<template>
	<div class="wrapper">
		<vue-snotify />
		<transition name="fade">
			<LoadPage v-if="load" />
		</transition>
		<router-view></router-view>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadPage from '@/component/LoadPageComponent.vue'
export default {
	name: 'app',
	components: {
		LoadPage
	},
	computed: {
		alert() {
			return this.$store.state.alert
		},
		load() {
			return this.$store.state.status.routeChange;
			// return this.$store.state.routeChange
		}
	},
	watch: {
		'alert.type'() {
			if (this.alert.type) {
				if (this.alert.type == 'success') {
					this.$snotify.success(this.alert.message);
				}
				if (this.alert.type == 'error') {
					this.$snotify.error(this.alert.message);
				}
				setTimeout(() => {
					this.clearNotification()
				}, 2000);
			}
		},
		'load'() {
			console.log('cambio');
		}
	},
	methods: {
		...mapActions({
			clearNotification: 'alert/clear'
		})
	}
};
</script>
<style lang="scss">
.bg {
	margin: 0px;
	height: 100vh !important;
}
</style>