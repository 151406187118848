<template>
	<div class="row">
		<div class="col-md-6">
			<div class="card card-outline card-info">
				<div class="card-header">
					<h3 class="card-title">Lista de Presupuesto </h3>
					<div class="card-tools">
						<a @click="showModal('')" v-can="'add_contacts'" class="btn btn-sm btn-outline-success">
							<i class="fa fa-plus"></i> Presupuesto
						</a>
					</div>
				</div>
				<NewBudget />
				<UploadFiles />
				<div class="card-body collapsed-card">
					<div class="row">
						<div class="col-12">
							<div class="card shadow" :class="budget.id == proyect.budgets_id ? 'card-success2' : ''"
								v-show="budgets" v-for="(budget, index) in budgets.data" :key="index">
								<div class="card-header">
									<h3 class="card-title">{{ budget.send_date | dateHuman }}</h3>
									<div class="div-tools text-right">
										<button type="button" class="btn btn-tool" data-card-widget="collapse"><i
												class="fas fa-plus"></i>
										</button>
									</div>
								</div>
								<div class="card-body" :style="index != 0 ? 'display:none;' : ''">
									<div class="div-tools text-right">
										<button class="btn btn-sm btn-outline-secondary"
											v-if="budget.id != proyect.budgets_id" @click="mainBudget(budget.id)">
											<i class="fas fa-check"></i>
										</button>
										<button class="btn btn-sm btn-outline-info" @click="showModal(budget.id)">
											<i class="fas fa-edit "> </i>
										</button>
										<button class="btn btn-sm btn-outline-danger" @click="deleted(budget.id)">
											<i class="fa fa-trash"></i>
										</button>
									</div>
									<div class="row mt-3">
										<div class="col-md-4  mb-2">
											<label for="">Presupuesto</label><br>
											$ {{ budget.amount | Money }}
										</div>
										<div class="col-md-4 mb-2">
											<label for="">Usuario en contacto</label><br>
											{{ budget.user.name }} {{ budget.user.last_name }}
										</div>
										<div class="col-md-4  mb-2">
											<label for="">Contacto empresa</label><br>
											{{ budget.contact.name }} - <i :class="budget.contact.mood.icon"></i> {{
												budget.contact.mood.name
											}}
										</div>
									</div>
									<div class="row">
										<div class="col-12">
											<hr>
										</div>
									</div>
									<!-- Files -->
									<button class="btn btn-outline-success btn-sm float-right"
										@click="showUploadModal(budget.id)">
										<i class="fa fa-upload"></i> Subir Archivo
									</button>
									<h5>Archivos</h5>
									<div class="row pt-3">
										<div class="col-md-6" v-show="budget.files.length > 0"
											v-for="(file, index2) in budget.files" :key="index2">
											<div class="card">
												<div class="col-12">
													<button @click="deletedFile(file.id)"
														class="btn btn-sm col-4 mt-2 btn-outline-danger float-right">
														<i class="fa fa-trash"></i>
													</button>
												</div>
												<div class="card-body text-center">
													<a class="text-dark" :href="config.apiUrl + file.file"
														target="_blank">
														<i class="fa fa-file fa-5x"></i>
														<hr>
														{{ file.name }}
													</a>
												</div>
											</div>
										</div>
										<div v-show="budget.files.length == 0" class="col-md-12">
											<div class="card">
												<div class="card-body text-center">
													<h3>Sin Archivos</h3>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card" v-show="!budgets">
								<div class="card-body text-center">
									<h3>Sin presupuestos generados</h3>
								</div>
							</div>
						</div>
						<div class="col-12">
							<hr>
							<router-link to="/projects" class="btn float-left btn-outline-warning">
								<i class="fas fa-undo-alt"></i>
								Regresar
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<Comments/>
		</div>
	</div>
</template>

<script>
import NewBudget from '../../component/budget/NewBudgetsComponent.vue'
import request from '../../services/request'
import { mapActions, mapMutations } from 'vuex';
import UploadFiles from '../../component/budget/UploadFilesComponent.vue'
import '../../helpers/filters'
import config from '@/services/config';
import Comments from '../../component/comments/CommentsComponent.vue'

export default {
	name: "budget-table",
	data() {
		return {
			proyect: {
				id: '',
				name: '',
				budgets_id: ''
			},
			params: {
				proyect_id: '',
			},
			config: config,
			budgets: [],
		}
	},
	components: {
		NewBudget,
		UploadFiles,
		Comments
	},
	created() {
		Event.$on("event-load-budgets", () => {
			this.list();
		});
	},
	mounted() {
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
			this.params.proyect_id = id;
			this.list();
		}
	},
	methods: {
		async get_id(id) {
			let _endpoint = `/projects/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.proyect.id = data.id;
					this.proyect.name = data.name;
					this.proyect.budgets_id = data.budgets_id;
					this.setHeaderTitle('Presupuestos (' + this.proyect.name + ')');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async mainBudget(id) {
			let send = {
				project_id: this.proyect.id,
				budge_id: id,
			};
			let _endpoint = `/budgets/main_budget`;

			try {
				let { data } = await request.post(_endpoint, send)
				if (data) {
					this.successNotification(data.message);
					this.get_id(this.proyect.id);
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					let info_error = await this.errorNotification(error);
					if (info_error == 'Logout') {
						this.$nextTick(() => {
							location.reload()
						})
					} else {
						this.errors = info_error;
					}
				}
			}
		},
		showModal(id) {
			Event.$emit('event-new-budgets', id);
			this.$modal.show('NewBudget');
		},
		showUploadModal(id) {
			Event.$emit('event-files-budgets', id);
			this.$modal.show('FileBudget');
		},
		async list(page = 1) {
			let service = request;
			let params = this.params;
			let _endpoint = '/budgets';
			try {
				let { data } = await service.list(_endpoint, page, params)
				if (data) {
					this.budgets = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		deleted: function (id) {
			let tit = 'Eliminar presupuesto';
			let txt = 'Seguro que quiere eliminar al presupuesto';
			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		deletedFile: function (id) {
			let tit = 'Eliminar archivo';
			let txt = 'Seguro que quiere eliminar el archivo';
			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeletedFile(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/budgets/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		async callDeletedFile(id) {
			let _endpoint = '/budgets/files-delete/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		...mapActions({
			successNotification: 'alert/success',
			errorNotification: 'alert/error',
		}),
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		})
	}
}
</script>

<style scoped>
.card-success2 {
	border: 2px solid #27a745;
}
</style>
