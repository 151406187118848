<template>
    <div class="col-12 ">
        <div class="card card-outline card-navy">
            <div class="card-body">
                <form @submit.prevent="triggerSave">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3>Imagen de perfil</h3>
                            <img class="img-fluid rounded" style="width: 200px;" :src="img_perfil" alt="">
                            <br>
                            <br>
                            <input type="file" id="file" class="inputfile" @change="loadPhoto">
                            <label for="file"><i class="fas fa-upload"></i> Subir imagen </label>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Nombre </label>
                                <input type="text" v-model="form.name" class="form-control">
                                <div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Apellidos </label>
                                <input type="text" v-model="form.last_name" class="form-control">
                                <div v-if="errors && errors.last_name" class="text-danger">{{ errors.last_name[0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Teléfono </label>
                                <input type="text" v-model="form.phone" class="form-control">
                                <div v-if="errors && errors.phone" class="text-danger">{{ errors.phone[0] }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> WhatsApp </label>
                                <input type="text" v-model="form.whathsapp" class="form-control">
                                <div v-if="errors && errors.whathsapp" class="text-danger">{{ errors.whathsapp[0] }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <label> Email </label>
                                <input type="mail" v-model="form.email" class="form-control">
                                <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Fecha Ingreso </label><br>
                                <date-picker v-model="form.admission_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
                                    :clearable="false"></date-picker>
                                <div v-if="errors && errors.admission_date" class="text-danger">{{
                    errors.admission_date[0]
                }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Fecha de nacimiento </label>
                                <date-picker v-model="form.birthday" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
                                    :clearable="false"></date-picker>
                                <div v-if="errors && errors.birthday" class="text-danger">{{ errors.birthday[0] }}</div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label> Perfil </label>
                                <select v-model="profile.profiles_id" class="form-control">
                                    <option v-if="" :value="profile.id" :key="index"  v-for="(profile, index) in catalogue.profiles" >
                                       {{ profile.name }}
                                    </option>
                                </select>
                                <div v-if="errors && errors.organization_charts_id" class="text-danger">{{
                                    errors.organization_charts_id[0] }}</div>
                            </div>
                         
                        </div> -->
                        <div class="col-md-4">
                            <div class="form-group">
                                <label> Perfil </label>
                                <select v-model="form.organization_charts_id" class="form-control">
                                    <option v-show="profile.areas_id == cat_profile.areas_id" :value="cat_profile.id"
                                        v-for="(cat_profile, index) in profiles" :key="index">
                                        <span v-if="cat_profile.profil">{{ cat_profile.profil.name }}</span>
                                    </option>
                                </select>
                                <div v-if="errors && errors.organization_charts_id" class="text-danger">{{
                    errors.organization_charts_id[0] }}</div>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="showUsers">
                            <div class="form-group">
                                <label> Jefe Directo </label>
                                <multiselect v-model='form.user' :options='catalogue.users_area'
                                    :custom-label='postWithTitle' placeholder='Buscar usuarios' label='title'
                                    track-by='id' :multiple="false" :hide-selected="true"
                                    :loading="catalogue.users_area_load">
                                    <span slot="noResult">No se encontraron usuario con este nombre.</span>
                                </multiselect>
                                <div v-if="errors && errors.users_area" class="text-danger">{{ errors.users_area[0] }}
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="card">
                                <div class="card-body text-center" v-if="img_signature">
                                    <h4>Firma</h4>
                                    <img class="img-fluid rounded" :src="img_signature" alt="firma">
                                    <br>
                                    <button type="button" class="btn btn-sm btn-outline-info" @click="clearSignature()">
                                        <i class="fas fa-edit"></i> Subir de nuevo
                                    </button>
                                </div>
                                <div class="card-body text-center" v-else>
                                    <h4>Firma</h4>
                                    <vueSignature ref="signature1" :sigOption="option"></vueSignature>
                                    <hr>
                                    <button class="btn btn-sm btn-outline-info" type="button" @click="clear">
                                        <i class="fas fa-eraser"></i> Limpiar
                                    </button>
                                    <!-- <br>
                                    <button class="btn mt-3 btn-sm btn-outline-info" type="button" @click="clear">
                                        <i class="fa-solid fa-upload"></i> Subir como imagen
                                    </button> -->
                                </div>
                                <div v-if="errors && errors.signature" class="text-danger text-center">{{
                    errors.signature[0] }}</div>

                            </div>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>

                        <div class="col-md-12 mt-2" v-if="!newUser">
                            <p class="text-info">Dejar en blanco si no quiere cambiar la contraseña</p>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label> Contraseña </label>
                                <div class="input-group">
                                    <input :type="type1" v-model="form.password" class="form-control">
                                    <div class="input-group-prepend">
                                        <div class="btn btn-outline-success"
                                            @click="type1 = type1 == 'password' ? 'text' : 'password'"
                                            style="border-radius: 0px 10px 10px 0px !important;">
                                            <i v-if="type1 == 'password'" class="fa-solid fa-eye"></i>
                                            <i v-else class="fa-solid fa-eye-slash"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="errors && errors.password" class="text-danger">{{
                    errors.password[0] }}</div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label> Confirmar contraseña </label>
                                <div class="input-group">
                                    <input :type="type2" v-model="form.password_confirmation" class="form-control">
                                    <div class="input-group-prepend">
                                        <div class="btn btn-outline-success"
                                            @click="type2 = type2 == 'password' ? 'text' : 'password'"
                                            style="border-radius: 0px 10px 10px 0px !important;">
                                            <i v-if="type2 == 'password'" class="fa-solid fa-eye"></i>
                                            <i v-else class="fa-solid fa-eye-slash"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="errors && errors.password_confirmation" class="text-danger">{{
                    errors.password_confirmation[0] }}</div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">

                                <label class="d-flex">
                                    <input type="checkbox" class="form-control form-control-sm mr-3"
                                        style="width: 20px;" v-model="form.privacy_notice">
                                    <span class="mt-1 mr-1">He leído y acepto el </span> <a
                                        href="https://www.soriano-ariza.com/privacy-notice" target="_black"
                                        class="mt-1 text-success"> aviso de privacidad</a>
                                </label>

                                <div v-if="errors && errors.privacy_notice" class="text-danger">{{
                                    errors.privacy_notice[0] }}</div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <button type="submit" class="btn float-right btn-outline-success" :disabled="submitted">
                                <span v-if="submitted">
                                    <i class="fa-solid fa-spinner fa-spin-pulse mr-1"></i> Enviando
                                </span>
                                <span v-else>
                                    <i class="fas fa-save"></i> Guardar
                                </span>
                            </button>
                            <router-link to="/users" class="btn float-left btn-outline-warning">
                                <i class="fas fa-undo-alt"></i>
                                Regresar
                            </router-link>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import request from '@/services/request'
import { mapActions, mapGetters } from 'vuex';

import Catalogue from '@/services/catalogue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import config from '@/services/config';
import vueSignature from "vue-signature"
import Multiselect from 'vue-multiselect'


export default {
    name: "profile-user",
    components: {
        DatePicker,
        vueSignature,
        Multiselect
    },
    data() {
        return {
            form: {
                id: '',
                name: '',
                last_name: '',
                img_perfil: '',
                phone: '',
                whathsapp: '',
                organization_charts_id: '',
                email: '',
                birthday: '',
                admission_date: '',
                user_id: '',
                user: '',
                approve_holidays_rh: '',
                remember_anniversary: '',
                remember_birthdat: '',
                password: '',
                password_confirmation: '',
                privacy_notice: false
            },
            showUsers: false,
            area: '',
            profiles: [],
            img_perfil: require('@/assets/img/profile.png'),
            img_signature: '',
            errors: {},
            action: '/admin/user',
            catalogue: new Catalogue(),
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgba(255,255,255, 0)"
            },
            profile: {},
            load_user: false,
            type1: 'password',
            type2: 'password',
            submitted: false,
            newUser: false,
            type: 1,
        }
    },
    mounted() {
        let is_new = this.authUser.new;
        if (is_new == 1) {
            this.$swal({
                title: 'Información requerida',
                text: 'Es necesario completar los datos del perfil para continuar',
                icon: 'warning',
                confirmButtonText: 'Entendido',
            });
        }
        this.catalogue.get_profiles();
        this.getProfiles();
        let id = this.authUser.id;
        if (id) {
            this.get_id(id);
        }
    },
    methods: {
        clear() {
            var _this = this;
            _this.$refs.signature1.clear();
        },
        clearSignature() {
            console.log('paso');
            this.img_signature = '';
        },
        loadPhoto(event) {
            if (event.target.files[0].type == "image/png" ||
                event.target.files[0].type == "image/jpeg") {
                this.form.img_perfil = event.target.files[0];
                this.img_perfil = URL.createObjectURL(event.target.files[0]);
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PNG , JPGE',
                });
            }
        },
        postWithTitle(option) {
            let name = '';
            if (option.name) {
                name += option.name;
            }
            if (option.last_name != null && option.last_name != "" && option.last_name != "null") {
                name += ' ' + option.last_name;
            }
            return name;
        },
        async triggerSave() {
            this.submitted = true;
            let signature = "";

            if (this.$refs.signature1) {
                const _empty = await this.$refs.signature1.isEmpty();
                if (!_empty) {
                    signature = this.$refs.signature1.save();
                }
            }

            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('name', this.form.name);
            data_form.append('last_name', this.form.last_name);
            data_form.append('email', this.form.email);
            data_form.append('phone', this.form.phone);
            data_form.append('organization_charts_id', this.form.organization_charts_id);
            data_form.append('img_perfil', this.form.img_perfil);
            data_form.append('whathsapp', this.form.whathsapp);
            data_form.append('birthday', this.form.birthday);
            data_form.append('admission_date', this.form.admission_date);
            data_form.append('password', this.form.password);
            data_form.append('password_confirmation', this.form.password_confirmation);
            data_form.append('privacy_notice', this.form.privacy_notice);
            data_form.append('type', this.form.type);
            if (this.form.user) {
                data_form.append('user_id', this.form.user.id);
            } else {
                data_form.append('user_id', this.form.user_id);
            }


            if (signature) {
                data_form.append('signature', signature);
            }

            let _endpoint = '/users';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/users/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    await this.authRelaod();
                    // this.users = data.data;
                    await this.successNotification(data.message);
                    this.submitted = false;
                    await this.$router.push('/');
                }
            } catch (error) {
                this.submitted = false;
                this.errors = {};
                if (error.response) {
                    let info_error = await this.errorNotification(error);
                    if (info_error == 'Logout') {
                        this.$nextTick(() => {
                            location.reload()
                        })
                    } else {
                        this.errors = info_error;
                    }
                }
            }
        },
        async get_id(id) {
            let _endpoint = `/users/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    this.form.id = id;
                    this.form.name = data.name ? data.name : '';
                    this.form.last_name = data.last_name ? data.last_name : '';
                    this.form.img_perfil = data.img_perfil ? data.img_perfil : '';
                    this.form.phone = data.phone ? data.phone : '';
                    this.form.whathsapp = data.whathsapp ? data.whathsapp : '';
                    this.form.email = data.email ? data.email : '';
                    this.form.organization_charts_id = data.organization_charts_id ? data.organization_charts_id : '';
                    this.form.birthday = data.birthday ? data.birthday : '';
                    this.form.admission_date = data.admission_date ? data.admission_date : '';
                    this.form.user_id = data.user_id ? data.user_id : '';
                    this.form.approve_holidays_rh = data.approve_holidays_rh == 1 ? true : false;
                    this.form.remember_anniversary = data.remember_anniversary == 1 ? true : false;
                    this.form.remember_birthdat = data.remember_birthdat == 1 ? true : false;
                    if (data.profile_picture) {
                        this.img_perfil = config.apiUrl + data.profile_picture;
                    }
                    if (data.signature) {
                        this.img_signature = config.apiUrl + '/storage/' + data.signature;
                    }
                    if (data.profile) {
                        this.profile = data.profile;
                    }
                    this.form.user = data.user;
                    this.newUser = data.new == 1 ? true : false;
                    this.form.privacy_notice = data.privacy_notice == 1 ? true : false;
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        async getProfiles() {
            let service = request;
            let _endpoint = '/catalogue/profiles';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.profiles = data;
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        clearUser() {
            this.form.user = '';
        },
        async validProfile() {
            let value = this.form.organization_charts_id;
            if (value != 'null') {
                let _endpoint = '/profiles/' + value;
                try {
                    let { data } = await request.get(_endpoint)
                    if (data.profiles_id != 1) {
                        this.showUsers = true;
                        this.catalogue.get_users_area(data.areas_id, this.form.id);
                    } else {
                        this.showUsers = false;
                    }
                } catch (error) {
                    this.errors = {};
                    if (error.response) {
                        this.errors = await this.errorNotification(error)
                    }
                }
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
            authRelaod: 'auth/getUser'

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        'form.organization_charts_id': {
            handler: function () {
                this.validProfile();
            },
            deep: true
        }
    },
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}

.fs-6 {
    font-size: 12px;
}

.custom-file-label::after {
    content: "Archivo";
}

.text-success {
    color: #19692c !important;
}
</style>