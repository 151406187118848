import request from './request'

export default class Catalogos {

    constructor() {
        this.countrys = [];
        this.zip = [];
        this.contact_mood = [];
        this.main_clients = [];
        this.all_clients = [];
        this.all_users = [];
        this.contacts_proyect = [];
        this.users_project = [];
        this.users_area_load = false;
        this.users_area = [];
        this.statuses_project = [];
        this.related_users = [];
        this.related_users_num = 0;
        this.validate_holiday_num = 0;
        this.validate_holiday = [];
        this.validate_holiday = [];
        this.areas = [];
        this.profiles = [];
        this.off_days = [];
    }

    async get_countrys() {
        let _endpoint = '/catalogue/countries';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.countrys = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_zip(zip) {
        let _endpoint = `/catalogue/zip?zip=${zip}`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.zip = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_contact_mood() {
        let _endpoint = `/catalogue/contact_mood`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.contact_mood = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_main_clients(id = '') {
        let _endpoint = `/catalogue/main_clients?id=${id}`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.main_clients = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_all_clients() {
        let _endpoint = `/catalogue/all_clients`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.all_clients = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_all_users(query = '') {
        let search = { _search: query };
        let _endpoint = '/catalogue/users';
        try {
            let { data } = await request.get(_endpoint, search)
            if (data) {
                this.all_users = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_contacts_proyect(proyecto_id) {
        let _endpoint = `/catalogue/contacts_proyect?proyecto_id=${proyecto_id}`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.contacts_proyect = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_users_project(projet_id) {
        let _endpoint = `/catalogue/users_project?id=${projet_id}`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.users_project = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_users_area(area_id = '', not_user_id = '') {
        this.users_area_load = true;
        let _endpoint = `/catalogue/users_area?id=${area_id}&not_user_id=${not_user_id}`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.users_area = data;
                this.users_area_load = false;
            }
        } catch (error) {
            console.log(error);
            this.users_area_load = false;

        }
    }

    async get_statuses_project() {
        let _endpoint = `/catalogue/statuses_project`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.statuses_project = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_related_users() {

        let _endpoint = `/catalogue/related_users`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                if (data.total) {
                    this.related_users_num = data.total;
                }
                this.related_users = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_validate_holiday() {
        this.validate_holiday_num = 0;
        this.validate_holiday = [];
        let _endpoint = `/holidays_list_valid`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                if (data.total) {
                    this.validate_holiday_num = data.total;
                    this.validate_holiday = data.data;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_areas() {
        let _endpoint = `/catalogue/areas`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.areas = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_profiles() {
        let _endpoint = `/catalogue/profiles`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.profiles = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_off_days() {
        let _endpoint = `/catalogue/off_days`;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.off_days = data;
            }
        } catch (error) {
            console.log(error);
        }
    }
}