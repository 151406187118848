<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Validar vacaciones</h3>
			</div>
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>Periodo</th>
								<th>Usuario</th>
								<th>Estatus</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td colspan="4" class="text-center p-4">
									<i class="fas fa-spinner fa-pulse fa-5x"></i>
									<h6 class="mt-3">
										Cargando
									</h6>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="holidays.total > 0">
							<tr :class="holiday.id == selected ? 'selected' : ''" v-for="(holiday, index) in holidays.data"
								:key="index">
								<td class="v-center">
									<p>
										{{ holiday.start_date | dateHuman }}
										<br> - <br>
										{{ holiday.end_date | dateHuman }}
									</p>
								</td>
								<td style="vertical-align: inherit;">
									<p>
										<span
											v-if="holiday.users.name && holiday.users.name != null && holiday.users.name != 'null'">
											{{ holiday.users.name }}
										</span>
										<span
											v-if="holiday.users.last_name && holiday.users.last_name != null && holiday.users.last_name != 'null'">
											{{ holiday.users.last_name }}
										</span>

									</p>
								</td>
								<td>
									<TimeLineHoliday :holiday="holiday" />
								</td>

								<td class="align-middle">
									<div class="col-12" v-if="holiday.id && authUser">

										<!-- JEFE DIRECTO-->
										<div
											v-if="(holiday.holidays_statuses_id == 1) && (authUser.id == holiday.users.user_id)">
											<button class="btn btn-outline-success btn-sm" @click="validate(holiday)">
												<i class="far fa-calendar-check"></i> Validar
											</button>
										</div>

										<!-- GERENTE AREA-->
										<div
											v-if="holiday.users.profile.area.holidays.area_manager == 1 && holiday.holidays_statuses_id == 2 && authUser.profile.areas_id == holiday.users.profile.areas_id && (authUser.profile.profiles_id == 2 || authUser.profile.profiles_id == 3)">
											<button class="btn btn-outline-success btn-sm" @click="validate(holiday)">
												<i class="far fa-calendar-check"></i> Validar
											</button>
										</div>

										<!-- RH -->
										<div
											v-if="(holiday.holidays_statuses_id == 3 && (authUser.approve_holidays_rh == 1 || authUser.profile.areas_id == 1 || authUser.profile.areas_id == 2)) || (holiday.users.profile.area.holidays.area_manager == 0 && holiday.holidays_statuses_id == 2 && (authUser.approve_holidays_rh == 1 || authUser.profile.areas_id == 1 || authUser.profile.areas_id == 2))">
											<button class="btn btn-outline-success btn-sm" @click="validate(holiday)">
												<i class="far fa-calendar-check"></i> Validar
											</button>
										</div>
									</div>

								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin vacaciones por validar.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<pagination v-model="page" v-if="holidays.total" :records="holidays.total" :per-page="holidays.per_page"
					@paginate="list" />
			</div>
		</div>
		<ModalValidate />
	</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

import ModalValidate from '@/component/holidays/ModalValidateComponent.vue';
import TimeLineHoliday from '@/component/holidays/TimeLineComponent.vue';
import Pagination from 'vue-pagination-2';
import request from '../../services/request'

export default {
	name: "validate_holiday",
	components: {
		Pagination,
		ModalValidate,
		TimeLineHoliday
	},
	data() {
		return {
			page: 1,
			holidays: {
				data: []
			},
			selected: '',
			search: '',
			load: false,
		}
	},
	mounted() {
		let id = this.$route.query.id;
		this.showSelected(id);
		this.setHeaderTitle('Vacaciones');
		this.list()
	},
	created() {
		Event.$on("event-load-holidays", () => {
			this.list();
		});
	},
	methods: {
		showSelected(id = false) {
			if (id) {
				this.selected = id;
			} else {
				this.selected = '';
			}

		},
		validate(holiday) {
			Event.$emit('event-load-pdf-holiday', holiday);
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/holidays_list_valid';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					this.load = false;
					this.holidays = data;
				}
			} catch (error) {
				this.load = false;
				console.log(error);
			}
		},
		InfoStatus(status, statu) {
			let status_info = status.history.map(x => x.holidays_statuses_id);
			let result = status_info.includes(statu);
			return result ? result : status.cancel;

		},
		createHoliday() {
			this.$modal.show('new_holiday');
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'$route.query': {
			handler: function (params) {
				if (params) {
					this.showSelected(params.id);
				}
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style >
.selected {
	color: #000;
	font-weight: bold;
	animation: blinkingText 4s infinite;
}

@keyframes blinkingText {
	0% {
		border: 3px solid #36a590;
	}

	25% {
		border: 3px solid #dee2e6;
	}

	50% {
		border: 3px solid #36a590;
	}

	75% {
		border: 3px solid #dee2e6;
	}

	100% {
		border: 3px solid #36a590;
	}
}
</style>
