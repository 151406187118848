<template>
    <div class="col-12">
        <div class="card card-outline card-info">
            <div class="card-header">
                <h3 class="card-title">Lista de Usuarios</h3>
                <div class="card-tools">
                    <button @click="printThis" class="btn-sm m-1 btn btn-outline-info">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        Descargar Organigrama
                    </button>
                </div>
            </div>
            <div class="card-body p-2 todo">
                <div class="row">
                    <div class="col-md-12 text-center ">
                        <label>Areas a visualizar en el organigrama</label>
                        <br>
                        <div class="row">
                            <div class="col-md-3 col-6 text-center" v-for="(area, index) in catalogue.areas"
                                :key="index">
                                <label class="m-2">
                                    <input type="checkbox" v-model="filter"
                                        class="form-control form-control-sm checkbox" @change="get_organization"
                                        :value="area.id">
                                    {{ area.name }}
                                </label>
                            </div>
                        </div>

                        <hr>

                    </div>
                    <div class="col-12 text-center p-5" v-if="load">
                        <i class="fas fa-spinner fa-pulse fa-5x"></i>
                        <h6 class="mt-3">
                            Cargando
                        </h6>
                    </div>
                    <div v-else class="col-12">
                        <div class="indicatorBtns">
                            <button class="btn-secondary btn btn-sm m-1" @click="controlScale('bigger')">+</button>
                            <button class="btn-secondary btn btn-sm m-1" @click="controlScale('smaller')">-</button>
                        </div>
                        <div ref="treeContainer" class="tree-container">

                            <VueTree ref="scaleTree" class="tree"
                                style="width: 100%; height: 600px; border: 1px solid gray; border-radius: 10px; background: #1f2937;"
                                direction="horizontal" :dataset="sampleData" :config="treeConfig"  linkStyle="straight"
                                @node-click="handleNodeClick">
                                <template v-slot:node="{ node }" >
                                    <div class="rich-media-node" :class="nchildren(node)" >
                                        <!-- <img class="img-fluid"
                                            :class="node.users.profile_picture ? 'existPhoto' : 'notPhoto'"
                                            v-if="node.users"
                                            :src="node.users.profile_picture ? config.apiUrl + node.users.profile_picture : config.BaseUrl + '/assets/img/logo.png'" />
                                        <img class="rounded-circle img-fluid" style="padding: 15px 20px;" v-else
                                            :src="config.BaseUrl + '/assets/img/logo.png'" /> -->
                                        <img class="rounded-circle img-fluid" style="padding: 15px 20px;" 
                                            :src="require('@/assets/img/logo.png')">
                                        <div class="node-title">{{ node.title }} </div>
                                        <span style="padding: 0px; font-size: 13px ; font-weight: bold;">
                                            {{ node.name }}
                                        </span>
                                        <p class="mb-1" v-if="node.users" style="font-size: 9px; margin-top: -2px;">
                                            {{ node.users.name }} {{ node.users.last_name }}<br>
                                        </p>
                                        <p class="mb-1" v-else>
                                            Sin usuario
                                        </p>                                     
                                    </div>
                                </template>
                            </VueTree>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTree from '@ssthouse/vue-tree-chart';
import request from '../../services/request'
import config from '@/services/config';
import Catalogue from '@/services/catalogue';
import domtoimage from 'dom-to-image';

export default {
    name: "OrganizationCopy",
    config: config,
    components: {
        VueTree
    },
    data() {
        return {
            load: false,
            config: config,
            catalogue: new Catalogue(),
            filter: [1, 2, 3, 4],
            sampleData: {},
            treeConfig: { nodeWidth: 200, nodeHeight: 250, levelHeight: 600 }
        };
    },
    methods: {
        async printThis() {
            console.log("printing..");
            var node = document.querySelectorAll("body .tree")[0];
            
            let scale = 20; // Escalar la imagen a 2x para mayor resolución
            let width = node.clientWidth * scale;
            let height = node.clientHeight * scale;

            domtoimage.toPng(node, {
                width: width,
                height: height,
                style: {
                    transform: 'scale(' + scale + ')',
                    transformOrigin: 'top left',
                    width: node.clientWidth + "px",
                    height: node.clientHeight + "px"
                }
            })
            .then(dataUrl => {
                const link = document.createElement('a');
                link.download = 'OrganigramaN.png';
                link.href = dataUrl;
                link.click();
            })
            .catch(error => {
                console.error('Error capturando el SVG:', error);
            });
        },
        controlScale(command) {
            switch (command) {
                case 'bigger':
                    this.$refs.scaleTree.zoomIn()
                    break
                case 'smaller':
                    this.$refs.scaleTree.zoomOut()
                    break
                case 'restore':
                    this.$refs.scaleTree.restoreScale()
                    break
            }
        },
        nchildren(node){
            let nameClass = '';
            if(node._children && node._children.length > 0 ) { 
                nameClass = 'num_childen';
            }
            if(node._children && node._children.length > 0){
                nameClass = 'num_childen';
            }
            return nameClass;
        },
        handleNodeClick(node) {
            alert('paso');
            this.toggleCollapse(node);
        },
        toggleCollapse(node) {
            node.collapsed = !node.collapsed;
            // Aquí puedes realizar cualquier otra acción que necesites al colapsar o expandir
        },
        async get_organization() {
            this.load = true;
            let componentVue = this.$refs.oranigramaVue;
            if (componentVue) {
                console.log(componentVue.VueComponent);
                console.log(componentVue.$data);
                console.log(componentVue.$data.transformVal);
                componentVue.$data.transformVal = '';
                console.log(componentVue.$data.transformVal);
            }

            let _endpoint = `/profile/organization?areas=${this.filter}`;
            try {
                let { data } = await request.get(_endpoint)
                this.sampleData = data;
                // console.log('aqui');
                // console.log(data);
                this.load = false;
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                    this.load = false;

                }
            }
        },
        async asignAllAreas() {
            let areas = this.catalogue.areas;
            let ids = [];
            areas.forEach(function (valor) {
                ids.push(valor.id);
            });
            this.filter = ids;
        },
    },
    async mounted() {
        await this.catalogue.get_areas();
        await this.asignAllAreas();
        this.get_organization();
    },
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rich-media-node {
    width: 150px;
    margin: 15px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    background-color: #121727;
    border-left: 6px solid #009688;
    border-radius: 4px;
    position: relative;
    border-radius: 10px;
}

.indicator {
    position: relative;
    cursor: pointer;
    font-weight: bold;
    height: 10px;
    margin-top: -5px;
    margin-bottom: 10px;
    left: 40%;
}

.indicatorBtns {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    z-index: 1000;
    background: #36a59091;
    position: relative;
    right: 0;
    top: 38px;

    button {
        float: inline-end;
        padding: 1px 9px 5px 9px;
    }
}

.node-title {
    font-size: 11px;
    font-weight: 700;
    color: #36a590;
}

.tree-container .link {
    stroke: #36a590 !important;
}

.existPhoto {
    padding: 15px 15px;
    border-radius: 100%;
    height: 120px;
    width: 90%;
    margin: 0 auto;
    object-fit: cover;
}

.notPhoto {
    padding: 15px 20px;
}
.num_childen{

}
.num_childen:before, .block:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(10deg, #009688, #000, #009688);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
  border-radius: 10px;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

</style>