<script>
import { mapGetters } from 'vuex';
import Catalogue from '@/services/catalogue';

export default {
	data() {
		return {
			catalogue: new Catalogue(),
		}
	},
	mounted() {
		this.catalogue.get_related_users();
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}

</script>
<template>
	<nav class="mt-2">
		<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
			<!-- Add icons to the links using the .nav-icon class with font-awesome or any other icon font library -->
			<li class="nav-item">
				<router-link :to="{ name: 'app-home' }" class="nav-link" >
					<i class="nav-icon fas fa-th"></i>
					<p>
						Panel principal
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-if="authUser.organization_charts_id != 1">
				<router-link :to="{ name: 'my_holidays' }" class="nav-link"  active-class="active">
					<i class="nav-icon fas fa-plane-departure"></i>
					<p>
						Mis vacaciones
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-if="catalogue.related_users_num > 0">
				<router-link :to="{ name: 'my_team' }" class="nav-link"  active-class="active">
					<i class="nav-icon fas fa-users"></i>
					<p>
						Mi equipo
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-if="catalogue.related_users_num > 0 || authUser.profile.areas_id == 2">
				<router-link :to="{ name: 'validate_holiday' }"  active-class="active" class="nav-link">
					<i class="nav-icon fas fa-calendar-check"></i>
					<p>
						Aprobar vacaciones
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'users'">
				<router-link to="/users" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-user"></i>
					<p>
						Usuarios
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'customers'">
				<router-link to="/customers" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-building"></i>
					<p>
						Clientes
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'projects'">
				<router-link to="/projects" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-project-diagram"></i>
					<p>
						Proyectos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'organization'">
				<router-link to="/organization" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-network-wired"></i>
					<p>
						Organigrama
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'calendar'">
				<router-link to="/calendar" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-calendar"></i>
					<p>
						Calendario
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'permissions'">
				<router-link to="/permissions" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-lock"></i>
					<p>
						Permisos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'holidays_config'">
				<router-link to="/holidays_config" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-sliders-h"></i>
					<p>
						Configurar Vacaciones
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'holidays_extras'">
				<router-link to="/holidays_extras" active-class="active" class="nav-link">
					<i class="nav-icon fa-regular fa-square-plus"></i>
					<p>
						Vacaciones Extras
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'holidays'">
				<router-link to="/holidays" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-plane"></i>
					<p>
						Vacaciones
					</p>
				</router-link>
			</li>
		</ul>
	</nav>
</template>
