<template>
	<modal name="NewCountry" v-bind:click-to-close="false" :width="400" :adaptive="true">
		<div class="row pad" v-if="load == false">
			<div class="col-12">
				<h4>{{ title }}</h4>
				<hr>
				<div class="form-group">
					<label for="">Nombre :</label>
					<input type="text" v-model="form.name" class="form-control">
					<div v-if="(errors && errors.name)" class="text-danger">{{ errors.name[0] }}</div>
				</div>
			</div>
			<div class="col-12 mt-3">
				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-warning" @click="reset">
							<i class="fas fa-undo-alt"></i>
							Cerrar
						</button>
					</div>
					<div class="col-6 text-right">
						<button class="btn btn-outline-success" @click="save">
							<i class="fas fa-save"></i>
							Guardar
						</button>

					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<div class="load">Loading...</div>
			</div>
		</div>
	</modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'

export default {
	name: "new-country",
	data() {
		return {
			title: 'Nuevo país',
			form: {
				name: '',
			},
			errors: {},
			load: false,
		}
	},
	methods: {
		async save() {
			this.load = true;
			let _endpoint = '';		
			_endpoint = `/add_countries`;
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
					Event.$emit('event-load-country', '');
				}
			} catch (error) {
				this.load = false;
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error);
				}
			}
		},		
		reset() {
			this.load = false;
			this.errors = {};
			this.form.name = '';
			this.$modal.hide('NewCountry');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style>
.pad {
	padding: 10px !important;
}
</style>
