<template>
    <div class="col-12">
        <div class=card>
            <div class="card-body pt-5 pb-5 text-center" v-if="load">
                <div class="col-12">
                    <i class="fas fa-spinner fa-pulse fa-5x"></i>
                    <h6 class="mt-3">
                        Cargando
                    </h6>
                </div>
            </div>
            <div v-else class="card-body p-0">
                <highcharts class="br-10" :options="chartOptions"></highcharts>
            </div>
        </div>
    </div>
</template>
<script>
import request from '../../services/request'
export default {
    data() {
        return {
            load: false,
            chartOptions: {
                chart: {
                    type: 'column'
                },
                title: {
                    align: 'left',
                    text: 'Usuarios por área'
                },
                subtitle: {
                    align: 'left',
                    text: 'En esta gráfica se observará un conteo general de usuarios por área registrado.'
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    title: {
                        text: 'Total usuarios por área'
                    }

                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y} '
                        }
                    }
                },

                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Usuarios<br/>'
                },

                series: [
                    {
                        name: 'Usuarios',
                        colorByPoint: true,
                        data: [
                            {
                                name: 'Chrome',
                                y: 63.06,
                                drilldown: 'Chrome'
                            },
                            {
                                name: 'Safari',
                                y: 19.84,
                                drilldown: 'Safari'
                            },
                            {
                                name: 'Firefox',
                                y: 4.18,
                                drilldown: 'Firefox'
                            },
                            {
                                name: 'Edge',
                                y: 4.12,
                                drilldown: 'Edge'
                            },
                            {
                                name: 'Opera',
                                y: 2.33,
                                drilldown: 'Opera'
                            },
                            {
                                name: 'Internet Explorer',
                                y: 0.45,
                                drilldown: 'Internet Explorer'
                            },
                            {
                                name: 'Other',
                                y: 1.582,
                                drilldown: null
                            }
                        ]
                    }
                ],
            }
        };
    },
    methods: {
        async getData() {
            this.load = true;
            let _endpoint = '/profile/data_chart';
            try {
                let { data } = await request.get(_endpoint);
                this.chartOptions.series[0].data = data;
                this.load = false;
            } catch (error) {
                this.errors = await this.errorNotification(error)
                this.load = false;
            }
        }
    },
    async created() {
        await this.getData();
    },
    
}
</script>
<style>
.br-10 {
    border-radius: 5px;
}
</style>