import { render, staticRenderFns } from "./AddYearComponent.vue?vue&type=template&id=2a08cff8"
import script from "./AddYearComponent.vue?vue&type=script&lang=js"
export * from "./AddYearComponent.vue?vue&type=script&lang=js"
import style0 from "./AddYearComponent.vue?vue&type=style&index=0&id=2a08cff8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports