<template>
    <div class="col-12">
        <p>
            <strong>* Configuración los días de vacaciones por años de laboral.</strong>
        </p>
        <hr>
        <div class="row">
            <AddYear />
            <div class="col-12 mb-2">
                <button class="btn btn-outline-success btn-sm float-right" @click="modalRule('')"><i class="fa fa-plus"></i>
                    Agregar</button>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body table-responsive p-0">
                        <table class="table text-center table-striped table-valign-middle">
                            <thead>
                                <tr>
                                    <th>Años</th>
                                    <th>Dias</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(year, index) in years.data" :key="index">

                                    <td>{{ year.year }} año<span v-if="year.year > 1">s</span></td>
                                    <td>{{ year.days }} dias</td>
                                    <td>
                                        <button @click="modalRule(year.id)" class="btn btn-outline-info btn-sm">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 pt-3">
                        <pagination v-model="page" :records="years.total" :per-page="years.per_page" @paginate="getList" />                       
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AddYear from './AddYearComponent.vue';
import request from '../../services/request'
import Pagination from 'vue-pagination-2';

export default {
    components: {
        AddYear,
        Pagination
    },
    data() {
        return {
            page:1,
            years: [],
        }
    },
    created() {
        Event.$on("event-load-year", () => {
            this.getList();
        });
    },
    mounted() {
        this.getList();
    },
    methods: {
        modalRule(id = '') {
            Event.$emit('event-add-year', id);
            this.$modal.show('addYear');
        },
        async getList(page = 1) {
            let service = request;
            let _endpoint = '/days_holidays_years';
            try {
                let { data } = await service.list(_endpoint, page)
                if (data) {
                    this.years = data;
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
.font-10 {
    font-size: 11px;
}

.lh {
    line-height: 18px;
}
</style>
