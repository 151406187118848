<template>
	<modal name="example" v-bind:click-to-close="false" :width="400" :height="'auto'" :adaptive="true">
		<div class="row p-3" v-if="load == false">
			<div class="col-12 text-center">
				<h4>Importar Usuarios</h4>
				<hr>
			</div>
			<div class="col-md-12">
				<label class="mb-3" for="">Area</label>
				<select class="form-control" v-model="area_id">
					<option v-for="(area, index) in catalogue.areas" :value="area.id" :key="index">{{ area.name }}</option>
				</select>
				<div v-if="errors && errors.area_id" class="text-danger">{{errors.area_id[0]}}</div>
			</div>
			<div class="col-md-12 text-center pt-3">
				<input type="file" id="file" ref="file" class="inputfile w-100 pt-4 mt-4 pb-4" @change="file_excel">
				<label for="file" class="w-100"><i class="fas fa-upload"></i><br> Selecciona el archivo 
					<br> <span> {{ archivo_upload.name }}</span> </label>
				<div v-if="errors && errors.file" class="text-danger">{{errors.file[0]}}</div>
				<label class="mb-3 text-sm text-secondary" for="">Solo se aceptan archivos Excel</label>
			</div>
			<div class="col-md-12 text-center">				
				<a class="pt-5" target="_blank" :href="exampleFile">Ejemplo de plantilla</a>
			</div>
			<div class="col-md-12 text-center">
				<hr>
				<button class="btn btn-outline-warning float-left" @click="resetModal">Cerrar</button>
				<button class="btn btn-outline-success float-right" @click="initImport()">Importar</button>
			</div>

		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<div class="load">Loading...</div>
			</div>
		</div>
	</modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../services/request'
import Catalogue from '@/services/catalogue';


export default {
	name: "import-stores",
	data() {
		return {
			exampleFile : '/ejemplo.xlsx',
			// exampleFile: require('@/assets/img/sin-logo.jpeg'),
			area_id: '',
			cargador: false,
			archivo_upload: '',
			archivo: '',
			fileName: '',
			load: false,
			reporte: {},
			errors: {},
			catalogue: new Catalogue(),
		}
	},
	mounted() {
		this.catalogue.get_areas();
	},
	methods: {
		file_excel() {
			if (this.$refs.file.files[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos Excel'
				});
			} else {
				this.fileName = this.$refs.file.files[0].name;
				this.archivo_upload = this.$refs.file.files[0];
				this.$refs.file.files = null;
			}
		},
		async initImport() {
			let vm = this;
			this.load = true;
			const data_form = new FormData();
			data_form.append('file', this.archivo_upload);
			data_form.append('area_id', this.area_id);
			let _endpoint = `/users/import`;
			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					vm.successNotification(data.message);
					vm.resetModal();
					Event.$emit('event-load-users');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.load = false;
					this.errors = await this.errorNotification(error)
				}
			}
		},
		resetModal() {			
			this.load = false;
			this.area_id = '';
			this.archivo_upload = '';			
			this.$modal.hide('example');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style>
.pad {
	padding: 10px !important;
}
</style>
