<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Mis vacaciones</h3>
				<div class="card-tools">
					<NewHolidays />
				</div>
			</div>
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>Periodo</th>
								<th>Usuario</th>
								<th>Estatus</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td colspan="4" class="text-center p-4">
									<i class="fas fa-spinner fa-pulse fa-5x"></i>
									<h6 class="mt-3">
										Cargando
									</h6>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="holidays.total > 0">
							<tr v-for="(holiday, index) in holidays.data" :key="index">
								<td class="v-center">
									<p>
										{{ holiday.start_date | dateHuman }}
										<br> - <br>
										{{ holiday.end_date | dateHuman }}
									</p>
								</td>
								<td style="vertical-align: inherit;">
									<p>
										<span
											v-if="holiday.users.name && holiday.users.name != null && holiday.users.name != 'null'">
										{{ holiday.users.name }} 
										</span>
										<span
											v-if="holiday.users.last_name && holiday.users.last_name != null && holiday.users.last_name != 'null'">
										{{ holiday.users.last_name }}
										</span>
									</p>
								</td>
								<td>
									<TimeLineHoliday :holiday="holiday" />
								</td>

								<td class="align-middle">

									<!-- JEFE DIRECTO-->
									<button class="btn btn-outline-success btn-sm" @click="validate(holiday)">
										<i class="far fa-file-pdf"></i> Ver solicitud
									</button>

								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin peticiones realizadas.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<pagination v-model="page" v-if="holidays.total" :records="holidays.total" :per-page="holidays.per_page"
					@paginate="list" />
			</div>
		</div>
		<ModalValidate />
	</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import NewHolidays from '../../component/holidays/NewHolidaysComponent.vue'
import TimeLineHoliday from '@/component/holidays/TimeLineComponent.vue';
import ModalValidate from '@/component/holidays/ModalValidateComponent.vue';

import Pagination from 'vue-pagination-2';
import request from '../../services/request'

export default {
	name: "holidays-table",
	components: {
		Pagination,
		NewHolidays,
		TimeLineHoliday,
		ModalValidate
	},
	data() {
		return {
			page: 1,
			holidays: {
				data: []
			},
			load: false,
			search: '',
		}
	},
	mounted() {
		this.setHeaderTitle('Vacaciones');
		this.list()
	},
	created() {
		Event.$on("event-load-holidays", () => {
			this.list();
		});
	},
	methods: {
		validate(holiday) {
			Event.$emit('event-load-pdf-holiday', holiday);
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/my_holidays';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					this.holidays = data;
					this.load = false;
				}
			} catch (error) {
				this.load = false;
				console.log(error);
			}
		},
		async changeStatus(id, status) {
			this.$swal({
				title: 'Aceptar las vacaciones',
				text: 'Si acepta ya no podra cancelar',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					let form = {
						id: id,
						status: status
					};
					this.callChangeStatus(form);

				}
			});
		},
		async callChangeStatus(form) {
			try {
				let _endpoint = '/holidays/change_status';
				let { data } = await request.post(_endpoint, form)
				if (data) {
					this.successNotification('Exito al aceptar las vacaciones');
					this.list();
				}
			} catch (error) {
				console.log(error);
			}
		},
		InfoStatus(status, statu) {
			let status_info = status.history.map(x => x.holidays_statuses_id);
			let result = status_info.includes(statu);
			return result ? result : status.cancel;

		},
		createHoliday() {
			this.$modal.show('new_holiday');
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	}
}
</script>
