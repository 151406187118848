<template>
	<div class="full_load">
		<div class="row align-items-center justify-content-center h-100">
			<scale-loader :loading="loading" :color="color" :height="height" :width="width"></scale-loader>
		</div>
	</div>
</template>
<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
	components: {
		ScaleLoader
	},
	data() {
		return {
			loading: true,
			color: '#065e4cab',
			height: '95px',
			width: '15px',
		}
	},
}
</script>
<style scoped>
.full_load {
	background-color: #4a4a4a75;
	/* background-color: #065e4cf5; */
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 1087;
	backdrop-filter: blur(4px);
}
</style>
