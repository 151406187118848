export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null
    },
    actions: {
        success({ commit }, message) {
            commit('success', message);
        },
        error({ commit }, error = {}) {
            // Validación API
            if (error.response.status == 403) {
                if (error.response.data.error == "Unauthenticated.") {
                    commit('error', 'Se ha cerrado la sesión de una cuenta');
                    this.commit('setUser', null);
                    this.commit('setToken', null);
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                }
                return Promise.resolve({});
                // Validación Campos
            } else if (error.response.status == 401) {
                console.log(error.response.data.message);
                if (error.response.data.message == "Unauthenticated.") {
                    commit('error', 'Se ha cerrado la sesión de una cuenta');
                    this.commit('setUser', null);
                    this.commit('setToken', null);
                    setTimeout(() => {
                        location.reload()
                    }, 2000);
                    return Promise.resolve({});
                } else {
                    commit('error', error.response.data.errors.message);
                    return Promise.resolve({});
                }
                // Validación Campos
            } else if (error.response.status == 422) {
                return Promise.resolve(error.response.data.errors);
            } else {
                commit('error', 'Ups. Parece que algo salio mal...');
                return Promise.resolve({});
            }
            // commit('error', message);
        },
        clear({ commit }) {
            commit('clear');
        }
    },
    mutations: {
        success(state, message) {
            state.type = 'success';
            state.message = message;
        },
        error(state, message) {
            state.type = 'error';
            state.message = message;
        },
        clear(state) {
            state.type = null;
            state.message = null;
        }
    }
}
