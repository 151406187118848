<template>
	<div class="row bg align-items-center justify-content-center m-0">
		<div class="w-75">
			<div class="row">
				<div class="col-12 text-center">
					<img :src="require('@/assets/img/logo.png')" class="mt-5 mb-5" alt="Logo" width="150px;"><br>
					<h2 style="color: #20a288;" class="login-box-msg mt-n4">BIENVENIDO</h2>
					<p class="mt-n2">Inicie sesión en su cuenta completando este formulario:</p>
				</div>
				<div class="col-12 text-center mb-4">

					<form @submit.prevent="triggerLogin" class="mt-3">
						<div class="row justify-content-center">

							<div class="col-12 col-md-10">
								<div class="input-group justify-content-center">
									<input type="text" style="width: 100px;" v-model="form.email" name="email"
										class="form-control" placeholder="Correo"
										:class="{ 'is-invalid': submitted && !form.email }" />
								</div>
								<div v-if="errors && errors.email" class="text-danger mt-1">{{ errors.email[0] }}</div>
							</div>
							<div class="col-12 col-md-10 mt-3">

								<div class="input-group" style="margin-bottom:6px !important;">
									<input type="password" v-model="form.password" name="password" class="form-control"
										placeholder="Contraseña" :class="{ 'is-invalid': submitted && !form.password }" />
								</div>
								<div v-if="errors && errors.password" class="text-danger">{{ errors.password[0] }}</div>
								<div class="row">
									<div class="col-12 mt-3 mb-3 text-center">
										<h5>
											<router-link to="/password-reset" class="text-login">
												Olvide mi contraseña
											</router-link>
										</h5>
									</div>
									<div class="col-12 text-center mt-3">
										<button class="btn btn-success" style="width: 200px;" :disabled="submitted">
											<span v-if="submitted">
												<i class="fa-solid fa-spinner fa-spin-pulse mr-1"></i> Enviando
											</span>
											<span v-else>
												Ingresar
											</span>
										</button>
									</div>
								</div>
							</div>
							<div class="col-12 mt-4 text-center">								
								<p class="text-muted">
									¿Aún no tienes cuenta? 
									<router-link class="pt-4" to="/register" >
										Regístrate
									</router-link>
								</p>							
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
	},
	data() {
		return {
			form: {
				email: '',
				password: '',
			},
			valid_mail: false,
			errors: {},
			submitted: false,
			send: false,
		}
	},
	methods: {
		async triggerLogin() {
			this.submitted = true;
			try {
				let login = await this.loginAction(this.form)
				if (login) {
					this.submitted = false;
					this.$nextTick(() => {
						location.reload()
					})
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.submitted = false;
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			loginAction: 'auth/login',
			errorNotification: 'alert/error',
		})
	}
};
</script>
<style scoped>
input {
	max-width: 415px;
	margin: 0 auto;
}
a {
    color: #20a288;
}
</style>