<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Lista de Proyectos</h3>
				<div class="card-tools">
					<router-link :to="`/projects/form`" class="btn btn-sm btn-outline-success">
						<i class="fa fa-plus"></i> Proyecto
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Cliente</th>
								<th>Nombre</th>
								<th>Fecha Inicio</th>
								<th>Fecha Fin</th>
								<th>Prioridad</th>
								<th>Estatus</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td colspan="8" class="text-center p-4">
									<i class="fas fa-spinner fa-pulse fa-5x"></i>
									<h6 class="mt-3">
										Cargando
									</h6>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="projects">
							<tr v-for="(project, index) in projects.data" :key="index">
								<td>{{ project.id }}</td>
								<td>{{ project.customer.alias }}</td>
								<td>{{ project.name }}</td>
								<td>{{ project.start_date | dateHuman }}</td>
								<td>{{ project.end_date | dateHuman }}</td>
								<td v-if="project.priority">{{ project.priority.name }}</td>
								<td v-else></td>
								<td>{{ project.project_status.name }}</td>
								<td>
									<router-link :to="`/projects/form/${project.id}`" title="Editar"
										class="btn btn-outline-primary btn-sm m-1">
										<i class="fas fa-edit "> </i>
									</router-link>
									<router-link :to="`/budget/${project.id}`" title="Presupuestos"
										class="btn btn-outline-secondary btn-sm m-1">
										<i class="fas fa-comments-dollar"></i>
									</router-link>
									<router-link :to="`/bonuses/${project.id}`" title="Bonos"
										class="btn btn-outline-info btn-sm m-1">
										<i class="fas fa-hand-holding-usd"></i>
									</router-link>
									<button type="button" @click="deleted(project.id)" title="Eliminar"
										class="btn-outline-danger btn btn-sm m-1 ">
										<i class="fa fa-trash"></i>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<pagination v-model="page" :records="projects.total" :per-page="projects.per_page" @paginate="list" />                       				
			</div>
		</div>
	</div>
</template>

<script>
import Pagination from 'vue-pagination-2';
import request from '../../services/request'
import { mapActions, mapMutations } from 'vuex';

import '../../helpers/filters'
export default {
	name: "projects-table",
	components: {
		Pagination,
	},
	data() {
		return {
            page:1,
			projects: {},
			search: '',
            load: false,
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Organigrama');
	},
	created() {
		Event.$on("event-load-projects", () => {
			this.list();
		});
	},
	methods: {
		deleted: function (id) {
			let tit = 'Eliminar proyecto';
			let txt = 'Seguro que quiere eliminar al proyecto';
			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/projects/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		async list(page = 1) {
            this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/projects';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
                    this.load = false;
					this.projects = data;
				}
			} catch (error) {
				if (error.response) {
                    this.load = false;
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			successNotification: 'alert/success',
			errorNotification: 'alert/error',
		}),
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		})
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
