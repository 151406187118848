<script>
import { mapMutations } from 'vuex';
import AreaHolidays from '../../component/holidays/AreaHolidaysComponent.vue';
import DaysHolidaysYears from '../../component/holidays/DaysHolidaysYearsComponent.vue';
export default {
	name: "holidays_config",
	components: {
		AreaHolidays,
		DaysHolidaysYears
	},
	mounted() {
		this.setHeaderTitle('Configurar Vacaciones');
	},
	methods: {
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
<template>
	<div class="row">
		<div class="col-md-8">
			<div class="card card-info card-outline">
				<div class="card-body">
					<AreaHolidays />
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card card-info card-outline">
				<div class="card-body">
					<DaysHolidaysYears />
				</div>
			</div>
		</div>
	</div>
</template>