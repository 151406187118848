<template>
    <modal name="addYear" v-bind:click-to-close="false" :width="400" :height="290" :adaptive="true">
        <div class="row pad" v-if="load == false">
            <div class="col-12 text-center">
                <h4>{{ titleModal }}</h4>
                <hr>
            </div>
            <div class="col-6">
                <label>Años</label>
                <input type="number" v-model="form.year" class="form-control" />
                <div v-if="errors && errors.year" class="text-danger">{{ errors.year[0] }}</div>
            </div>
            <div class="col-6">
                <label>Dias</label>
                <input type="number" v-model="form.days" class="form-control" />
                <div v-if="errors && errors.days" class="text-danger">{{ errors.days[0] }}</div>
            </div>
            <div class="col-12">
                <hr>
            </div>
            <div class="col-12 mt-3">
				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-warning" @click="reset">
							<i class="fas fa-undo-alt"></i>
							Cerrar
						</button>
					</div>
					<div class="col-6 text-right">
						<button class="btn btn-outline-success" @click="save">
							<i class="fas fa-save"></i>
							Guardar
						</button>

					</div>
				</div>
			</div>

        </div>
        <div class="row" v-else>
            <div class="col-md-12">
                <div class="load">Loading...</div>
            </div>
        </div>
    </modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request';

export default {
    data() {
        return {
            titleModal: 'Agregar regla',
            cargador: false,
            load: false,
            errors: {},
            form: {
                id: '',
                year: '',
                days: '',
                _method : 'POST'
            }
        }
    },
    created() {
        Event.$on("event-add-year", (id) => {
            this.titleModal = 'Agregar regla';
            if (id) {
                this.getId(id);
            }
        });
    },
    methods: {
        async save() {
			this.load = true;
			let _endpoint = `/days_holidays_years`;
            
            if (this.form.id != "") {
                this.form._method = 'PUT';
				_endpoint = `/days_holidays_years/${this.form.id}`;
			}

			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
					Event.$emit('event-load-year', '');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.load = false;
					this.errors = await this.errorNotification(error)
				}
			}
		},
        async getId(id) {
            let _endpoint = `/days_holidays_years/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {                    
                    this.form = data;
                    this.title = 'Editar regla';
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        reset() {
            this.form.id = '';
            this.form.year = '';
            this.form.days = '';
            this.form._method = 'POST';            
            this.errors = {};
            this.load = false;
            this.$modal.hide('addYear');
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>
<style>
.pad {
    padding: 10px !important;
}
</style>
