<template>
	<div class="col-12 ">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Formulacio de Proyectos</h3>
			</div>
			<div class="card-body">
				<form @submit.prevent="triggerSave">
					<div class="row">
						<div class="col-md-4">
							<div class="form-group">
								<label> Nombre </label>
								<input type="text" v-model="form.name" class="form-control">
								<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Fecha Inicio </label>
								<date-picker v-model="form.start_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
									:clearable="false"></date-picker>
								<div v-if="errors && errors.start_date" class="text-danger">{{ errors.start_date[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Fecha Fin </label>
								<date-picker v-model="form.end_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
									:clearable="false"></date-picker>
								<div v-if="errors && errors.end_date" class="text-danger">{{ errors.end_date[0] }}</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<label> Descripción </label>
								<textarea class="form-control" rows="3" v-model="form.description"></textarea>
								<div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Cliente </label>
								<multiselect v-model='form.customers_id' :options='catalogue.all_clients'
									placeholder='Buscar cliente' label='alias' track-by='id' :multiple="false">
								</multiselect>
								<div v-if="errors && errors.customers_id" class="text-danger">{{
										errors.customers_id[0]
								}}</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Prioridad </label>
								<multiselect v-model='form.priorities_id' :options='priorities'
									placeholder='Buscar opción' label='name' track-by='id' :multiple="false">
								</multiselect>
								<div v-if="errors && errors.priorities_id" class="text-danger">{{
										errors.priorities_id[0]
								}}</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Estatus </label>
								<multiselect v-model='form.project_statuses_id' :options='catalogue.statuses_project'
									placeholder='Buscar estatus' label='name' track-by='id' :multiple="false">
								</multiselect>
								<div v-if="errors && errors.project_statuses_id" class="text-danger">{{
										errors.project_statuses_id[0]
								}}</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<label> Usuarios </label>
								<multiselect v-model='form.users' :options='users' :custom-label='postWithTitle'
									placeholder='Buscar usuarios' label='title' track-by='id' :multiple="true"
									:loading="isLoadingSelect" :hide-selected="true"
									>
								</multiselect>
								<div v-if="errors && errors.users" class="text-danger">{{ errors.users[0] }}</div>
							</div>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-md-12">
							<button type="submit" class="btn float-right btn-outline-success">
								<i class="fas fa-save"></i> Guardar
							</button>
							<router-link to="/projects" class="btn float-left btn-outline-warning">
								<i class="fas fa-undo-alt"></i>
								Regresar
							</router-link>
						</div>
					</div>
				</form>

			</div>
		</div>
	</div>
</template>

<script>
import request from '../../services/request'
import { mapActions } from 'vuex';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

import Multiselect from 'vue-multiselect'
import Catalogue from '../../services/catalogue';

export default {
	name: "users-table",
	components: {
		DatePicker,
		Multiselect
	},
	data() {
		return {
			form: {
				id: '',
				name: '',
				start_date: '',
				end_date: '',
				description: '',
				priorities_id: '',
				customers_id:'',
				project_statuses_id:'',
				_method: 'POST',
				users: []
			},
			catalogue: new Catalogue(),
			isLoadingSelect: false,
			users: [],
			priorities: [],
			img_perfil: require('@/assets/img/profile.png'),
			errors: {},
			action: '/admin/user',
		}
	},
	mounted() {
		this.catalogue.get_all_clients();
		this.catalogue.get_statuses_project();
		this.asyncPriorities();
		this.asyncFind();
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
		}
	},
	methods: {
		async triggerSave() {
			let _endpoint = '/projects';
			if (this.form.id != "") {
				this.form._method = 'PUT';
				_endpoint = `/projects/${this.form.id}`;
			}

			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.successNotification(data.message);
					this.$router.push('/projects');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		postWithTitle(option) {
			return `${option.id} - ${option.name} ${option.last_name}`;
		},
		async asyncFind(query) {
			let search = { _search: query };
			this.isLoadingSelect = true;
			let _endpoint = '/catalogue/users';
			try {
				let { data } = await request.get(_endpoint, search)
				if (data) {
					this.isLoadingSelect = false;
					this.users = data;
				}
			} catch (error) {
				this.isLoadingSelect = false;
			}
		},
		async asyncPriorities() {
			let _endpoint = '/catalogue/priorities';
			let search = {};
			try {
				let { data } = await request.get(_endpoint, search)
				if (data) {
					this.priorities = data;
				}
			} catch (error) {
				this.isLoadingSelect = false;
			}
		},
		async get_id(id) {
			let _endpoint = `/projects/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.form.id = data.id;
					this.form.name = data.name;
					this.form.start_date = data.start_date;
					this.form.end_date = data.end_date;
					this.form.description = data.description;
					this.form.priorities_id = data.priority;
					this.form.users = data.users;
					this.form.customers_id = data.customer;
					this.form.project_statuses_id = data.project_status;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}
</style>
