import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store'

// Auth
import LayoutAuth from '@/pages/layouts/LayoutAuth'
import LoginPage from '../pages/auth/LoginPage'
import RegisterPage from '../pages/auth/RegisterPage'
import EmailResetPage from '../pages/auth/EmailResetPage'
import ResetPasswordPage from '../pages/auth/ResetPasswordPage'

// Admin
import LayoutAdmin from '../pages/layouts/LayoutAdmin'
import HomePage from '../pages/HomePage'
import Organization from '../pages/users/organization'
import Calendar from '../pages/calendar/index'

// Users
import ListaUsers from '../pages/users/index'
import FormUsers from '../pages/users/form'
import Profile from '@/pages/users/profile'
import MyTeam from '../pages/users/my_team'

// Permissions
import ListaPermissions from '../pages/permissions/index'
import FormPermissions from '../pages/permissions/form'

// Project
import ListaProject from '../pages/project/index'
import FormProject from '../pages/project/form'

// Project
import ListaHolidays from '../pages/holidays/index'
import MyHoliday from '@/pages/my_holidays/index'
import ValidateHoliday from '@/pages/holidays/validate_holiday'

// Customers
import ListaCustomers from '../pages/customers/index'
import FormCustomers from '../pages/customers/form'

// Contacts
import ListaContacts from '../pages/contacts/index'

// Bonuses
import ListaBonuses from '../pages/bonuses/index'

// Budget
import ListaBudget from '../pages/budget/index'

// Budget
import HolidaysExtras from '../pages/extra_holiday/index'

// Budget
import HolidaysConfig from '../pages/holidaysconfig/index'

Vue.use(VueRouter);

export const router = new VueRouter({
	mode: 'hash',
	routes: [
		// { path: '/', component: HomePage },
		{
			path: '/login',
			name: 'auth',
			component: LayoutAuth,
			children: [
				{ path: '/login', component: LoginPage, name: 'login' },
				{ path: '/register', component: RegisterPage, name: 'register' },
				{ path: '/password-reset', component: EmailResetPage, name: 'password-reset' },
				{ path: '/change-password', component: ResetPasswordPage, name: 'change-password' },
			]
		},
		{
			path: '/',
			name: 'app',
			component: LayoutAdmin,
			children: [
				{
					path: '/',
					name: 'app-home',
					component: HomePage,
				},
				// Users
				{
					path: '/users',
					name: 'users',
					component: ListaUsers,
				},
				{
					path: '/my_team',
					name: 'my_team',
					component: MyTeam,
				},
				{
					path: '/users/form',
					name: 'add_users',
					component: FormUsers,
				},
				{
					path: '/profile',
					name: 'profile',
					component: Profile,
				},
				{
					path: '/users/form/:id',
					name: 'edit_users',
					component: FormUsers,
				},
				// Projects
				{
					path: '/projects',
					name: 'projects',
					component: ListaProject,
				},
				{
					path: '/projects/form',
					name: 'add_projects',
					component: FormProject,
				},
				{
					path: '/projects/form/:id',
					name: 'edit_projects',
					component: FormProject,
				},
				{
					path: '/organization',
					name: 'organization',
					component: Organization,
				},
				{
					path: '/permissions',
					name: 'permissions',
					component: ListaPermissions,
				},
				{
					path: '/permissions/form/:id?',
					name: 'edit_permissions',
					component: FormPermissions,
				},
				{
					path: '/calendar',
					name: 'calendar',
					component: Calendar,
				},
				{
					path: '/holidays',
					name: 'holidays',
					component: ListaHolidays,
				},
				{
					path: '/my_holidays',
					name: 'my_holidays',
					component: MyHoliday,
				},
				{
					path: '/validate_holiday',
					name: 'validate_holiday',
					component: ValidateHoliday,
				},
				{
					path: '/customers',
					name: 'customers',
					component: ListaCustomers,
				},
				{
					path: '/customers/form',
					name: 'add_customers',
					component: FormCustomers,
				},
				{
					path: '/customers/form/:id?',
					name: 'edit_customers',
					component: FormCustomers,
				},
				{
					path: '/contacts/:id',
					name: 'contacts',
					component: ListaContacts,
				},
				{
					path: '/bonuses/:id',
					name: 'bonuses',
					component: ListaBonuses,
				},
				{
					path: '/budget/:id',
					name: 'budget',
					component: ListaBudget,
				},
				{
					path: '/holidays_config',
					name: 'holidays_config',
					component: HolidaysConfig,
				},

				{
					path: '/holidays_extras',
					name: 'holidays_extras',
					component: HolidaysExtras,
				},

			]
		},
		// otherwise redirect to home
		{ path: '*', redirect: '/' }
	]
});

router.beforeEach((to, from, next) => {

	// Cargador de pagina
	if (to.name != from.name) {
		store.state.status.routeChange = true;
	}

	// Accesos publicos
	const publicPages = ['/login', '/register', '/password-reset', '/change-password'];
	const authRequired = !publicPages.includes(to.path);


	// Verificamos si esta logueado
	const loggedIn = JSON.parse(localStorage.getItem('user'));
	let permissions = null;
	if (loggedIn) {
		permissions = loggedIn.profile ? loggedIn.profile.permissions : [];
	}

	const publicPagesLogin = ['/profile', '/my_holidays', '/my_team', '/my_holidays', '/validate_holiday'];
	const authRequiredAuth = publicPagesLogin.includes(to.path);

	if (loggedIn) {
		if (loggedIn.new == 1) {
			if (to.fullPath != '/profile') {
				store.commit("alert/error", "Es nectario completar el formulario para poder continuar");
				store.state.status.routeChange = false;
				return next('/profile');

			}
		}
	}

	// Redirige a home si se loguea
	if (loggedIn && !authRequired) {
		return next('/');
	}

	if (authRequiredAuth && loggedIn) {
		return next();
	}

	// Validamos permisos se usuario
	if (permissions) {
		if (to.fullPath != '/') {
			let res = permissions.findIndex(s => s.permission === to.name);
			if (res == -1) {
				return next('/');
			} else {
				next();
			}
		}
	}

	// Redirige a login si no esta logueado
	if (authRequired && !loggedIn) {
		return next('/login');
	}

	next();
});

router.afterEach((to, from) => {
	// Cargador de pagina
	if (to.name != from.name) {
		setTimeout(() => {
			store.state.status.routeChange = false;
		}, 1000);
	}

})

router.afterEach((to, from) => {
	// Cargador de pagina
	if (to.name != from.name) {
		setTimeout(() => {
			store.state.status.routeChange = false;
		}, 1000);
	}
})


export default router
