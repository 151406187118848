<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Lista de Vacaciones</h3>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Dirección :</label>
						<select class="form-control" v-model="search.area_id" @change="list()">
							<option value=""> Todos </option>
							<option :value="area.id"
								v-for="(area, index) in catalogue.areas" :key="index">
								{{ area.name }}
							</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Estatus :</label>
						<select class="form-control" v-model="search.status" @change="list()">
							<option value=""> Todos </option>
							<option value="1"> Por autorizar </option>
							<option value="4"> Autorizados </option>
							<option value="100"> Cancelados </option>
						</select>
					</div>
					<div class="col-md-10">
						<label>Fecha :</label>
						<date-picker v-model="search.date" range valueType="YYYY-MM-DD" format="DD-MM-YYYY"
							:clearable="true" range-separator=" - " />
					</div>
					<div class="col-md-2">
						<button style="margin-top: 30px;" @click="exportHolidays()"
							class="btn btn-outline-success btn-block">
							<i class="fa-solid fa-file-export"></i> Excel
						</button>

					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>#</th>
								<th>Periodo</th>
								<th>Usuario</th>
								<th>Estatus</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td colspan="5" class="text-center p-4">
									<i class="fas fa-spinner fa-pulse fa-5x"></i>
									<h6 class="mt-3">
										Cargando
									</h6>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="holidays.total > 0">
							<tr v-show="holiday.users" v-for="(holiday, index) in holidays.data" :key="index">
								<td class="v-center">{{ holiday.id }}</td>
								<td class="v-center">
									<p>
										{{ holiday.start_date | dateHuman }}
										<br> - <br>
										{{ holiday.end_date | dateHuman }}
									</p>
								</td>
								<td style="vertical-align: inherit;">
									<p v-if="holiday.users">
										<span
											v-if="holiday.users.name && holiday.users.name != null && holiday.users.name != 'null'">
											{{ holiday.users.name }}
										</span>
										<span
											v-if="holiday.users.last_name && holiday.users.last_name != null && holiday.users.last_name != 'null'">
											{{ holiday.users.last_name }}
										</span>

									</p>
								</td>
								<td>
									<TimeLineHoliday :holiday="holiday"> </TimeLineHoliday>
								</td>

								<td class="align-middle">
									<div class="col-12" v-if="authUser && holiday.id && holiday.users">

										<!-- JEFE DIRECTO-->

										<button class="btn btn-outline-success btn-sm" @click="validate(holiday)">
											<i class="far fa-file-pdf"></i> Ver formato
										</button>

									</div>

								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<pagination v-model="page" v-if="holidays.total" :records="holidays.total" :per-page="holidays.per_page"
					@paginate="list" />
			</div>
		</div>
		<ModalValidate />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ModalValidate from '@/component/holidays/ModalValidateComponent.vue';
import TimeLineHoliday from '@/component/holidays/TimeLineComponent.vue';
import Pagination from 'vue-pagination-2';
import request from '../../services/request'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import config from '@/services/config';
import Catalogue from '@/services/catalogue';

export default {
	name: "holidays_table",
	components: {
		DatePicker,
		Pagination,
		ModalValidate,
		TimeLineHoliday
	},
	data() {
		return {
			config: config,
			page: 1,
			holidays: {},
			search: {
				date: [],
				status: '',
				area_id: ''
			},
			catalogue: new Catalogue(),
			load: false,
			deep: false,
		}
	},
	async mounted() {

		await this.catchParameters();
		await this.catalogue.get_areas();
		this.deep = true;
	},
	created() {
		Event.$on("event-load-holidays", () => {
			this.list();
		});
	},
	methods: {
		exportHolidays() {
			let filter = config.apiUrl + '/export_holidays_filter?' + new URLSearchParams(this.search).toString();
			window.open(filter, '_blank');
		},
		async catchParameters() {
			let params = this.$route.query;
			if (params) {
				if (params.status) {
					this.search.status = params.status;
				}
				if (params.star && params.end) {
					this.search.date = [params.star, params.end];
				}
			}
			await this.list();
		},
		validate(holiday) {
			Event.$emit('event-load-pdf-holiday', holiday);
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/holidays';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					this.holidays = data;
					this.load = false;
				}
			} catch (error) {
				this.load = false;
				console.log(error);
			}
		},
		createHoliday() {
			this.$modal.show('new_holiday');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		search: {
			deep: true,
			immediate: false,
			handler() {
				if (this.deep) {
					console.log('filtro')
					this.list();
				}

			}
		}
	}
}
</script>

<!-- <style >
.timeline {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px;
}

.li {
	transition: all 200ms ease-in;
}

.timestamp {
	margin-bottom: 20px;
	padding: 0px 2px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: 100;
	font-size: 10px;
}

.status {
	padding: 0px 10px;
	display: flex;
	justify-content: center;
	border-top: 2px solid #D6DCE0;
	position: relative;
	transition: all 200ms ease-in;
}

.status h4 {
	font-weight: 600;
}

.status:before {
	content: "";
	width: 20px;
	height: 20px;
	background-color: white;
	border-radius: 25px;
	border: 1px solid #ddd;
	position: absolute;
	top: -12px;
	left: 42%;
	transition: all 200ms ease-in;
}

.li.complete .status {
	border-top: 2px solid #20c997;
}

.li.complete .status:before {
	background-color: #20c997;
	border: none;
	transition: all 200ms ease-in;
}

.li.complete .status h4 {
	color: #66DC71;
}

/*  */
.li.ready .status {
	border-top: 2px solid #20c997;
}

.li.ready .status:before {
	background-color: #20c997;
	border: none;
	transition: all 200ms ease-in;
}

.li.ready .status h4 {
	color: #66DC71;
}


@media (min-device-width: 320px) and (max-device-width: 700px) {
	.timeline {
		list-style-type: none;
		display: block;
	}

	.li {
		transition: all 200ms ease-in;
		display: flex;
		width: inherit;
	}

	.timestamp {
		width: 80px;
	}

	.status:before {
		left: -8%;
		top: 30%;
		transition: all 200ms ease-in;
	}
}


.txt_status {
	margin-top: 15px;
	font-size: 12px;
}

.div_line {
	/* width: 200px;
     padding-left: 200px;
     overflow: auto; */
	padding: 0px;
	position: initial;
	width: auto;
}

.timeline::before {
	background-color: transparent;
}
</style> -->
