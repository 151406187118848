<template>
    <div class="row">
        <div class="col-8">
            <h3>Lista de contactos</h3>
        </div>
        <div class="col-4">
            <NewContact />
            <a @click="showModal('')" class="btn float-right btn-outline-success">
                <i class="fa fa-plus"></i> Contacto
            </a>
        </div>
        <div class="col-12">
            <hr>

            <div class="row">
                <div class="col-md-6">
                    <label>Buscar :</label>
                    <input type="text" class="form-control" v-model="params.search" @keyup="list()" />
                </div>
            </div>
            <br>
            <div class="table-responsive">
                <table class="table table-bordered text-center text-sm">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>Teléfono</th>
                            <th>Humor</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody v-if="contacts">
                        <tr v-for="(contact, index) in contacts.data" :key="index">
                            <td>{{ contact.id }}</td>
                            <td>{{ contact.name }}</td>
                            <td>{{ contact.email }}</td>
                            <td>{{ contact.phone }}</td>
                            <td v-if="contact.mood" class="text-center text-info">
                                <i :class="[contact.mood.icon]"> </i><br>
                                <p style="font-size: 13px;">{{ contact.mood.name }}</p>
                            </td>
                            <td v-else>Sin Humor</td>
                            <td>
                                <a @click="showModal(contact.id)" v-can="'edit_contacts'"
                                    class="btn btn-outline-primary btn-sm">
                                    <i class="fas fa-edit "> </i>
                                </a>
                                <button type="button" @click="deleted(contact.id)" v-can="'deleted_contacts'"
                                    class="btn-outline-danger btn btn-sm ">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td align="center" colspan="5">Sin resultados.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination v-model="page" :records="contacts.total" :per-page="contacts.per_page" @paginate="list" />                        
        </div>
    </div>
</template>

<script>
import NewContact from '../../component/contacts/NewContactsComponent.vue'
import Pagination from 'vue-pagination-2';
import request from '../../services/request'
import { mapActions } from 'vuex';

export default {
    name: "contacts-table",
    components: {
        Pagination,
        NewContact
    },
    data() {
        return {
            page:1,
            contacts: {},
            params: {
                customers_id: '',
                search: ''
            },
        }
    },
    mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.params.customers_id = id;
        }
        this.list();
    },
    created() {
        Event.$on("event-load-contacts", () => {
            this.list();
        });
    },
    methods: {
        deleted: function (id) {
            let tit = 'Eliminar contacto';
            let txt = 'Seguro que quiere eliminar al contacto';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
            let _endpoint = '/contacts/' + id;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        showModal(id) {
            Event.$emit('event-new-contact', id);
            this.$modal.show('NewContact');
        },
        async list(page = 1) {
            let service = request;
            let params = this.params;
            let _endpoint = '/contacts';
            try {
                let { data } = await service.list(_endpoint, page, params)
                if (data) {
                    this.contacts = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}
</style>
