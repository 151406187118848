<template>
    <modal name="NewUser" :width="800" height="'auto'" :adaptive="true">
        <div class="row" v-if="load">
			<div class="col-md-12">
				<div class="load">Loading...</div>
			</div>
		</div>
        <div v-else class="col-12 p-4">
            <form @submit.prevent="triggerSave">
                <div class="row">
                    <div class="col-12 text-center">
                        <h3>Imagen de perfil</h3>
                        <img class="img-fluid rounded" style="width: 200px;" :src="img_perfil" alt="">
                        <br>
                        <br>
                        <input type="file" id="file" class="inputfile" @change="loadPhoto">
                        <label for="file"><i class="fas fa-upload"></i> Subir imagen </label>
                    </div>
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Nombre </label>
                            <input type="text" v-model="form.name" class="form-control">
                            <div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Apellidos </label>
                            <input type="text" v-model="form.last_name" class="form-control">
                            <div v-if="errors && errors.last_name" class="text-danger">{{ errors.last_name[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Teléfono </label>
                            <input type="text" v-model="form.phone" class="form-control">
                            <div v-if="errors && errors.phone" class="text-danger">{{ errors.phone[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> WhatsApp </label>
                            <input type="text" v-model="form.whathsapp" class="form-control">
                            <div v-if="errors && errors.whathsapp" class="text-danger">{{ errors.whathsapp[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Email </label>
                            <input type="mail" v-model="form.email" class="form-control">
                            <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Fecha Ingreso </label><br>
                            <date-picker v-model="form.admission_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
                                :clearable="false"></date-picker>
                            <div v-if="errors && errors.admission_date" class="text-danger">{{
                                errors.admission_date[0]
                            }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Fecha de nacimiento </label>
                            <date-picker v-model="form.birthday" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
                                :clearable="false"></date-picker>
                            <div v-if="errors && errors.birthday" class="text-danger">{{ errors.birthday[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Perfil </label>
                            <select v-model="form.organization_charts_id" class="form-control">
                                <option v-show="profile.areas_id == cat_profile.areas_id || authUser.profile.areas_id == cat_profile.areas_id " :value="cat_profile.id"
                                    v-for="(cat_profile, index) in profiles" :key="index">
                                    <span v-if="cat_profile.profil">{{ cat_profile.profil.name }}</span>
                                </option>
                            </select>
                            <div v-if="errors && errors.organization_charts_id" class="text-danger">{{
                                errors.organization_charts_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4" v-if="showUsers">
                        <div class="form-group">
                            <label> Jefe Directo </label>
                            <select v-model="form.user_id" class="form-control">
                                <option value="null"> Sin Jefe </option>
                                <option :value="user.id" v-for="(user, index) in catalogue.users_area" :key="index">
                                    {{ user.name }} {{ user.last_name }}
                                </option>
                            </select>
                            <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body text-center" v-if="img_signature">
                                <h4>Firma</h4>
                                <img class="img-fluid rounded" :src="img_signature" alt="">
                            </div>
                            <div class="card-body text-center" v-else>
                                <h4>Firma</h4>
                                <h5>Sin Firma</h5>
                            </div>

                        </div>
                    </div>

                    <div class="col-12">
                        <hr>
                    </div>

                    <div class="col-md-12">
                        <button type="submit" class="btn float-right btn-outline-success" :disabled="submitted">
                            <span v-if="submitted">
                                <i class="fa-solid fa-spinner fa-spin-pulse mr-1"></i> Enviando
                            </span>
                            <span v-else>
                                <i class="fas fa-save"></i> Guardar
                            </span>
                        </button>
                        <button type="button" @click="reset" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </form>

        </div>
    </modal>
</template>

<script>
import request from '@/services/request'
import { mapActions, mapGetters } from 'vuex';

import Catalogue from '@/services/catalogue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import config from '@/services/config';


export default {
    name: "profile-user",
    components: {
        DatePicker,
    },
    data() {
        return {
            form: {
                id: '',
                name: '',
                last_name: '',
                img_perfil: '',
                phone: '',
                whathsapp: '',
                organization_charts_id: '',
                email: '',
                birthday: '',
                admission_date: '',
                user_id: '',
                approve_holidays_rh: '',
                remember_anniversary: '',
                remember_birthdat: '',
            },
            showUsers: false,
            area: '',
            profiles: [],
            img_perfil: require('@/assets/img/profile.png'),
            img_signature: '',
            errors: {},
            action: '/admin/user',
            catalogue: new Catalogue(),
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgba(255,255,255, 0)"
            },
            profile: {},
            type1: 'password',
            type2: 'password',
            submitted: false,
            load: false,
        }
    },
    mounted() {
        this.getProfiles();
    },
    methods: {
        reset() {
            this.load = false;
            this.form.id = '';
            this.form.name = '';
            this.form.last_name = '';
            this.form.img_perfil = '';
            this.form.phone = '';
            this.form.whathsapp = '';
            this.form.organization_charts_id = '';
            this.form.email = '';
            this.form.birthday = '';
            this.form.admission_date = '';
            this.form.user_id = '';
            this.form.approve_holidays_rh = '';
            this.form.remember_anniversary = '';
            this.form.remember_birthdat = '';
            this.img_perfil = require('@/assets/img/profile.png'),
                this.img_signature = '';
            this.errors = {};
            this.action = '/admin/user';
            this.form._method = 'POST';
            this.$modal.hide('NewUser');
        },
        reloadUsers() {
            Event.$emit('event-load-users');
        },
        clear() {
            var _this = this;
            _this.$refs.signature1.clear();
        },
        loadPhoto(event) {
            if (event.target.files[0].type == "image/png" ||
                event.target.files[0].type == "image/jpeg") {
                this.form.img_perfil = event.target.files[0];
                this.img_perfil = URL.createObjectURL(event.target.files[0]);
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PNG , JPGE',
                });
            }
        },
        async triggerSave() {
            this.submitted = true;
            let signature = "";
            if (this.$refs.signature1) {
                signature = this.$refs.signature1.save();
            }

            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('name', this.form.name);
            data_form.append('last_name', this.form.last_name);
            data_form.append('email', this.form.email);
            data_form.append('phone', this.form.phone);
            data_form.append('organization_charts_id', this.form.organization_charts_id);
            data_form.append('img_perfil', this.form.img_perfil);
            data_form.append('whathsapp', this.form.whathsapp);
            data_form.append('birthday', this.form.birthday);
            data_form.append('admission_date', this.form.admission_date);
            data_form.append('user_id', this.form.user_id);
            data_form.append('password', this.form.password);
            data_form.append('password_confirmation', this.form.password_confirmation);
            data_form.append('register_admin', 1);

            if (signature) {
                data_form.append('signature', signature);
            }

            let _endpoint = '/users';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/users/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    // this.users = data.data;
                    this.submitted = false;
                    this.successNotification(data.message);
                    this.reset();
                    this.reloadUsers();
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.submitted = false;
                    let info_error = await this.errorNotification(error);
                    if (info_error == 'Logout') {
                        this.$nextTick(() => {
                            location.reload()
                        })
                    } else {
                        this.errors = info_error;
                    }
                }
            }
        },
        async get_id(id) {
            this.load = true;
            let _endpoint = `/users/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    this.form.id = id;
                    if (data.profile_picture) {
                        this.img_perfil = config.apiUrl + data.profile_picture;
                    } else {
                        this.img_perfil = require('@/assets/img/profile.png');
                    }
                    if (data.signature) {
                        this.img_signature = config.apiUrl + '/storage/' + data.signature;
                    }
                    this.form.name = data.name ? data.name : '';
                    this.form.last_name = data.last_name ? data.last_name : '';
                    this.form.phone = data.phone ? data.phone : '';
                    this.form.whathsapp = data.whathsapp ? data.whathsapp : '';
                    this.form.email = data.email ? data.email : '';
                    this.form.organization_charts_id = data.organization_charts_id ? data.organization_charts_id : '';
                    this.form.birthday = data.birthday ? data.birthday : '';
                    this.form.admission_date = data.admission_date ? data.admission_date : '';
                    this.form.user_id = data.user_id ? data.user_id : '';
                    this.form.approve_holidays_rh = data.approve_holidays_rh == 1 ? true : false;
                    this.form.remember_anniversary = data.remember_anniversary == 1 ? true : false;
                    this.form.remember_birthdat = data.remember_birthdat == 1 ? true : false;
                    if (data.profile) {
                        this.profile = data.profile;
                    } else {
                        this.profile = {};
                    }
                    this.load = false;
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.load = false;
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        async getProfiles() {
            let service = request;
            let _endpoint = '/catalogue/profiles';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.profiles = data;
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        async validProfile() {
            let value = this.form.organization_charts_id;
            if (value != 'null') {
                let _endpoint = '/profiles/' + value;
                try {
                    let { data } = await request.get(_endpoint)
                    if (data.profiles_id != 1) {
                        this.showUsers = true;
                        this.catalogue.get_users_area(data.areas_id, this.form.id);
                    } else {
                        this.showUsers = false;
                    }
                } catch (error) {
                    this.errors = {};
                    if (error.response) {
                        this.errors = await this.errorNotification(error)
                    }
                }
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-edit-user", id => {
            this.title = 'Nuevo usuario';
            if (id) {
                this.title = 'Editar usuario';
                this.get_id(id);
            }
            this.$modal.show('NewUser');

        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        'form.organization_charts_id': {
            handler: function () {
                this.validProfile();
            },
            deep: true
        }
    },
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}

.fs-6 {
    font-size: 12px;
}

.custom-file-label::after {
    content: "Archivo";
}
</style>