<template>
    <modal name="FileBudget" v-bind:click-to-close="false" :width="400" :adaptive="true">
        <div class="row pad">
            <div class="col-12">
                <h4>Subir Archivos</h4>
                <hr>
            </div>
            <div class="col-12">
                <div class="example-drag">
                    <div class="upload">
                        <ul v-if="files.length">
                            <li v-for="file in files" :key="file.id">
                                <span>{{ file.name }}</span> -
                                <span>{{ file.size | formatSize }}</span> -
                                <span v-if="file.error">{{ file.error }}</span>
                                <span v-else-if="file.success">success</span>
                                <span v-else-if="file.active">active</span>
                                <span v-else></span>
                            </li>
                        </ul>
                        <ul class="pl-0" v-else>
                            <td>
                                <div class="text-center p-5 cd ">
                                    <h4>DColoque los archivos en cualquier lugar para cargarlos<br />o</h4>
                                    <label for="file" class="btn btn-lg btn-outline-primary">Selecciona archivos</label>
                                </div>
                            </td>
                        </ul>

                        <div style="padding-left: 0px;" v-show="$refs.upload && $refs.upload.dropActive"
                            class="drop-active">
                            <h3>Drop files to upload</h3>
                        </div>

                        <hr>

                        <div class="example-btn text-center">
                            <file-upload class="btn btn-outline-primary" @before-send="setHeaders"
                                :post-action="`${config.apiUrl}/api/budgets/files/${id}`" :multiple="true" :drop="true"
                                :drop-directory="true" v-model="files" ref="upload">
                                <i class="fa fa-plus"></i>
                                Selecciona archivos
                            </file-upload>
                            <button type="button" class="btn btn-outline-success"
                                v-if="!$refs.upload || !$refs.upload.active"
                                @click.prevent="$refs.upload.active = true">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                Iniciar la subida
                            </button>
                            <button type="button" class="btn btn-outline-danger" v-else
                                @click.prevent="$refs.upload.active = false">
                                <i class="fa fa-stop" aria-hidden="true"></i>
                                Detener carga
                            </button>
                            <button class="btn btn-outline-warning mt-2" @click="reset">
                                <i class="fas fa-undo-alt"></i>
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </modal>
</template>
<script>
import FileUpload from 'vue-upload-component'
import config from '@/services/config';

export default {
    data() {
        return {
            config: config,
            id: '',
            files: [],
        }
    },
    components: {
        FileUpload,
    },
    methods: {
        reset() {
            Event.$emit('event-load-budgets', '');
            this.id = '';
            this.files = [];
            this.$modal.hide('FileBudget');
            
        },
        setHeaders : (ev) => {
            ev.formData.append("upload_preset", "default");
            ev.xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        }
    },
    created() {
        Event.$on("event-files-budgets", id => {
            this.id = id;
        });
    },
}
</script>
<style>
.cd {
    border: 1px solid #ddd;
    border-radius: 10px;
}

.example-drag label.btn {
    margin-bottom: 0;
    margin-right: 1rem;
}

.example-drag .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    opacity: .6;
    text-align: center;
    background: #000;
}

.example-drag .drop-active h3 {
    margin: -.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
}
</style>
