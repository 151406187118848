import axios from "axios";
import store from '../store/index';
import config from '@/services/config';

// const URL = '/dashboard';
const URL = `${config.apiUrl}/api`;

export default {
    list(endpoint=null, page=1, search=null) {
        let showPages = store.state.showPages;
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0]=='/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.get(final_endpoint, {params: {_ajax: new Date().getTime(), page:page, _search: search, n: showPages}})
        } else {
            return;
        }
    },
    get(endpoint=null, params={}) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0]=='/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            params._ajax = new Date().getTime();
            return axios.get(final_endpoint, {params})
        } else {
            return;
        }
    },
    post(endpoint=null, payload) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0]=='/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.post(final_endpoint, payload, config);
        } else {
            return;
        }
    },
	postMedia(endpoint=null, payload) {
			if (endpoint) {
					let final_endpoint;
					if (endpoint[0]=='/') {
							final_endpoint = `${URL}${endpoint}`;
					} else {
							final_endpoint = `${URL}/${endpoint}`;
					}

					return axios.post(final_endpoint, payload,  {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					});
			} else {
					return;
			}
	},
    destroy(endpoint) {
        if (endpoint) {
            let final_endpoint;
            if (endpoint[0]=='/') {
                final_endpoint = `${URL}${endpoint}`;
            } else {
                final_endpoint = `${URL}/${endpoint}`;
            }
            return axios.delete(final_endpoint);
        } else {
            return;
        }
    },
    setLanguage(lang = 'es', params = {}) {
        let final_endpoint = `${URL}/language/set/${lang}`;
        params._ajax = new Date().getTime();
        return axios.get(final_endpoint, {params})
    },
    logout() {
        return axios.post('/logout');
    },
}
