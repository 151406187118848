<template>
    <div class="col-12">
        <div class="card card-outline card-info">
            <div class="card-header">
                <h3 class="card-title">Lista de Clientes</h3>
                <div class="card-tools">
                    <a @click="CallNewCustomers()" v-can="'add_customers'" class="btn btn-sm btn-outline-success">
                        <i class="fa fa-plus"></i> Cliente
                    </a>
                    <NewCustomers />
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <label>Buscar :</label>
                        <input type="text" class="form-control" v-model="search" @keyup="list()" />
                    </div>
                </div>
                <br>
                <div class="table-responsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Logo</th>
                                <th>Alias</th>
                                <th>Correo</th>
                                <th>Teléfono</th>
                                <th>RFC</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody v-if="load">
							<tr>
								<td colspan="7" class="text-center p-4">
									<i class="fas fa-spinner fa-pulse fa-5x"></i>
									<h6 class="mt-3">
										Cargando
									</h6>
								</td>
							</tr>
						</tbody>
                        <tbody v-else-if="customers">
                            <tr v-for="(customer, index) in customers.data" :key="index">
                                <td>{{ customer.id }}</td>
                                <td v-if="customer.logo">
                                    <img :src="config.apiUrl + customer.logo" class="img-fluid" alt="logo">
                                </td>
                                <td v-else>
                                    <img :src="require('@/assets/img/sin-logo.jpeg')" class="img-fluid" alt="logo">
                                </td>
                                <td>{{ customer.alias }}</td>
                                <td>{{ customer.email }}</td>
                                <td>{{ customer.phone }}</td>
                                <td>{{ customer.rfc }}</td>
                                <td>
                                    <router-link :to="`/customers/form/${customer.id}`" v-can="'edit_customers'"
                                        class="btn btn-outline-primary btn-sm m-1">
                                        <i class="fas fa-edit "> </i>
                                    </router-link>
                                    <!-- <router-link :to="`/contacts/${customer.id}`" v-can="'contacts'"
                                        class="btn btn-outline-info btn-sm m-1">
                                        <i class="fas fa-users"></i>
                                    </router-link> -->
                                    <button type="button" @click="deleted(customer.id)" v-can="'deleted_customers'"
                                        class="btn-outline-danger btn btn-sm m-1 ">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td align="center" colspan="5">Sin resultados.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <pagination v-model="page" :records="customers.total" :per-page="customers.per_page" @paginate="list" />
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from 'vue-pagination-2';
import request from '../../services/request'
import { mapActions, mapMutations } from 'vuex';
import NewCustomers from '../../component/customers/NewCustomersComponent.vue';
import config from '@/services/config';

export default {
    name: "customers-table",
    components: {
        Pagination,
        NewCustomers
    },
    data() {
        return {
            page: 1,
            config: config,
            customers: {},
            search: '',
            load: false,
        }
    },
    methods: {
        deleted: function (id) {
            let tit = 'Eliminar Cliente';
            let txt = 'Seguro que quiere eliminar al Cliente';
            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
            let _endpoint = '/customers/' + id;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        CallNewCustomers() {
            this.$modal.show('ModalCustomer');
        },
        async list(page = 1) {
            this.load = true;
            let service = request;
            let search = this.search;
            let _endpoint = '/customers';
            try {
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    this.customers = data;
                    this.load = false;
                }
            } catch (error) {
                this.load = false;
                console.log(error);
            }
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    mounted() {
        this.list();
        this.setHeaderTitle('Clientes');
    }
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.img-fluid {
    max-width: 50px;
}
</style>
