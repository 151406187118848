<template>
	<modal name="NewExtraHoliday" v-bind:click-to-close="false" :width="400" :height="'auto'" :adaptive="true">
		<div class="row pad" v-if="load == false">
			<div class="col-12">
				<h4>{{ title }}</h4>
				<hr>
				<div class="form-group">
					<label for="">Usuario :</label>
					<multiselect v-model='form.user_id' :options='catalogue.all_users' placeholder='Buscar opción'
						:custom-label="nameWithLang" track-by='id' :multiple="false">
						<span slot="noResult">No se encontraron usuario con este nombre.</span>

					</multiselect>
					<div v-if="(errors && errors.user_id)" class="text-danger">{{ errors.user_id[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Tipo :</label>
					<select v-model="form.type" class="form-control">
						<option value="">Sin tipo</option>
						<option value="1">Por S.A.</option>
						<option value="2">Del periodo anterior</option>
					</select>
					<div v-if="(errors && errors.type)" class="text-danger">{{ errors.type[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Expiración :</label>
					<date-picker v-model="form.expiration" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
									:clearable="false"></date-picker>
					<div v-if="(errors && errors.expiration)" class="text-danger">{{ errors.expiration[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Dias :</label>
					<input type="number" v-model="form.days" class="form-control">
					<div v-if="(errors && errors.days)" class="text-danger">{{ errors.days[0] }}</div>
				</div>
			</div>
			<div class="col-12 mt-3">
				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-warning" @click="reset">
							<i class="fas fa-undo-alt"></i>
							Cerrar
						</button>
					</div>
					<div class="col-6 text-right">
						<button class="btn btn-outline-success" @click="save">
							<i class="fas fa-save"></i>
							Guardar
						</button>

					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<div class="load">Loading...</div>
			</div>
		</div>
	</modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'
import Catalogue from '../../services/catalogue';
import Multiselect from 'vue-multiselect'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
	name: "form-holidays-extras",
	components: {
		Multiselect,
		DatePicker
	},
	data() {
		return {
			title: 'Vacaciones extras',
			form: {
				id: '',
				user_id: '',
				type: '',
				expiration: '',
				days: '',
			},
			errors: {},
			load: false,
			catalogue: new Catalogue(),
		}
	},
	created() {
		Event.$on("event-new-holiday-extra", holiday => {
			console.log(holiday);
			if (holiday) {
				this.form.id = holiday.id;
				this.form.user_id = holiday.users;
				this.form.type = holiday.type;
				this.form.expiration = holiday.expiration;
				this.form.days = holiday.days;
			}
			this.$modal.show('NewExtraHoliday');
		});
	},
	mounted() {
		this.catalogue.get_all_users();
	},
	methods: {
		nameWithLang({ name, last_name }) {
			return `${name} ${last_name}`
		},
		async save() {
			this.load = true;
			let _endpoint = '';
			if (this.form.id) {
				_endpoint = `/holidays_extras/${this.form.id}`;
				this.form._method = 'PUT';
			} else {
				_endpoint = `/holidays_extras`;
			}
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
					Event.$emit('event-load-holidays', '');
				}
			} catch (error) {
				this.load = false;
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error);
				}
			}
		},
		reset() {
			this.load = false;
			this.errors = {};
			this.form.id = '';
			this.form.id = '';
			this.form.user_id = '';
			this.form.type = '';
			this.form.expiration = '';
			this.form.days = '';
			this.form._method = 'POST';
			this.$modal.hide('NewExtraHoliday');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style>
.pad {
	padding: 10px !important;
}
</style>
