<template>
	<div class="row bg align-items-center justify-content-center">
		<div class="w-75">
			<div class="row">
				<div class="col-12 text-center">
					<img :src="require('@/assets/img/logo.png')" class="mt-5 mb-5" alt="Logo" width="150px;"><br>
					<h3 style="color: #199f88;" class="login-box-msg mt-n4">Modificar contraseña</h3>
					<p class="mt-n2">Ingresa los datos para recuperar <br>tu contraseña</p>
					<div class="col-12 text-center mb-4">

						<form @submit.prevent="triggerChangePassword">
							<div class="row justify-content-center">

								<div class="col-12 col-md-10">
									<div class="input-group ">
										<input type="text" v-model="form.email" name="email" class="form-control"
											placeholder="Correo" :class="{ 'is-invalid': submitted && !form.email }" />
									</div>
									<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
								</div>
								<div class="col-12 col-md-10 mt-3">
									<div class="input-group" style="margin-bottom:6px !important;">
										<input type="password" v-model="form.password" name="password" class="form-control"
											placeholder="Nueva contraseña"
											:class="{ 'is-invalid': submitted && !errors.password }" />
									</div>
									<div v-if="errors && errors.password" class="text-danger">{{ errors.password[0] }}</div>
									<div class="row">
										<div class="col-12 mt-3 mb-3 text-center">
											<h5>
												<router-link to="/login" class="text-login">
													Iniciar sesión
												</router-link>
											</h5>
										</div>
										<div class="col-12 text-center mt-3">
											<button class="btn btn-success" style="width: 200px;" :disabled="submitted">
												<span v-if="submitted">
													<i class="fa-solid fa-spinner fa-spin-pulse mr-1"></i> Enviando
												</span>
												<span v-else>
													Modificar
												</span>
											</button>
										</div>
									</div>

								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
	},
	data() {
		return {
			form: {
				passwordToken: '',
				email: '',
				password: '',
			},
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerChangePassword() {
			this.submitted = true;
			try {
				let reset = await this.changePassword(this.form)
				if (reset) {
					this.submitted = false;
					this.successNotification(reset.message);
					this.$router.push('/login')
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.submitted = false;
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			changePassword: 'auth/changepass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	created() {	
		let tkn = this.$route.query.token;
		console.log(tkn);
		if (tkn) {
			this.form.passwordToken = tkn;
		} else {
			this.errorNotification('Comprueba la URL para modificar la contraseña.');
			this.$router.push('/login')
		}
	},
};
</script>
<style scoped>
input {
	max-width: 415px;
	margin: 0 auto;
}
</style>