<template>
    <form @submit.prevent="triggerSave">
        <div class="row">
            <div class="col-12">
                <H3>Facturación</H3>
                <hr>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label> Razón Social</label>
                    <input type="text" v-model="form.bussiness_name" class="form-control" />
                    <div v-if="errors && errors.bussiness_name" class="text-danger">
                        {{ errors.bussiness_name[0] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label> R.F.C.</label>
                    <input type="text" v-model="form.rfc" class="form-control" />
                    <div v-if="errors && errors.rfc" class="text-danger">
                        {{ errors.rfc[0] }}
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <NewCountry />
                <label>País</label>
                <div class="form-group" style=" display: flex;">
                    <select class="form-control" v-model="form.country_id">
                        <option value=""> -- Selecciona una opción --</option>
                        <option v-for="(country, index) in catalogue.countrys" :key="index" :value="country.id">{{
                            country.name
                        }}</option>
                    </select>
                    <div class="input-group-prepend">
                        <button type="button" class="btn btn-sm btn-success" @click="add_country()">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div v-if="errors && errors.country_id" class="text-danger" style="margin-top: -15px;">
                    {{ errors.country_id[0] }}
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Código Postal</label>
                    <input type="text" v-model="form.zip" maxlength="5" class="form-control" @keyup="get_zip" />
                    <div v-if="errors && errors.zip" class="text-danger">
                        {{ errors.zip[0] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Estado</label>
                    <select class="form-control" v-model="form.state" v-if="form.country_id == 1">
                        <option value=""> -- Selecciona una opción --</option>
                        <option v-for="(state, index) in catalogue
                        .zip.state" :key="index" :value="state.state">{{ state.state }}</option>
                    </select>
                    <input type="text" v-model="form.state" v-if="form.country_id != 1" class="form-control" />
                    <div v-if="errors && errors.state" class="text-danger">
                        {{ errors.state[0] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Delegación/Municipio</label>
                    <select class="form-control" v-model="form.municipality" v-if="form.country_id == 1">
                        <option value=""> -- Selecciona una opción --</option>
                        <option v-for="(municipality, index) in catalogue
                        .zip.municipality" :key="index" :value="municipality.municipality">{{
        municipality.municipality
    }}
                        </option>
                    </select>
                    <input type="text" v-model="form.municipality" v-if="form.country_id != 1" class="form-control" />
                    <div v-if="errors && errors.municipality" class="text-danger">
                        {{ errors.municipality[0] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Colonia</label>
                    <select class="form-control" v-model="form.colony" v-if="form.country_id == 1">
                        <option value=""> -- Selecciona una opción --</option>
                        <option v-for="(suburb, index) in catalogue
                        .zip.suburb" :key="index" :value="suburb.suburb">{{ suburb.suburb }}</option>
                    </select>
                    <input type="text" v-model="form.colony" class="form-control" v-if="form.country_id != 1" />
                    <div v-if="errors && errors.colony" class="text-danger">
                        {{ errors.colony[0] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Calle</label>
                    <input type="text" v-model="form.street" class="form-control" />
                    <div v-if="errors && errors.street" class="text-danger">
                        {{ errors.street[0] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Número Exterior</label>
                    <input type="text" v-model="form.number_exterior" class="form-control" />
                    <div v-if="errors && errors.number_exterior" class="text-danger">
                        {{ errors.number_exterior[0] }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Número Interior</label>
                    <input type="text" v-model="form.interior_number" class="form-control" />
                    <div v-if="errors && errors.interior_number" class="text-danger">
                        {{ errors.interior_number[0] }}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <hr>
                <button type="submit" class="btn float-right btn-outline-success">
                    <i class="fas fa-save"></i> Guardar
                </button>
            </div>
        </div>
    </form>

</template>
<script>
import request from '../../services/request'
import { mapActions } from 'vuex';

import Catalogue from '../../services/catalogue';
import config from '@/services/config';
import NewCountry from '../../component/country/NewCountryComponent.vue';


export default {
    name: "users-table",
    components: {      
        NewCountry
    },
    data() {
        return {
            form: {
                id: '',
                bussiness_name: '',
                rfc: '',
                country_id: '',
                zip: '',
                state: '',
                municipality: '',
                colony: '',
                street: '',
                number_exterior: '',
                interior_number: '',
                customer_id: '',
                // _method: 'POST',
            },
            isLoadingSelect: false,
            logo: require('@/assets/img/sin-logo.jpeg'),
            errors: {},
            action: '/customers',
            catalogue: new Catalogue(),
        }
    },
    mounted() {
        this.catalogue.get_countrys();
        let id = this.$route.params.id;
        this.catalogue.get_main_clients(id);
        if (id) {
            this.get_id(id);
        }
    },
    created() {
        Event.$on("event-load-country", () => {
            this.catalogue.get_countrys();
        });
    },
    methods: {
        add_country() {
            this.$modal.show('NewCountry');
        },
        async get_id(id) {
            let _endpoint = `/customers/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    let form = this.form;
                    Object.keys(form).forEach(key => {
                        this.form[key] = data[key];
                    });
                    this.get_zip();
                    if (data.logo) {
                        this.logo = config.apiUrl + data.logo;
                    }
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        csv_file(event) {
            if (event.target.files[0].type == "image/png" ||
                event.target.files[0].type == "image/jpeg") {
                this.form.logo = event.target.files[0];
                this.logo = URL.createObjectURL(event.target.files[0]);
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PNG , JPGE',
                });
            }
        },
        async triggerSave() {
            const data_form = new FormData();
            let form = this.form;
            Object.keys(form).forEach(key => {
                data_form.append(key, form[key]);
            });

            let _endpoint = `/customers/billing/${this.form.id}`;

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    // this.$router.push('/customers');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    let info_error = await this.errorNotification(error);
                    if (info_error == 'Logout') {
                        this.$nextTick(() => {
                            location.reload()
                        })
                    } else {
                        this.errors = info_error;
                    }
                }
            }
        },
        async get_zip() {
            let zip = this.form.zip;
            let country = this.form.country_id;
            if (zip.length == 5 && country == 1) {
                this.catalogue.get_zip(zip);
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>










<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}
</style>
