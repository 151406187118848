<script>
// import PageMenu from './PageMenu.vue';
// import titlePage from './titlePage.vue';
import PageMenu from './PageMenu.vue';
import titlePage from './TitlePage.vue';
import { mapActions, mapGetters } from 'vuex';
import config from '@/services/config';
import Catalogue from '@/services/catalogue';
import SessionTimeOut from '@/component/SessionTimeoutCounter.vue'

export default {
	components: {
		PageMenu,
		titlePage,
		SessionTimeOut
	},
	data() {
		return {
			config: config,
			imageBackground: `/img/oficina.jpg`,
			catalogue: new Catalogue(),
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	mounted() {
		this.catalogue.get_validate_holiday();
	},
	methods: {
		async postLogout() {
			try {
				let logout = await this.authLogout();
				if (logout) {
					this.$nextTick(() => {
						location.reload()
					})
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapActions({
			authLogout: 'auth/logout'
		})
	},
	created() {
		Event.$on("event-load-holidays", async () => {
			await this.catalogue.get_validate_holiday();
		});
	},
}
</script>
<template>
	<div class="wrapper">
		<SessionTimeOut />
		<!-- Navbar -->
		<nav class="main-header navbar navbar-expand navbar-white bg-dark">
			<!-- Left navbar links -->
			<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" data-widget="pushmenu" href="#" role="button">
						<i class="fas fa-bars"></i></a>
				</li>
			</ul>

			<!-- Right navbar links -->
			<ul class="navbar-nav ml-auto">

				<!-- Notificaciones -->
				<li class="nav-item dropdown" v-if="catalogue.validate_holiday_num > 0">
					<a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
						<i class="far fa-bell"></i>
						<span class="badge badge-success navbar-badge">{{ catalogue.validate_holiday_num }}</span>
					</a>

					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right v-holday">
						<router-link :to="{ name: 'validate_holiday', query: { id: holiday.id } }" v-show="holiday.users"
							class="dropdown-item" v-for="(holiday, index) in catalogue.validate_holiday" :key="index">
							<div class="media">
								<img v-if="holiday.users.profile_picture"
									:src="config.apiUrl + holiday.users.profile_picture" alt="User Avatar"
									class="img-size-50 mr-3 img-circle">
								<img v-else :src="require('@/assets/img/profile.png')" alt="User Avatar"
									class="img-size-50 mr-3 img-circle">
								<div class="media-body">
									<h3 class="dropdown-item-title name">
										<span
											v-if="holiday.users.name && holiday.users.name != null && holiday.users.name != 'null'">
											{{ holiday.users.name }}
										</span>
										<span
											v-if="holiday.users.last_name && holiday.users.last_name != null && holiday.users.last_name != 'null'">
											{{ holiday.users.last_name }}
										</span>
									</h3>
									<p class="text-sm">Solicito vacaciones</p>
									<p class="txt-11 mt-1 text-muted"><i class="far fa-calendar mr-1"></i> De {{
										holiday.start_date }} al {{ holiday.end_date }} </p>
								</div>
							</div>

						</router-link>
						<div class="dropdown-divider"></div>
						<router-link :to="{ name: 'validate_holiday' }" class="dropdown-item dropdown-footer"> Ir aprobar
							vacaciones </router-link>
					</div>

				</li>
				<!-- Notifications Dropdown Menu -->
				<li class="nav-item pt-2">
					<span>
						Hola, {{ authUser.name }} 
					</span>
				</li>
				<li class="nav-item dropdown">
					<a class="nav-link" data-toggle="dropdown" href="#">
						<i class="fa fa-user-circle" aria-hidden="true"></i>
					</a>
					<div class="dropdown-menu dropdown-menu dropdown-menu-right">
						<router-link to="/profile" class="dropdown-item text-center">
							Editar cuenta
						</router-link>
						<div class="dropdown-divider"></div>
						<a href="#" @click="postLogout" class="dropdown-item text-center">
							<i class="fa fa-external-link" aria-hidden="true"></i> Cerrar sesión
						</a>
					</div>

				</li>
				<li class="nav-item">
					<a class="nav-link" data-widget="fullscreen" href="#" role="button">
						<i class="fas fa-expand-arrows-alt"></i>
					</a>
				</li>
			</ul>
		</nav>
		<!-- /.navbar -->

		<!-- Main Sidebar Container -->
		<aside class="main-sidebar elevation-4 sidebar-dark-teal">
			<!-- Brand Logo -->
			<router-link :to="{ name: 'app-home' }" class="text-center">
				<div class="div_logo">
					<img :src="require('@/assets/img/logo.png')" alt="logo" class="img-circle elevation-3"
						style="opacity: .8; width: 80%; ">
				</div>
				<!-- <span class="brand-text font-weight-light"> SORIANOARIZA</span> -->
			</router-link>

			<!-- Sidebar -->
			<div class="sidebar">
				<!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
					<div class="image">
						<img :src="`${config.BaseUrl}/assets/img/logo.png`" class="img-circle elevation-2" alt="User Image">
					</div>
					<div class="info">
						<a href="#" class="d-block">{{ authUser.name }} {{ authUser.last_name }}</a>
					</div>
				</div> -->

				<PageMenu></PageMenu>

			</div>
			<!-- /.sidebar -->
		</aside>
		<div class="content-wrapper">
			<div class="col-12">

				<!-- Main content -->
				<section class="content">
					<div class="container-fluid">
						<titlePage />
						<div class="container-fluid">
							<router-view></router-view>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>
<style scoped>
.name {

	width: 200px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.txt-11 {
	font-size: 11px !important;
}

@media only screen and (max-width: 430px) {
	.v-holday {
		left: inherit;
		right: -90px;
	}

}
</style>