<template>
	<div class="col-12 ">
		<div class="card">
			<div class="card-body">
				<form @submit.prevent="triggerSave">
					<div class="row">
						<div class="col-12 text-center">
							<h3>Imagen de perfil</h3>
							<img class="img-fluid rounded" style="width: 200px;" :src="img_perfil" alt="">
							<br>
							<br>
							<input type="file" id="file" class="inputfile" @change="loadPhoto">
							<label for="file"><i class="fas fa-upload"></i> Subir imagen </label>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Nombre </label>
								<input type="text" v-model="form.name" class="form-control">
								<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Apellidos </label>
								<input type="text" v-model="form.last_name" class="form-control">
								<div v-if="errors && errors.last_name" class="text-danger">{{ errors.last_name[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Teléfono </label>
								<input type="mail" v-model="form.phone" class="form-control">
								<div v-if="errors && errors.phone" class="text-danger">{{ errors.phone[0] }}</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label> WhatsApp </label>
								<input type="mail" v-model="form.whathsapp" class="form-control">
								<div v-if="errors && errors.whathsapp" class="text-danger">{{ errors.whathsapp[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label> Email </label>
								<input type="mail" v-model="form.email" class="form-control">
								<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Fecha Ingreso </label><br>
								<date-picker v-model="form.admission_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
									:clearable="false"></date-picker>
								<div v-if="errors && errors.admission_date" class="text-danger">{{
									errors.admission_date[0]
								}}</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Fecha de nacimiento </label>
								<date-picker v-model="form.birthday" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
									:clearable="false"></date-picker>
								<div v-if="errors && errors.birthday" class="text-danger">{{ errors.birthday[0] }}</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label> Perfil </label>
								<select v-model="form.organization_charts_id" @change="validProfile" class="form-control">
									<option value="null"> Sin Perfil </option>
									<option :value="profile.id" v-for="(profile, index) in profiles" :key="index">
										<span v-if="profile.profil">{{ profile.profil.name }}</span>
										<span v-if="profile.area">- {{ profile.area.name }}</span>
									</option>
								</select>
								<div v-if="errors && errors.organization_charts_id" class="text-danger">{{ errors.organization_charts_id[0] }}</div>
							</div>
						</div>
						<div class="col-md-12" v-if="showUsers">
							<div class="form-group">
								<label> Jefe Directo </label>
								<multiselect v-model='form.user' :options='catalogue.users_area' :custom-label='postWithTitle'
                                    placeholder='Buscar usuarios' label='title' track-by='id' :multiple="false"
                                    :hide-selected="true" :loading="catalogue.users_area_load" >
                                    <span slot="noResult">No se encontraron usuario con este nombre.</span>
                                </multiselect>								
								<div v-if="errors && errors.users_area" class="text-danger">{{ errors.users_area[0] }}</div>
							</div>
						</div>

						<div class="col-12">
							<div class="row">
								<div class="col-md-6 text-center">
									<div class="card">
										<div class="card-body">
											<div class="form-group">
												<label> Recordatorio Aniversario </label>
												<input type="checkbox" v-model="form.remember_anniversary"
													class="form-control">
												<hr>
												<p class="fs-6">Al activar esta opción se enviará una notificación por
													correo de felicitación de su aniversario cuando le toque</p>
												<div v-if="errors && errors.remember_anniversary" class="text-danger">{{
													errors.remember_anniversary[0]
												}}
												</div>
											</div>
										</div>
									</div>

								</div>
								<div class="col-md-6 text-center">
									<div class="card">
										<div class="card-body">

											<div class="form-group">
												<label> Recordatorio Cumpleaños </label>
												<input type="checkbox" v-model="form.remember_birthdat"
													class="form-control">
												<hr>
												<p class="fs-6">Al activar esta opción se enviará una notificación por
													correo de felicitación de su cumpleaños cuando le toque</p>
												<div v-if="errors && errors.remember_birthdat" class="text-danger">{{
													errors.remember_birthdat[0]
												}}
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- <div class="col-md-4 text-center">
									<div class="card">
										<div class="card-body">

											<div class="form-group">
												<label> Permiso Vacaciones RH </label>
												<input type="checkbox" v-model="form.approve_holidays_rh"
													class="form-control">
												<div v-if="errors && errors.approve_holidays_rh" class="text-danger">{{
														errors.approve_holidays_rh[0]
												}}
												</div>
											</div>
										</div>
									</div>
								</div> -->
								<div class="col-12">
									<div class="card">
										<div class="card-body text-center" v-if="img_signature">
											<h4>Firma</h4>
											<img class="img-fluid rounded" :src="img_signature" alt="">
										</div>
										<div class="card-body text-center" v-else>
											<h4>Firma</h4>
											<vueSignature ref="signature1" :sigOption="option"></vueSignature>
											<hr>
											<button class="btn btn-sm btn-outline-info" type="button"
												@click="clear">Limpiar</button>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-md-12">
							<button type="submit" class="btn float-right btn-outline-success">
								<i class="fas fa-save"></i> Guardar
							</button>
							<router-link to="/users" class="btn float-left btn-outline-warning">
								<i class="fas fa-undo-alt"></i>
								Regresar
							</router-link>
						</div>
					</div>
				</form>

			</div>
		</div>
	</div>
</template>

<script>
import request from '../../services/request'
import { mapActions } from 'vuex';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import config from '@/services/config';

import Catalogue from '../../services/catalogue';
import vueSignature from "vue-signature"
import Multiselect from 'vue-multiselect'

export default {
	name: "users-table",
	components: {
		DatePicker,
		vueSignature,
		Multiselect
	},
	data() {
		return {
			form: {
				id: '',
				name: '',
				last_name: '',
				img_perfil: '',
				phone: '',
				whathsapp: '',
				organization_charts_id: '',
				email: '',
				birthday: '',
				admission_date: '',
				user_id: '',
                user: '',
				approve_holidays_rh: '',
				remember_anniversary: '',
				remember_birthdat: ''
			},
			showUsers: false,
			area: '',
			profiles: [],
			img_perfil: require('@/assets/img/profile.png'),
			img_signature: '',
			errors: {},
			action: '/admin/user',
			catalogue: new Catalogue(),
			option: {
				penColor: "rgb(0, 0, 0)",
				backgroundColor: "rgba(255,255,255, 0)"
			},
		}
	},
	mounted() {
		this.getProfiles();
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
		}
	},
	methods: {
		clear() {
			var _this = this;
			_this.$refs.signature1.clear();
		},
		async validProfile() {
			let value = this.form.organization_charts_id;
			if (value != 'null') {
				let _endpoint = '/profiles/' + value;
				try {
					let { data } = await request.get(_endpoint)
					if (data.profiles_id != 1) {
						this.showUsers = true;
						this.catalogue.get_users_area(data.areas_id, this.form.id);
					} else {
						this.showUsers = false;
					}
				} catch (error) {
					this.errors = {};
					if (error.response) {
						this.errors = await this.errorNotification(error)
					}
				}
			}
		},
		loadPhoto(event) {
			if (event.target.files[0].type == "image/png" ||
				event.target.files[0].type == "image/jpeg") {
				this.form.img_perfil = event.target.files[0];
				this.img_perfil = URL.createObjectURL(event.target.files[0]);
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PNG , JPGE',
				});
			}
		},
		postWithTitle(option) {
			let name = '';
			if(option.name){
				name += option.name;
			}
			if(option.last_name != null && option.last_name != ""  && option.last_name != "null"){
				name += ' '+option.last_name;
			}
			return name;
		},
		async triggerSave() {

			let signature = "";
			if (this.$refs.signature1) {
                const _empty = await this.$refs.signature1.isEmpty();
                if(!_empty){
                    signature = this.$refs.signature1.save();
                }
            }


			const data_form = new FormData();
			data_form.append('id', this.form.id);
			data_form.append('name', this.form.name);
			data_form.append('last_name', this.form.last_name);
			data_form.append('email', this.form.email);
			data_form.append('phone', this.form.phone);
			data_form.append('organization_charts_id', this.form.organization_charts_id);
			data_form.append('img_perfil', this.form.img_perfil);
			data_form.append('whathsapp', this.form.whathsapp);
			data_form.append('birthday', this.form.birthday);
			data_form.append('admission_date', this.form.admission_date);
			data_form.append('approve_holidays_rh', this.form.approve_holidays_rh);
			data_form.append('remember_anniversary', this.form.remember_anniversary);
			data_form.append('remember_birthdat', this.form.remember_birthdat);
			if(this.form.user){
                data_form.append('user_id', this.form.user.id);
            }else{
                data_form.append('user_id', this.form.user_id);
            }
			if (signature) {
				data_form.append('signature', signature);
			}

			let _endpoint = '/users';
			if (this.form.id != "") {
				data_form.append('_method', 'PUT');
				_endpoint = `/users/${this.form.id}`;
			}

			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					// this.users = data.data;
					this.successNotification(data.message);
					this.$router.push('/users');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					let info_error = await this.errorNotification(error);
					if (info_error == 'Logout') {
						this.$nextTick(() => {
							location.reload()
						})
					} else {
						this.errors = info_error;
					}
				}
			}
		},
		async get_id(id) {
			let _endpoint = `/users/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.form.id = id;
					this.form.name = data.name ? data.name : '';
					this.form.last_name = data.last_name ? data.last_name : '';
					this.form.img_perfil = data.img_perfil ? data.img_perfil : '';
					this.form.phone = data.phone ? data.phone : '';
					this.form.whathsapp = data.whathsapp ? data.whathsapp : '';
					this.form.email = data.email ? data.email : '';
					this.form.organization_charts_id = data.organization_charts_id ? data.organization_charts_id : '';
					this.form.birthday = data.birthday ? data.birthday : '';
					this.form.admission_date = data.admission_date ? data.admission_date : '';
					this.form.user_id = data.user_id ? data.user_id : '';
					this.form.approve_holidays_rh = data.approve_holidays_rh == 1 ? true : false;
					this.form.remember_anniversary = data.remember_anniversary == 1 ? true : false;
					this.form.remember_birthdat = data.remember_birthdat == 1 ? true : false;
					if (data.profile_picture) {
						this.img_perfil = config.apiUrl + data.profile_picture;
					}
					if (data.signature) {
						this.img_signature = config.apiUrl + '/storage/' + data.signature;
					}
                    this.form.user = data.user;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async getProfiles() {
			let service = request;
			let _endpoint = '/catalogue/profiles';
			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					this.profiles = data;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	clearUser() {
		this.form.user = '';
	},
	watch: {
		'form.organization_charts_id': {
			handler: function () {
				this.validProfile();
			},
			deep: true
		}
	},
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}

.fs-6 {
	font-size: 12px;
}
</style>
