<template>
	<modal name="NewContact" v-bind:click-to-close="false" :width="400" :adaptive="true">
		<div class="row pad" v-if="load == false">
			<div class="col-12">
				<h4>{{ title }}</h4>
				<hr>
				<div class="form-group">
					<label for="">Nombre :</label>
					<input type="text" v-model="form.name" class="form-control">
					<div v-if="(errors && errors.name)" class="text-danger">{{ errors.name[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Correo :</label>
					<input type="text" v-model="form.email" class="form-control">
					<div v-if="(errors && errors.email)" class="text-danger">{{ errors.email[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Teléfono :</label>
					<input type="text" v-model="form.phone" class="form-control">
					<div v-if="(errors && errors.phone)" class="text-danger">{{ errors.phone[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">WhatsApp :</label>
					<input type="text" v-model="form.whathsapp" class="form-control">
					<div v-if="(errors && errors.whathsapp)" class="text-danger">{{ errors.whathsapp[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Humor :</label>
					<div class="row">
						<div class="col-4" v-for="(mood, index) in catalogue.contact_mood" :key="index">
							<button :class="[ form.contact_moods_id == mood.id ? 'active' : '' , 'btn btn-sm btn-block btn-outline-info text-center']" @click="form.contact_moods_id = mood.id ">
								<i :class="[mood.icon, 'fa-2x']"> </i><br>
								{{ mood.name }}
							</button>
						</div>
					</div>
					<div v-if="(errors && errors.contact_moods_id)" class="text-danger">{{ errors.contact_moods_id[0] }}</div>

				</div>
			</div>
			<div class="col-12 mt-3">
				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-warning" @click="reset">
							<i class="fas fa-undo-alt"></i>
							Cerrar
						</button>
					</div>
					<div class="col-6 text-right">
						<button class="btn btn-outline-success" @click="save">
							<i class="fas fa-save"></i>
							Guardar
						</button>

					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<div class="load">Loading...</div>
			</div>
		</div>
	</modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'
import Catalogue from '../../services/catalogue';

export default {
	name: "new-customers",
	data() {
		return {
			title: 'Nuevo contacto',
			form: {
				id: '',
				name: '',
				phone: '',
				whathsapp: '',
				email: '',
				contact_moods_id: '',
				customers_id: '',
			},
			errors: {},
			load: false,
			catalogue: new Catalogue(),
		}
	},
	created(){
		Event.$on("event-new-contact", id => {
			this.title = 'Nuevo contacto';
			if(id){
				this.title = 'Editar contacto';
				this.get_id(id);	
			}
        });
	},
	mounted() {
		this.catalogue.get_contact_mood();
		let id = this.$route.params.id;
		if (id) {
			this.form.customers_id = id;
		}
	},
	methods: {
		async save() {
			this.load = true;
			let _endpoint = '';
			if(this.form.id){
				_endpoint = `/contacts/${this.form.id}`;
				this.form._method = 'PUT';
			}else{
				_endpoint = `/contacts`;
			}
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
					Event.$emit('event-load-contacts', '');
				}
			} catch (error) {
				this.load = false;
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error);
				}
			}
		},
		async get_id(id) {
			this.form.id = id;
			let _endpoint = `/contacts/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					let form = this.form;
					Object.keys(form).forEach(key => {
						this.form[key] = data[key];
					});
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		reset() {
			this.load = false;
			this.errors = {};
			this.form.id = '';
			this.form.name = '';
			this.form.phone = '';
			this.form.whathsapp = '';
			this.form.email = '';
			this.form.contact_moods_id = '';
			this.form._method = 'POST';
			this.$modal.hide('NewContact');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style>
.pad {
	padding: 10px !important;
}
</style>
