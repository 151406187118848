<template>
    <div class="row">
        <div class="col-md-8">
            <div class="card" style="height: 95%;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row" v-if="load">
                                <td class="text-center p-4 col-12">
                                    <i class="fas fa-spinner fa-pulse fa-5x"></i>
                                    <h6 class="mt-3">
                                        Cargando
                                    </h6>
                                </td>
                            </div>
                            <div class="row" v-else>
                                <div class="col-12">
                                    <h3 class="text-center">Última solicitud de vacaciones</h3>
                                </div>
                                <div class="col-12">
                                    <div class="row" v-if="holiday">
                                        <div class="col-12 mt-2">
                                            <H6 class="text-center">
                                                {{ holiday.start_date | dateHuman }}
                                                -
                                                {{ holiday.end_date | dateHuman }}
                                            </H6>
                                        </div>
                                        <div class="col-12 mt-2">
                                            <TimeLineHoliday :holiday="holiday" />
                                        </div>
                                    </div>
                                    <div class="col-12 text-center mt-5" v-else>
                                        <h3>Sin solicitud realizada</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <div class="card">
                <div class="card-body">
                    <div class="row" v-if="load">
                        <td class="text-center p-4 col-12">
                            <i class="fas fa-spinner fa-pulse fa-5x"></i>
                            <h6 class="mt-3">
                                Cargando
                            </h6>
                        </td>
                    </div>
                    <div class="row" v-else>
                        <div class="col-12">
                            <h3>Dias restantes del periodo</h3>
                            <p>
                                {{ user._star | dateHuman }}
                                -
                                {{ user._end | dateHuman }}
                            </p>
                            <h4>
                                {{ (user.daysHollidays + holidayExtrasSum) - user.daysUsedHollidays }} Día<span
                                    v-if="((user.daysHollidays + holidayExtrasSum) - user.daysUsedHollidays) > 2">s</span>
                            </h4>
                            <!-- Extras -->
                            <hr v-if="holidayExtrasSaSum > 0">
                            <p class="text-info text-sm mt-n2" v-if="holidayExtrasSaSum > 0">
                                <i class="fa-solid fa-calendar-plus fa-fade mr-3"></i>
                                {{ holidayExtrasSaSum }} Día<span v-if="holidayExtrasSaSum > 2">s</span> extra por S.A.
                            </p>

                            <hr v-if="holidayExtrasPeriodSum > 0">
                            <p class="text-warning text-sm mt-n2" v-if="holidayExtrasPeriodSum">
                                <i class="fa-solid fa-circle-info fa-fade mr-3"></i>
                                {{ holidayExtrasPeriodSum }} Día<span v-if="holidayExtrasPeriodSum > 2">s</span>
                                extras
                                <br> del periodo anterior hasta {{
                                    holidayExtrasPeriod.expiration | dateHuman }}
                            </p>

                            <button
                                v-if="((user.daysHollidays + holidayExtrasSum) - user.daysUsedHollidays) > 0 && processHoliday(holiday)"
                                @click="createHoliday()" class="btn mt-3  bg-success btn-success btn-block">
                                <i class="fa fa-plus"></i> Solicitar
                            </button>
                            <button
                                v-if="((user.daysHollidays + holidayExtrasSum) - user.daysUsedHollidays) > 0 && processCancelHoliday(holiday)"
                                @click="cancelHoliday(holiday.id)" class="btn mt-3  bg-danger btn-danger btn-block">

                                <i class="fa-solid fa-calendar-xmark mr-2"></i> Cancelar solicitud
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewHolidays />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import NewHolidays from '../../component/holidays/NewHolidaysComponent.vue'
import TimeLineHoliday from '@/component/holidays/TimeLineComponent.vue';

import request from '../../services/request'

export default {
    name: "holidays-table",
    components: {
        NewHolidays,
        TimeLineHoliday
    },
    data() {
        return {
            holidayExtrasSa: '',
            holidayExtrasSaSum: 0,
            holidayExtrasPeriod: '',
            holidayExtrasPeriodSum: 0,
            holidayExtrasSum: 0,
            holiday: '',
            user: {},
            load: false
        }
    },
    mounted() {
        this.gatHolidayLast()
    },
    created() {
        Event.$on("event-load-holidays", () => {
            this.gatHolidayLast();
        });
    },
    methods: {
        createHoliday() {
            this.$modal.show('new_holiday');
        },
        async cancelHoliday(id) {
            this.$swal({
                title: 'Cancelar las vacaciones',
                text: 'Se eliminar la solicitud de los registros',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, cancelar',
                cancelButtonText: 'No, regresar',
                customClass: {
                    cancelButton: 'order-1 btn btn-outline-danger',
                    confirmButton: 'order-2 btn btn-outline-success',
                },
            }).then(async (result) => {
                if (result.isConfirmed) {

                    let vm = this;
                    this.load = true;
                    let _endpoint = '/holidays/' + id;
                    try {
                        let { data } = await request.destroy(_endpoint)
                        if (data) {
                            this.successNotification(data.message);
                            this.gatHolidayLast();
                            vm.load = false;
                        }
                    } catch (error) {
                        vm.load = false;
                        console.log(error);
                    }
                }
            });

        },
        async gatHolidayLast() {
            let vm = this;
            this.load = true;
            let service = request;
            let _endpoint = '/holidays_last';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.holiday = data.holiday;
                    this.user = data.user;
                    this.holidayExtrasSa = data.holidayExtrasSa;
                    this.holidayExtrasPeriod = data.holidayExtrasPeriod;
                    this.holidayExtrasSum = 0;
                    this.getExtraHolidays();
                    this.getExtrasPeriodSum();
                    vm.load = false;
                }
            } catch (error) {
                vm.load = false;
                console.log(error);
            }
        },
        async getExtraHolidays() {
            let vm = this;
            let suma = 0;
            let holidayExtrasSa = this.holidayExtrasSa;
            if (holidayExtrasSa) {

                holidayExtrasSa.forEach(function (valor) {
                    let _extra = valor.days;
                    if (valor.used) {
                        valor.used.forEach(function (use) {
                            vm.holidayExtrasSum += use.days;
                            _extra -= use.days;

                        });
                    }
                    suma += _extra;
                });
            }
            this.holidayExtrasSaSum = suma;
        },
        async getExtrasPeriodSum() {
            let vm = this;
            let suma = 0;
            let valor = this.holidayExtrasPeriod;
            if (valor) {
                let _extra = valor.days;
                if (valor.used) {
                    valor.used.forEach(function (use) {
                        vm.holidayExtrasSum += use.days;
                        _extra -= use.days;
                    });
                }
                suma += _extra;
            }
            this.holidayExtrasPeriodSum = suma;
        },
        InfoStatus(status, statu) {
            let status_info = status.history.map(x => x.holidays_statuses_id);
            let result = status_info.includes(statu);
            return result ? result : status.cancel;
        },
        InfoCancel(status, statu) {
            let status_info = status.history.map(x => x.holidays_statuses_id);
            let result = status_info.includes(statu);
            return result ? result : status.cancel;

        },
        processCancelHoliday(holiday = null) {
            console.log(holiday)
            if (holiday) {
                let validation = false;

                if (holiday.holidays_statuses_id == 1) {
                    validation = true;
                }
                return validation
            } else {
                return false;
            }
        },
        processHoliday(holiday) {
            if (holiday) {
                let status_info = holiday.history.map(x => x.holidays_statuses_id);
                let validation = false;
                let result = status_info.includes(100);
                if (result) {
                    validation = true;
                }

                if (status_info.length == 3) {
                    validation = true;
                }
                return validation;
            } else {
                return true;
            }
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>

