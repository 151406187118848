import Vue from 'vue';

import store from './store';
import router from './router';
import App from './App';

import './assets/custom.css';


import 'core-js/stable'; 
import 'regenerator-runtime/runtime';


import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);

import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 6000
  }
}
Vue.use(Snotify, options)


import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import darkUnica from "highcharts/themes/grid-light";

darkUnica(Highcharts);
Vue.use(HighchartsVue);

import vmodal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'
Vue.use(vmodal)

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import VueUploadComponent from 'vue-upload-component'
Vue.component('file-upload', VueUploadComponent)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// setup fake backend
import { authHeader } from './helpers';
authHeader();

// ACL permisos usuario
require('./helpers/acl');

window.Event = new Vue();

import money from 'v-money'
Vue.use(money, {
  precision: 2,
  decimal: '.',
  thousands: ',',
  prefix: '$ ',
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
