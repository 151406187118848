<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Lista de usuarios participantes</h3>
			</div>
			<div class="card-body">
				<NewBonuses />
				<div class="col-12 text-center" v-if="proyect.bonuses.id == ''">
					<button class="btn btn-outline-success" @click="showModal('')">
						Generar Bonos
					</button>
				</div>
				<div class="col-12 table-responsive" v-else>
					<div class="row">
						<div class="col-6">
							<h3> $ {{ proyect.bonuses.amount | Money }}</h3>
						</div>
						<div class="col-6 text-right">
							<button class="btn btn-outline-info btn-sm" @click="showModal(proyect.bonuses.id)">
								<i class="fa fa-edit"></i> Editar
							</button>
						</div>
						<div class="col-12">
							<hr>
						</div>
					</div>
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Pusto</th>
								<th>Cantidad</th>
								<th>Pagado</th>
								<th>Fecha de Pago</th>
							</tr>
						</thead>
						<tbody>
							<tr v-show="catalogue.users_project.users.length > 0" v-for="(user, index) in catalogue.users_project.users" :key="index">
								<td>{{ user.name }} {{ user.last_name }}</td>
								<td>
									<span v-if="user.profile">
										<span v-if="user.profile.area">{{ user.profile.area.name }} - </span>
										<span v-if="user.profile">{{ user.profile.profil.name }} </span>
									</span>
								</td>
								<td>
									<money v-model="user.pivot.bonus_pay" class="form-control text-right"></money> 
								</td>
								<td>
									<input type="checkbox" class="form-control" v-model="user.pivot.succes_pay" />
								</td>
								<td>{{ user.pivot.date_pay | dateHuman }}</td>
							</tr>
							<tr  v-show="catalogue.users_project.users.length == 0">
								<td colspan="5" class="text-center">
									Sin usuarios participantes
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td colspan="2">
									<h4>Total :</h4>
								</td>
								<td>$ {{ sum_bonus() | Money }}</td>
								<td colspan="2"></td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div class="col-12">
					<hr>
					<router-link to="/projects" class="btn float-left btn-outline-warning">
						<i class="fas fa-undo-alt"></i>
						Regresar
					</router-link>
					<button class="btn float-right btn-outline-success" @click="save()">
						<i class="fas fa-save"></i>
						Guardar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import request from '../../services/request'
import { mapActions, mapMutations } from 'vuex';
import NewBonuses from '../../component/bonuses/NewBonusesComponent.vue';
import Catalogue from '../../services/catalogue';

import '../../helpers/filters'
export default {
	name: "bonuses-table",
	components: {
		NewBonuses
	},
	data() {
		return {
			proyect: {
				id: '',
				name: '',
				bonuses: {
					id: '',
					amount: '',
				},
			},
			proyect_id: '',
			catalogue: new Catalogue(),
		}
	},
	mounted() {
		let id = this.$route.params.id;
		if (id) {
			this.proyect_id = id;
			this.get_bonus_id();
		}
		this.setHeaderTitle('Bonos');
		this.catalogue.get_users_project(id);
	},
	created() {
		Event.$on("event-load-bonuses", () => {
			this.get_bonus_id();
			this.catalogue.get_users_project(this.proyect_id);
		});
	},
	methods: {
		async save(){
			let form = {
				id : this.proyect_id,
				users : this.catalogue.users_project.users
			};

			let _endpoint = `/bonuses/users`;

			try {
				let { data } = await request.post(_endpoint, form)
				if (data) {
					this.successNotification(data.message);
					this.$router.push('/projects');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errorNotification(error);					
				}
			}

		},	
		showModal(id) {
			Event.$emit('event-new-bonuses', id);
			this.$modal.show('NewBonuses');
		},
		sum_bonus(){
			let suma = 0;
			let mount_proyect = this.proyect.bonuses.amount;
			let users = this.catalogue.users_project.users;
			users.forEach(value => {
				suma += value.pivot.bonus_pay
			});
			return mount_proyect - suma;
		},
		async get_bonus_id() {
			try {
				let service = request;
				let _endpoint = '/projects/' + this.proyect_id;
				let { data } = await service.get(_endpoint)
				if (data) {
					this.proyect = data;
				}
			} catch (error) {
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async get_users_proyect() {
			try {
				let service = request;
				let _endpoint = '/projects/' + this.proyect_id;
				let { data } = await service.get(_endpoint)
				if (data) {
					this.proyect = data;
				}
			} catch (error) {
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		
		...mapActions({
			successNotification: 'alert/success',
			errorNotification: 'alert/error',
		}),
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		})
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
