<template>
	<div class="col-12">
		<div class="row">
			<div class="col-12" v-can="'users'">
				<ChartOrganizationComponent />
				<hr>
			</div>
			
			<div class="col-12" v-if="authUser.organization_charts_id != 1">
				<div class="row">
					<div class="col-md-12">
						<LastHolodays />
					</div>
				</div>
			</div>

			<!-- Usuarios -->
			<div class="col-md-4 col-lg-4 col-sm-6 col-12" v-can="'users'">
				<router-link to="/users" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Usuarios</span>
							<span class="info-box-number">{{ users }}</span>
						</div>
						<span class="info-box-icon bg-success rounded-circle"><i class="far fa-user"></i></span>
					</div>
				</router-link>
			</div>

			<!-- Clientes -->
			<div class="col-md-4 col-lg-4 col-sm-6 col-12" v-can="'customers'">
				<router-link to="/customers" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Clientes</span>
							<span class="info-box-number">{{ customers }}</span>
						</div>
						<span class="info-box-icon bg-success rounded-circle"><i class="fas fa-building"></i></span>
					</div>
				</router-link>
			</div>

			<!-- Proyectos -->
			<div class="col-md-4 col-lg-4 col-sm-6 col-12" v-can="'projects'">
				<router-link to="/projects" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Proyectos</span>
							<span class="info-box-number">{{ projects }}</span>
						</div>
						<span class="info-box-icon bg-success rounded-circle"><i class="fas fa-project-diagram"></i></span>
					</div>
				</router-link>
			</div>

			<!-- Organigrama -->
			<div class="col-md-4 col-lg-4 col-sm-6 col-12" v-can="'organization'">
				<router-link to="/organization" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Organigrama</span>
							<span class="info-box-number">Ver más</span>
						</div>
						<span class="info-box-icon bg-success rounded-circle"><i class="fas fa-network-wired"></i></span>
					</div>
				</router-link>
			</div>

			<!-- calendar -->
			<div class="col-md-4 col-lg-4 col-sm-6 col-12" v-can="'calendar'">
				<router-link to="/calendar" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Calendario</span>
							<span class="info-box-number">Ver más</span>
						</div>
						<span class="info-box-icon bg-success rounded-circle"><i class="fas fa-calendar"></i></span>
					</div>
				</router-link>
			</div>

			<!-- calendar -->
			<div class="col-md-4 col-lg-4 col-sm-6 col-12" v-can="'permissions'">
				<router-link to="/permissions" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Permisos</span>
							<span class="info-box-number">Ver más</span>
						</div>
						<span class="info-box-icon bg-success rounded-circle"><i class="fas fa-lock"></i></span>
					</div>
				</router-link>
			</div>

			<!-- calendar -->
			<div class="col-md-4 col-lg-4 col-sm-6 col-12" v-can="'holidays_config'">
				<router-link to="/holidays_config" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Configurar Vacaciones</span>
							<span class="info-box-number">Ver más</span>
						</div>
						<span class="info-box-icon bg-success rounded-circle"><i class="fas fa-sliders-h"></i></span>
					</div>
				</router-link>
			</div>

			<!-- holidays -->
			<div class="col-md-4 col-lg-4 col-sm-6 col-12" v-can="'holidays'">
				<router-link to="/holidays" class="text-dark">
					<div class="info-box shadow-sm">
						<div class="info-box-content">
							<span class="info-box-text">Vacaciones</span>
							<span class="info-box-number">Ver más</span>
						</div>
						<span class="info-box-icon bg-success rounded-circle"><i class="fas fa-plane"></i></span>
					</div>
				</router-link>
			</div>

		</div>
	</div>
</template>

<script>
import ChartOrganizationComponent from '../component/organization/ChartOrganizationComponent.vue';
import LastHolodays from '../component/holidays/LastHolidaysComponent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import request from '../services/request'

export default {
	components: {
		ChartOrganizationComponent,
		LastHolodays
	},
	data() {
		return {
			users: 0,
			customers: 0,
			projects: 0,
		}
	},
	methods: {
		handler() {
			var args = arguments;
			for (var arg of args) {
				if (arg instanceof Function) {
					arg();
				}
			}
		},
		async fecthAssements(type) {
			let service = request;
			let _endpoint;
			if (type == 'users') {
				_endpoint = '/users';
			}
			if (type == 'customers') {
				_endpoint = '/customers';
			}
			if (type == 'projects') {
				_endpoint = '/projects';
			}

			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					if (type == 'users') {
						this.users = data.total;
					}
					if (type == 'customers') {
						this.customers = data.total;
					}
					if (type == 'projects') {
						this.projects = data.total;
					}

				}
			} catch (error) {
				console.log(error);
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			errorNotification: 'alert/error',
		})
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	mounted() {
		this.fecthAssements('users');
		this.fecthAssements('customers');
		this.fecthAssements('projects');
		this.setHeaderTitle('Panel principal');
	}
};
</script>
