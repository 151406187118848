<script>
export default {
    props: {
        holiday: {
            default: {},
        }
    },
    methods: {
        InfoStatus(status, statu) {
            let status_info = status.history.map(x => x.holidays_statuses_id);
            let result = status_info.includes(statu);
            return result ? result : status.cancel;
        },
        InfoCancel(status, statu) {
            let status_info = status.history.map(x => x.holidays_statuses_id);
            let result = status_info.includes(statu);
            return result ? result : status.cancel;

        },
    }
}
</script>
<template>
    <ul class="timeline div_line" id="timeline">
        <li :class="[InfoStatus(holiday, 1) ? 'ready' : '', 'li']">
            <div class="timestamp">
                <span class="date" v-if="holiday.history[0]">
                    {{ holiday.history[0].created_at | dateHuman }}
                </span>
            </div>
            <div class="status">
                <h6 class="txt_status">
                    Solicitud
                    Usuario
                </h6>
            </div>
        </li>
        <li :class="[InfoStatus(holiday, 2) ? 'ready' : '', InfoCancel(holiday, 100) ? 'cancel' : '', 'li']">
            <div class="timestamp">
                <span class="date" v-if="holiday.history[1]">
                    {{ holiday.history[1].created_at | dateHuman }}
                </span>
                <span class="date" v-else>
                    Sin Fecha
                </span>
            </div>
            <div class="status">
                
                <h6 class="txt_status">
                    Jefe Directo
                </h6>
                <div v-if="holiday.history[1]" class=" btn btn-sm btn-link tooltip-container">
                    <span class="tooltip-text">
                        <span v-if="holiday.history[1].comment">
                            {{ holiday.history[1].comment }}
                        </span>
                        <span v-else>
                            Sin mensaje
                        </span>
                    </span>
                    <i class="fa-solid fa-message"></i>
                </div>
            </div>
        </li>
        <li v-if="holiday.users.profile.area.holidays.area_manager == 1"
            :class="[InfoStatus(holiday, 3) ? 'ready' : '', InfoCancel(holiday, 100) ? 'cancel' : '', 'li']">
            <div class="timestamp">
                <span class="date" v-if="holiday.history[2]">
                    {{ holiday.history[2].created_at | dateHuman }}
                </span>
                <span class="date" v-else>
                    Sin Fecha
                </span>
            </div>
            <div class="status">
                <h6 class="txt_status">
                    Aprobación Gerente
                </h6>
                <div v-if="holiday.history[2]" class=" btn btn-sm btn-link tooltip-container">
                    <span class="tooltip-text">
                        <span v-if="holiday.history[2].comment">
                            {{ holiday.history[2].comment }}
                        </span>
                        <span v-else>
                            Sin mensaje
                        </span>
                    </span>
                    <i class="fa-solid fa-message"></i>

                </div>
            </div>
        </li>
        <li v-if="holiday.users.profile.area.holidays.area_manager == 0"
            :class="[InfoStatus(holiday, 4) ? 'ready' : '', InfoCancel(holiday, 100) ? 'cancel' : '', 'li']">
            <div class="timestamp">
                <span class="date" v-if="holiday.history[2]">
                    {{ holiday.history[2].created_at | dateHuman }}
                </span>
                <span class="date" v-else>
                    Sin Fecha
                </span>
            </div>
            <div class="status">
                <h6 class="txt_status">
                    Aprobación RH
                </h6>
                <div v-if="holiday.history[2]" class=" btn btn-sm btn-link tooltip-container">
                    <span class="tooltip-text">
                        <span v-if="holiday.history[2].comment">
                            {{ holiday.history[2].comment }}
                        </span>
                        <span v-else>
                            Sin mensaje
                        </span>
                    </span>
                    <i class="fa-solid fa-message"></i>

                </div>
            </div>

        </li>
        <li v-else :class="[InfoStatus(holiday, 4) ? 'ready' : '', InfoCancel(holiday, 100) ? 'cancel' : '', 'li']">
            <div class="timestamp">
                <span class="date" v-if="holiday.history[4]">
                    {{ holiday.history[4].created_at | dateHuman }}
                </span>
                <span class="date" v-else>
                    Sin Fecha
                </span>
            </div>
            <div class="status">
                <h6 class="txt_status">
                    Aprobación RH
                </h6>
                <div v-if="holiday.history[3]" class=" btn btn-sm btn-link tooltip-container">
                    <span class="tooltip-text">

                        <span v-if="holiday.history[3].comment">
                            {{ holiday.history[3].comment }}
                        </span>
                        <span v-else>
                            Sin mensaje
                        </span>
                    </span>
                    <i class="fa-solid fa-message"></i>
                </div>
            </div>

        </li> 
    </ul>
</template>
<style scoped>
.timeline {
    margin: 30px 10px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.li {
    width: 33%;
    transition: all 200ms ease-in;
}

.timestamp {
    margin-bottom: 20px;
    padding: 0px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
}

.status {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    border-top: 2px solid #D6DCE0;
    position: relative;
    transition: all 200ms ease-in;
}

.status h4 {
    font-weight: 600;
}

.status:before {}



.status:before {
    content: "";
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 25px;
    border: 1px solid #ddd;
    position: absolute;
    top: -15px !important;
    left: 45% !important;
    transition: all 200ms ease-in;
}

.li.ready .status:before {
    padding: 6px;
    font-size: 15px;
    color: #fff;
    font-family: "fontawesome";
    content: '\f00c';
}

.li.cancel .status:before {
    padding: 2px 9px;
    font-size: 17px;
    color: #fff;
    font-family: "fontawesome";
    content: '\f00d';
}

.li.complete .status {
    border-top: 2px solid #219f8c;
}

.li.complete .status:before {
    background-color: #219f8c;
    border: none;
    transition: all 200ms ease-in;
}

.li.complete .status h4 {
    color: #66DC71;
}

/*  */
.li.cancel .status h4 {
    border-top: 2px solid #ff2121;
}

.li.cancel .status:before {
    background-color: #ff2121;
    border: none;
    transition: all 200ms ease-in;
}

.li.cancel .status h4 {
    color: #ff2121;
}

.li.cancel .status {
    border-top: 2px solid #ff2121;
}



.li.ready .status {
    border-top: 2px solid #219f8c;
}

.li.ready .status:before {
    background-color: #219f8c;
    border: none;
    transition: all 200ms ease-in;
}

.li.ready .status h4 {
    color: #66DC71;
}


@media (min-device-width: 320px) and (max-device-width: 700px) {
    .timeline {
        list-style-type: none;
        display: block;
    }

    .li {
        width: 100%;
        display: flow-root;
        transition: all 200ms ease-in;
        width: inherit;
    }

    .timestamp {
        width: 80px;
    }

    .status:before {
        left: -8%;
        top: 30%;
        transition: all 200ms ease-in;
    }

    .status{
        margin-bottom: 20px;
    }
}


.txt_status {
    margin-top: 25px;
    font-size: 12px;
    min-height: 30px;
}

.div_line {
    /* width: 200px;
     padding-left: 200px;
     overflow: auto; */
    padding: 0px;
    position: initial;
    width: auto;
}

.timeline::before {
    background-color: transparent;
}

i.fa-solid.fa-message {
    padding: 3px;
}

.btn-sm{
    padding: 3px;
    font-size: 10px !important;
}
</style>
