<template>
	<modal name="ModalCustomer" v-bind:click-to-close="false" :width="400" :height="230" :adaptive="true">
		<div class="row pad" v-if="load == false">
			<div class="col-12">
				<h4>Nuevo cliente</h4>
                <hr>
				<label for="">Alias</label>
                <input type="text" v-model="form.alias" class="form-control">
                <div v-if="(errors && errors.alias)" class="text-danger">{{ errors.alias[0] }}</div>
			</div>
            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-outline-warning" @click="reset">
                            <i class="fas fa-undo-alt"></i>
                            Cerrar
                        </button>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-outline-success" @click="save">
                            <i class="fas fa-save"></i>
                            Guardar
                        </button>

                    </div>
                </div>
            </div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<div class="load">Loading...</div>
			</div>
		</div>
	</modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'

export default {
	name: "new-customers",
	data() {
		return {
            form:{
                alias: '',
            },
            errors: {},
            load: false,
		}
	},
	mounted() {
	},
	methods: {
		async save() {
			this.load = true;
            let _endpoint = `/customers`;
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
                    this.$router.push({ path: '/customers/form/'+data.customer.id })
				}
			} catch (error) {
                this.load = false;
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error);
				}
			}
		},
		reset(){
				this.load = false;
				this.errors = {};
                this.form.alias = '';
				this.$modal.hide('ModalCustomer');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style>
.pad {
	padding: 10px !important;
}
</style>
