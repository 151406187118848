<template>
	<div class="row bg align-items-center justify-content-center m-0">
		<div class="w-75">
			<div class="row">
				<div class="col-12 text-center">
					<img :src="require('@/assets/img/logo.png')" class="mt-5 mb-5" alt="Logo" width="150px;"><br>
					<h2 style="color: #20a288;" class="login-box-msg mt-n4">BIENVENIDO</h2>
					<p class="mt-n2">Inicie sesión en su cuenta completando este formulario:</p>
				</div>
				<div class="col-12 text-center mb-4">
					<form @submit.prevent="triggerRegister" class="mt-3">
						<div class="row justify-content-center">

							<div class="col-12 col-md-10">
								<div class="input-group justify-content-center">
									<input type="text" v-model="form.name" class="form-control" placeholder="Nombres"
										:class="{ 'is-invalid': submitted && !form.name }" />
								</div>
								<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
							</div>
							<div class="col-12 col-md-10 mt-3">
								<div class="input-group justify-content-center">
									<input type="text" v-model="form.last_name" class="form-control" placeholder="Apellidos"
										:class="{ 'is-invalid': submitted && !form.last_name }" />
								</div>
								<div v-if="errors && errors.last_name" class="text-danger">{{ errors.last_name[0] }}</div>
							</div>
							<div class="col-12 col-md-10 mt-3">
								<div class="input-group justify-content-center">
									<input type="text" v-model="form.email" class="form-control" placeholder="Correo"
										:class="{ 'is-invalid': submitted && !form.email }" />
								</div>
								<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
							</div>
							<div class="col-12 col-md-10 mt-3">
								<div class="input-group justify-content-center">
									<select class="form-control" v-model="form.area_id">
										<option value="">Área de trabajo</option>
										<option :value="area.id" v-for="(area, index) in catalogue.areas" :key="index">
											{{ area.name }}
										</option>
									</select>									
								</div>
								<div v-if="errors && errors.area_id" class="text-danger">{{ errors.area_id[0] }}</div>							
								<div class="row">
									<div class="col-12 mt-3 mb-3 text-center">
										<h5>
											<router-link to="/login" class="text-login">
												Iniciar sesión
											</router-link>
										</h5>
									</div>
									<div class="col-12 text-center mt-3">
										<button class="btn btn-success" style="width: 200px;" :disabled="submitted">
											<span v-if="submitted">
												<i class="fa-solid fa-spinner fa-spin-pulse mr-1"></i> Enviando
											</span>
											<span v-else>
												Registrarse
											</span>
										</button>
									</div>
								</div>
							</div>							
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import Catalogue from '@/services/catalogue';

export default {
	
	data() {
		return {
			form: {
				name: '',
				last_name: '',
				email: '',
				area_id: '',
			},
			catalogue: new Catalogue(),
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerRegister() {
			this.submitted = true;
			try {
				let register = await this.registerAction(this.form)
				if (register) {
					this.submitted = false;
					this.successNotification(register.message);
					this.$router.push('/login')
				}
			} catch (error) {
				this.submitted = false;
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			registerAction: 'auth/register',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	mounted() {
		this,this.catalogue.get_areas();
		this,this.catalogue.get_profiles();
	}
};
</script>
<style scoped>
input, select, .text-danger  {
	max-width: 415px;
	margin: 0 auto;
}

a {
	color: #20a288;
}
</style>