<template>
    <modal name="modalPDF" :width="630" height="'auto'" :adaptive="true">
        <div class="row pad" v-if="load == false">
            <div class="col-12 text-center">
                <h4 class="mt-2 mb-2">Formato de vacaciones</h4>
                <button class="btn bt-sm btn-outline-danger float-right mt-n5" @click="reset">
                    <i class="fas fa-times"></i>
                </button>
                <hr>
            </div>
            <div class="col-md-12 text-center">
                <div class="row">
                    <div class="col-12">
                        <div class="bk_iframe" v-if="loadIframe">
                            <i class="text-white fas fa-circle-notch fa-spin fa-5x" style="margin-top: 30%;"></i>
                        </div>
                        <iframe :src="urlPdf" @load="iframeLoadHelper"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <hr>
            </div>


            <div class="col-12  mb-3" v-if="authUser && holiday.id">
                <div class="text-center" v-if="send">
                    <i class="fa-solid fa-spinner fa-spin-pulse fa-2x"></i>
                    <h5 class="mt-2">
                        Guardando
                    </h5>
                </div>
                <div v-else>
                    <div v-if="(holiday.holidays_statuses_id == 1) && (authUser.id == holiday.users.user_id)">
                        <label>Comentario para el solicitante:</label>
                        <textarea name="comment" v-model="comment" class="form-control" cols="30" rows="2"></textarea>
                        <hr>
                        <button class="btn btn-outline-danger float-left pl-4 pr-4" @click="cancelStatus(holiday.id)">
                            <i class="fas fa-times "> </i> Rechazar
                        </button>
                        <button class="btn btn-outline-success float-right pl-4 pr-4"
                            @click="changeStatus(holiday.id, 2)">
                            <i class="fas fa-check "> </i> Aceptar
                        </button>

                    </div>

                    <!-- GERENTE AREA-->
                    <div
                        v-if="holiday.users.profile.area.holidays.area_manager == 1 && holiday.holidays_statuses_id == 2 && authUser.profile.areas_id == holiday.users.profile.areas_id && (authUser.profile.profiles_id == 2 || authUser.profile.profiles_id == 3)">
                        <label>Comentario para el solicitante:</label>
                        <textarea name="comment" v-model="comment" class="form-control  mb-3" cols="30"
                            rows="2"></textarea>
                        <button class="btn btn-outline-danger float-left pl-4 pr-4" @click="cancelStatus(holiday.id)">
                            <i class="fas fa-times "> </i> Rechazar
                        </button>
                        <button class="btn btn-outline-success float-right pl-4 pr-4"
                            @click="changeStatus(holiday.id, 3)">
                            <i class="fas fa-check "> </i> Aceptar
                        </button>

                    </div>

                    <!-- RH -->
                    <div
                        v-if="(holiday.holidays_statuses_id == 3 && (authUser.approve_holidays_rh == 1 || authUser.profile.areas_id == 1 || authUser.profile.areas_id == 2)) || (holiday.users.profile.area.holidays.area_manager == 0 && holiday.holidays_statuses_id == 2 && (authUser.approve_holidays_rh == 1 || authUser.profile.areas_id == 1 || authUser.profile.areas_id == 2))">
                        <label>Comentario para el solicitante:</label>
                        <textarea name="comment" v-model="comment" class="form-control mb-3" cols="30"
                            rows="2"></textarea>
                        <button class="btn btn-outline-danger float-left pl-4 pr-4" @click="cancelStatus(holiday.id)">
                            <i class="fas fa-times "> </i> Rechazar
                        </button>
                        <button class="btn btn-outline-success float-right pl-4 pr-4"
                            @click="changeStatus(holiday.id, 4)">
                            <i class="fas fa-check "> </i> Aceptar
                        </button>

                    </div>

                </div>
            </div>

        </div>
        <div class="row" v-else>
            <div class="col-md-12">
                <div class="load">Loading...</div>
            </div>
        </div>
    </modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import config from '@/services/config';
import request from '../../services/request'

export default {
    name: "modal-validate-holidays",
    data() {
        return {
            config: config,
            cargador: false,
            archivo_upload: '',
            archivo: '',
            load: false,
            loadIframe: false,
            urlPdf: '',
            reporte: {},
            holiday: {},
            comment: '',
            send: false,
        }
    },
    created() {
        Event.$on("event-load-pdf-holiday", (holiday) => {
            this.$modal.show('modalPDF');
            this.loadModal(holiday);
        });
    },
    methods: {

        iframeLoadHelper() {
            this.loadIframe = true;
            if (this.urlPdf) {
                return this.iframeLoaded();
            } else {
                return;
            }
        },
        // The real load method
        iframeLoaded() {
            console.log('iframe loaded');
            this.loadIframe = false;
        },

        loadModal(holiday) {
            this.load = true;
            this.holiday = holiday;
            this.comment = '';
            this.getId(holiday.id);
        },
        async getId(id) {
            this.urlPdf = `${config.apiUrl}/pdf/${id}.pdf#view=FitV`;
            this.load = false;
        },
        async changeStatus(id, status) {
            let comment = this.comment;
            this.$swal({
                // title: 'Aceptar las vacaciones',
                text: 'Deseas continuar con la aprobación de estas vacaciones',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, aprobar',
                cancelButtonText: 'No, regresar',
                customClass: {
                    cancelButton: 'order-1 btn btn-outline-danger',
                    confirmButton: 'order-2 btn btn-outline-success',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: id,
                        status: status,
                        comment: comment
                    };
                    this.callChangeStatus(form);
                }
            });
        },
        async callChangeStatus(form) {
            let vm = this;
            vm.send = true;
            try {
                let _endpoint = '/holidays/change_status';
                let { data } = await request.post(_endpoint, form)
                if (data) {
                    vm.send = false;
                    this.list();
                    this.successNotification('Éxito al aceptar las vacaciones');
                }
            } catch (error) {
                vm.send = false;
                console.log(error);
            }
        },
        list() {
            this.$modal.hide('modalPDF');
            Event.$emit('event-load-holidays');
        },
        async cancelStatus(id) {
            let comment = this.comment;
            this.$swal({
                // title: 'Cancelar las vacaciones',
                text: 'Deseas continuar con el rechazo de estas vacaciones ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, rechazar',
                cancelButtonText: 'No, regresar',
                customClass: {
                    cancelButton: 'order-1 btn btn-outline-danger',
                    confirmButton: 'order-2 btn btn-outline-success',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: id,
                        cancel: 1,
                        comment: comment
                    };
                    this.callChangeStatus(form);
                    this.successNotification('Éxito al cancelar las vacaciones');
                    this.list();
                }
            });
        },
        reset() {
            this.load = false;
            this.$modal.hide('modalPDF');
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<style>
.pad {
    padding: 10px !important;
}

iframe {
    width: 100%;
    min-height: 500px;
}

.bk_iframe {
    position: absolute;
    width: 97%;
    height: 100%;
    background: #27736fcc;
    backdrop-filter: blur(10px);
}
</style>
