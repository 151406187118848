<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Calendario de actividades</h3>
			</div>
			<div class="card-body">
				<FullCalendar :options="calendarOptions" />
			</div>
		</div>
	</div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from "@fullcalendar/core/locales/es";
import moment from 'moment'

import request from '../../services/request'
import { mapActions } from 'vuex';

export default {
	name: "calendar-all",
	components: {
		FullCalendar
	},
	data() {
		return {
			calendarOptions: {
				locale: esLocale,
				plugins: [dayGridPlugin, interactionPlugin],
				initialView: 'dayGridMonth',
				// eventClick: this.eventClick,
				eventSources: [
					{
						async events(time) {
							let _endpoint = `/events`;
							let params = {
								start: moment(time.start).format("YYYY-MM-DD"),
								end: moment(time.end).format("YYYY-MM-DD")
							}
							try {
								let data = await request.get(_endpoint, params)
								if (data) {
									return data.data;
								}
							} catch (error) {
								this.errors = {};
								if (error.response) {
									this.errors = await this.errorNotification(error)
								}
							}
						}
					}
				]
			}
		}
	},
	methods: {
		// eventClick: function (arg) {
			// alert('date click! ' + arg.dateStr)
		// },
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>

<style>
.fc-event-title.fc-sticky {
  white-space: normal!important;
}

.birthday {
	display: flex;
	background-color: #2b3e50ed;
	border: 2px solid #229d86;
}
.birthday:before {
font-family: "Font Awesome 5 Free";
   content: "\f1fd";
   display: inline-block;
   padding-right: 3px;
   padding-left: 3px;
   vertical-align: middle;
   font-weight: 900;
   color: #fff;
}

.admission_date {
	display: flex;
	background-color: #229d86;
	border: 2px solid #2b3e50ed;
}
.admission_date:before {
font-family: "Font Awesome 5 Free";
   content: "\f59c";
   display: inline-block;
   padding-right: 3px;
   padding-left: 3px;
   vertical-align: middle;
   font-weight: 900;
   color: #fff;
}

.init_proyect {
	display: flex;
	background-color: #09bb08;
	border: 2px solid #4563ffed;
}
.init_proyect:before {
font-family: "Font Awesome 5 Free";
   content: "\f164";
   display: inline-block;
   padding-right: 3px;
   padding-left: 3px;
   vertical-align: middle;
   font-weight: 900;
   color: #fff;
}

.end_proyect {
	display: flex;
	background-color: #087fbb;
	border: 2px solid #9d9d9ded;
}
.end_proyect:before {
font-family: "Font Awesome 5 Free";
   content: "\f2b5";
   display: inline-block;
   padding-right: 3px;
   padding-left: 3px;
   vertical-align: middle;
   font-weight: 900;
   color: #fff;
}
</style>
