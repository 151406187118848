<template>
	<div class="row bg align-items-center justify-content-center">
		<div class="w-75">
			<div class="row">
				<div class="col-12 text-center">
					<img :src="require('@/assets/img/logo.png')" class="mt-5 mb-5" alt="Logo" width="150px;"><br>
					<h3 style="color: #199f88;" class="login-box-msg mt-n4">RESTABLECER CONTRASEÑA</h3>
					<p class="mt-n2">Ingresa el correo para enviar el link</p>
				</div>
				<div class="col-12 text-center mb-4">

					<form @submit.prevent="triggerResetPassword">
						<div class="row justify-content-center">

							<div class="col-12 col-md-10">
								<div class="input-group ">
									<input type="text" v-model="form.email" name="email" class="form-control"
										placeholder="Correo" :class="{ 'is-invalid': submitted && !form.email }" />
								</div>
								<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
								<div class="row">
									<div class="col-12 mt-3 mb-3 text-center">
										<h5>
											<router-link to="/login" class="text-login">Iniciar sesión</router-link>
										</h5>
									</div>

									<div class="col-12 text-center mt-3">
										<div class="form-group text-center">
											<button class="btn btn-success" style="width: 220px;" :disabled="submitted">
												<span v-if="submitted">
													<i class="fa-solid fa-spinner fa-spin-pulse mr-1"></i> Enviando
												</span>
												<span v-else>
													Restablecer contraseña
												</span>
											</button>
										</div>
										<div class="col-md-12 text-center">
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
	},
	data() {
		return {
			form: {
				email: '',
			},
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerResetPassword() {
			this.submitted = true;
			try {
				let reset = await this.resetPassword(this.form)
				if (reset) {
					this.submitted = false;
					this.successNotification(reset.message);
					this.$router.push('/login')
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.submitted = false;
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
};
</script>
<style scoped>
input {
	max-width: 415px;
	margin: 0 auto;
}
</style>