import { render, staticRenderFns } from "./DaysHolidaysYearsComponent.vue?vue&type=template&id=0ed7b286&scoped=true"
import script from "./DaysHolidaysYearsComponent.vue?vue&type=script&lang=js"
export * from "./DaysHolidaysYearsComponent.vue?vue&type=script&lang=js"
import style0 from "./DaysHolidaysYearsComponent.vue?vue&type=style&index=0&id=0ed7b286&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed7b286",
  null
  
)

export default component.exports