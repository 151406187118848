<script>
import { mapActions, mapGetters } from 'vuex';

import NewExtraHolidayComponent from '../../component/extra_holiday/NewExtraHolidayComponent.vue';
import Pagination from 'vue-pagination-2';
import request from '../../services/request'


export default {
    name: "holidays_table",
    components: {
        Pagination,
        NewExtraHolidayComponent
    },
    data() {
        return {
            page: 1,
            holidays: {},
            search: {
                date: [],
                status: '',
            },
            load: false,
            deep: false,
        }
    },
    async mounted() {
        this.deep = true;
        this.list();
    },
    created() {
        Event.$on("event-load-holidays", () => {
            this.list();
        });
    },
    methods: {
        async list(page = 1) {
            this.load = true;
            let service = request;
            let search = this.search;
            let _endpoint = '/holidays_extras';
            try {
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    this.holidays = data;
                    this.load = false;
                }
            } catch (error) {
                this.load = false;
                console.log(error);
            }
        },
        deleted: function (id) {
            let tit = 'Eliminar vacaciones';
            let txt = 'Seguro que quiere eliminar las vacaciones';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Regresar',
                customClass: {
                    cancelButton: 'order-1 btn btn-outline-danger',
                    confirmButton: 'order-2 btn btn-outline-success',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
            let _endpoint = '/holidays_extras/' + id;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        createHoliday(holiday = {}) {
            Event.$emit('event-new-holiday-extra', holiday);
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        search: {
            deep: true,
            immediate: false,
            handler() {
                if (this.deep) {
                    console.log('filtro')
                    this.list();
                }

            }
        }
    }
}
</script>

<template>
    <div class="col-12">
        <NewExtraHolidayComponent />
        <div class="card card-outline card-info">
            <div class="card-header">
                <h3 class="card-title">Lista de Vacaciones Extras</h3>
                <div class="card-tools">
                    <button v-can="'add_holidays_extras'" @click="createHoliday()"
                        class="btn btn-sm m-1 btn-outline-success">
                        <i class="fa fa-plus"></i> Registrar vacaciones
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <label>Usuarios :</label>
                        <select class="form-control" v-model="search.status" @change="list()">
                            <option value=""> Todos </option>


                        </select>
                    </div>


                </div>
                <br>
                <div class="table-responsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Usuario</th>
                                <th>Dia de vencimiento</th>
                                <th>Dias</th>
                                <th>Dias usados</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody v-if="load">
                            <tr>
                                <td colspan="5" class="text-center p-4">
                                    <i class="fas fa-spinner fa-pulse fa-5x"></i>
                                    <h6 class="mt-3">
                                        Cargando
                                    </h6>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="holidays.total > 0">
                            <tr v-for="(holiday, index) in holidays.data" :key="index">
                                <td class="v-center">{{ holiday.id }}</td>
                                <td class="v-center">
                                    <p v-if="holiday.type == 1"> Por S.A. </p>
                                    <p v-else> Del periodo anterior </p>
                                </td>
                                <td class="v-center">
                                    <p v-if="holiday.users"> {{ holiday.users.name }} {{ holiday.users.last_name }}</p>
                                    <p v-else> Sin Usuario</p>
                                </td>
                                <td class="v-center">
                                    <p v-if="holiday.expiration"> {{ holiday.expiration | dateHuman }} </p>
                                </td>
                                <td class="v-center">
                                    <p> {{ holiday.days }} </p>
                                </td>
                                <td class="v-center">
                                    <p v-if="holiday.used_sum_days"> {{ holiday.used_sum_days }} </p>
                                    <p v-else> 0</p>
                                </td>
                                <td class="v-center">
                                    <button v-can="'edit_holidays_extras'" v-if="holiday.used_sum_days < holiday.days" @click="createHoliday(holiday)"
                                        class="btn btn-outline-primary btn-sm m-1">
                                        <i class="fas fa-edit "> </i>
                                    </button>
                                    <button v-can="'deleted_holidays_extras'" v-if="holiday.used_sum_days != holiday.days"  @click="deleted(holiday.id)"
                                        class="btn-outline-danger btn btn-sm m-1 ">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td align="center" colspan="5">Sin resultados.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <pagination v-model="page" v-if="holidays.total" :records="holidays.total" :per-page="holidays.per_page"
                    @paginate="list" />
            </div>
        </div>
        <ModalValidate />
    </div>
</template>
