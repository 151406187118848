<template>
    <div class="card card-success card-outline direct-chat direct-chat-success shadow-sm">
        <div class="card-header">
            <h3 class="card-title">Seguimiento del proyecto</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
                <button type="button" class="btn btn-tool" title="Contacts" data-widget="chat-pane-toggle">
                    <i class="fas fa-users"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="direct-chat-messages" id="list">
                <div class="direct-chat-msg" :class="message.user_id == authUser.id ? 'right' : ''"
                    v-for="(message, index) in messages" :key="index">
                    <div class="direct-chat-infos clearfix">
                        <span class="direct-chat-name"
                            :class="message.user_id == authUser.id ? 'float-right' : 'float-left'">{{
                                message.user.name
                            }} {{ message.user.last_name }}</span>
                        <span class="direct-chat-timestamp"
                            :class="message.user_id == authUser.id ? 'float-left' : 'float-right'">{{
                                message.created_at
                                    | dateMinutHuman
                            }}</span>
                    </div>
                    <img class="direct-chat-img"
                        :src="message.user.profile_picture ? config.apiUrl + message.user.profile_picture : require('@/assets/img/profile.png')"
                        alt="Message User Image">
                    <div class="direct-chat-text">
                        <a v-if="message.file" class="text-light" :href="config.apiUrl + message.file" target="_blank">
                            <i class="fa fa-file fa-5x"></i><br>
                            {{ message.name }}
                        </a>
                        <hr v-if="message.file">
                        {{ message.message }}
                    </div>
                </div>
            </div>
            <div class="direct-chat-contacts">
                <ul class="contacts-list">

                    <li v-for="(user, index2) in project.users" :key="index2">
                        <a href="#">
                            <img class="contacts-list-img"
                                :src="user.profile_picture ? config.apiUrl + user.profile_picture : require('@/assets/img/profile.png')"
                                alt="User Avatar">
                            <div class="contacts-list-info">
                                <span class="contacts-list-name">
                                    {{ user.name }} {{ user.last_name }}
                                </span>
                                <p class="mb-0">
                                    <span v-if="user.profile.profil">{{ user.profile.profil.name }}</span>
                                    <span v-if="user.profile.area"> / {{ user.profile.area.name }}</span>
                                </p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-footer">
            <form @submit.prevent="triggerSave">
                <div class="form-group">
                    <textarea type="text" rows="2" placeholder="Escriba el mensaje..." v-model="form.message"
                        class="form-control"></textarea>
                    <div v-if="errors && errors.message" class="text-danger">{{ errors.message[0] }}</div>
                </div>
                <div class="form-group mt-1 d-flex">
                    <button type="button" @click="clearFile" class="btn btn-danger col-1" v-if="fileName"> <i
                            class="fa fa-trash"></i> </button>
                    <div class="custom-file" :class="fileName ? 'col-11' : ''">
                        <input type="file" class="custom-file-input" id="customFile" ref="file"
                            @change="handleFileObject()">
                        <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                    </div>

                </div>
                <button type="submit" class="btn btn-success float-right">Enviar</button>
            </form>
        </div>
    </div>
</template>
<script>
import request from '../../services/request'
import config from '@/services/config';
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            project: {},
            messages: [],
            page: 1,
            page_max: '',
            config: config,
            fileName: '',
            file: '',
            errors: {},
            form: {
                project_id: '',
                message: '',
                file: ''
            }
        }
    },
    methods: {
        async get_id(id) {
            let _endpoint = `/projects/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    this.project = data;
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        async list() {
            this.load = true;
            let _endpoint = 'project_comments?id=' + this.form.project_id;
            let _message = this.messages;
            let page = this.page;
            let search = {};
            try {
                let { data } = await request.list(_endpoint, page, search)
                if (data) {
                    this.page_max = data.last_page;
                    data.data.map(function (element) {
                        _message.push(element);
                    });
                    this.messages = _message;
                    this.load = false;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async triggerSave() {
            const data_form = new FormData();
            data_form.append('project_id', this.form.project_id);
            data_form.append('message', this.form.message);
            data_form.append('file', this.form.file);

            let _endpoint = '/project_comments';

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.messages.push(data.message);
                    this.resetMessage();
                    setTimeout(function () {
                        let element = document.getElementById("list");
                        element.scrollTo(0, 20000);
                    }
                        , 500);


                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        handleFileObject() {
            this.form.file = this.$refs.file.files[0]
            this.fileName = this.file.name
        },
        resetMessage() {
            this.form.message = '';
            this.form.file = '';
            this.errors = {};
            this.clearFile();
        },
        clearFile() {
            this.$refs.file.type = 'text';
            this.$refs.file.type = 'file';
            this.form.file = '';
            this.fileName = '';
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        let id = this.$route.params.id;
        this.form.project_id = id;
        if (id) {
            this.get_id(id);
            this.list();
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<style scoped>
.direct-chat-messages {
    height: 400px !important;
}

.direct-chat-contacts {
    height: 400px !important;
}

.custom-file-label::after {
    content: "Archivo";
}
</style>