import * as auth from '../../services/auth'
import request from '../../services/request'

export const register = async ({ commit }, payload) => {
	console.log(commit);
	try {
		let { data } = await auth.register(payload)
		if (data) {
			return Promise.resolve(data)
		} else {
			return Promise.reject('error')
		}
	} catch (err) {
		return Promise.reject(err)
	}
}

export const login = async ({ commit }, payload) => {
	try {
		let { data } = await auth.login(payload)
		if (data) {
			if (data.access_token) {
				commit('setUser', data.user)
				commit('setToken', data.access_token)
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		} else {
			return Promise.reject(data)
		}
	} catch (err) {
		return Promise.reject(err)
	}
}

export const resetPass = async ({ commit }, payload) => {
	console.log(commit);
	try {
		let { data } = await request.post('/auth/reset-password-request', payload)
		return Promise.resolve(data)
	} catch (err) {
		return Promise.reject(err)
	}
}

export const changepass = async ({ commit }, payload) => {
	console.log(commit);
	try {
		let { data } = await request.post('/auth/change-password', payload)
		return Promise.resolve(data)
	} catch (err) {
		return Promise.reject(err)
	}
}



export const logout = async ({ commit }) => {
	try {
		localStorage.removeItem('sessionStartTime'); // Borrar el tiempo restante de localStorage
		commit('setUser', null);
		commit('setToken', null);
		return Promise.resolve(true)
	} catch (err) {
		return Promise.reject(err)
	}
}

export const getUser = async ({ commit }) => {
	try {
		let { data } = await auth.getUser()
		commit('setUser', data)
		return Promise.resolve(true)
	} catch (err) {
		commit('setUser', null)
		commit('setToken', null)
		return Promise.reject(false)
	}
}

export const updateUser = async ({ commit }, payload) => {
	try {
		let { data } = await auth.updateUser(payload)
		commit('setUser', data)
		return Promise.resolve(true)
	} catch (err) {
		commit('setUser', null)
		commit('setToken', null)
		return Promise.reject(false)
	}
}

