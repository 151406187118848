<template>
    <div class="col-md-12">
        <p>
            <strong>* Configuración para las vacaciones por área, estas reglas se aplicarán a los usuarios de dicha
                área.</strong>
        </p>
        <hr>
        <div class="row mt-4">
            <div class="col-md-6" v-for="(area, index) in areas" :key="index">
                <div class="card card-outline">
                    <div class="card-header">
                        <h3 class="card-title">{{ area.name }}</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="custom-control pl-0">
                                        <div class="row">
                                            <div class="col-3 text-center">
                                                <toggle-button :value="1" :width="55" :height="30"
                                                    v-model="area.holidays.area_manager"
                                                    :labels="{ checked: 'Si', unchecked: 'No' }" />
                                            </div>
                                            <div class="col-9">
                                                <strong>
                                                    Es requerida la validación del gerente de área.
                                                </strong>
                                            </div>
                                        </div>
                                        <span class="font-10">Esta opción se aplica para que el gerente de área valide
                                            las vacaciones, si no será solamente el jefe director.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="custom-control pl-0">
                                        <div class="row">
                                            <div class="col-3">
                                                <input class="form-control" type="number" min="0"
                                                    v-model="area.holidays.days" />
                                            </div>
                                            <div class="col-9 mt-1">
                                                <strong>
                                                    Cuantos días de vacaciones máximos
                                                </strong>
                                            </div>
                                        </div>
                                        <span class="font-10">Esta opción es para el número máximo de días permitidos
                                            para tomar vacaciones, dejar en 0 para tomar todos los días de
                                            corrido.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <hr>
                <button type="button" @click="save()" class="btn float-right btn-outline-success">
                    <i class="fas fa-save"></i> Guardar
                </button>

            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

import request from '../../services/request'
export default {
    data() {
        return {
            areas: [],
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        async getList() {
            let service = request;
            let _endpoint = '/holidays_area';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.areas = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async save() {
            this.load = true;
            let _endpoint = `/holidays_area`;
            try {
                let { data } = await request.post(_endpoint, { 'areas': this.areas })
                if (data) {
                    this.successNotification(data.message);
                }
            } catch (error) {
                this.load = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
    }
}
</script>

<style scoped>
.font-10 {
    font-size: 11px;
}

.lh {
    line-height: 18px;
}
</style>
