<template>
	<modal name="new_holiday" :width="400" :height="'auto'" :adaptive="true">
		<div class="row p-4" v-if="load == false">
			<div class="col-12 text-center">
				<h4>Solicitud de Vacaciones</h4>
				<hr>
			</div>
			<div class="col-md-12 text-center">
				<div class="row">
					<div class="col-6">
						<label for="">Fecha Inicio</label>
						<date-picker v-model="form.start_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
							:clearable="false" :disabled-date="disabledInit"></date-picker>
						<div v-if="errors && errors.start_date" class="text-danger">{{ errors.start_date[0] }}</div>
					</div>
					<div class="col-6">
						<label for="">Fecha Fin</label>
						<date-picker v-model="form.end_date"
							:disabled='form.start_date == "" || form.start_date == null' valueType="YYYY-MM-DD"
							format="DD-MM-YYYY" :clearable="false" :disabled-date="disabledEnd"></date-picker>
						<div v-if="errors && errors.end_date" class="text-danger">{{ errors.end_date[0] }}</div>
					</div>
				</div>
				<br>
				<div class="row">
					<div class="col-6">
						<button class="btn btn-block btn-outline-warning" @click="reset()"> Regresar </button>
					</div>
					<div class="col-6">
						<button class="btn btn-block btn-outline-success" @click="save()"> Solicitar </button>
					</div>

				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<div class="load">Loading...</div>
			</div>
		</div>
	</modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import request from '../../services/request'
import moment from 'moment'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
	watch: {
		'form.start_date'(value) {
			if (value == "", value == null) {
				this.form.end_date = '';
			}
			if (value != "") {
				this.form.end_date = '';
				this.getDayMaxHolidays();
			}
		}
	},
	name: "new-holidays",
	components: {
		DatePicker,
	},
	data() {
		return {
			form: {
				start_date: '',
				end_date: '',
			},
			dateArray: [],
			errors: {},
			load: false,
			catalogue: new Catalogue(),
			off_days: [],
			MaxHolidays: [],

		}
	},
	async mounted() {
		await this.getOffDays();
		// await this.getDayMaxHolidays();
	},
	methods: {
		async save() {
			this.load = true;
			let _endpoint = `/holidays`;
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
					Event.$emit('event-load-holidays', '');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.load = false;
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async getOffDays() {
			this.load = true;
			let _endpoint = `/catalogue/off_days`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.off_days = data;
					this.load = false;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.load = false;
					this.errors = await this.errorNotification(error)
				}
			}
		},
		reset() {
			this.load = false;
			this.dateArray = [];
			this.errors = {};
			this.form.start_date = '';
			this.form.end_date = '';
			this.$modal.hide('new_holiday');
		},
		disabledInit: function (date) {
			let today = new Date();
			let in_year = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

			let offDays = this.off_days;
			let fortDate = moment(date).format('YYYY-MM-DD');

			return date < new Date() || date.getDay() == 0 || date.getDay() == 6 || offDays.includes(fortDate) || date > in_year;
		},
		disabledEnd: function (date) {

			let offDays = this.off_days;
			let fortDate = moment(date).format('YYYY-MM-DD');

			if (this.form.start_date) {
				return date < new Date(this.form.start_date) || date > moment(this.form.start_date).add(this.MaxHolidays, 'days') || date.getDay() == 0 || date.getDay() == 6 || offDays.includes(fortDate);
			} else {
				this.form.end_date = '';
				return false;
			}
		},
		async getDayMaxHolidays() {
			let vm = this;

			let extras = 0;
			let daysHolidays = 0;
			let daysUsedHolidays = 0;
			let dayMax = 0;
			let user = await this.authUser;

			if (user) {
				// Dias máximos de goce
				if (user.daysMax) {
					dayMax = user.daysMax;
				}

				// Dias de vacaciones de goce
				if (user.daysHollidays) {
					daysHolidays = user.daysHollidays;
				}

				// Dias extras dados por el admin 
				if (user.daysUsedHollidaysExtras) {
					if (user.daysUsedHollidaysExtras.extras) {
						extras = user.daysUsedHollidaysExtras.extras;
					}
				}

				// Dias extras dados por el admin 
				if (user.daysUsedHollidays) {
					daysUsedHolidays = user.daysUsedHollidays;
				}
			} else {
				this.$swal({
					title: 'La sesión ha expirado',
					text: 'tu sesión ha expirado, para seguir trabajando inicia sesión nuevamente.',
					icon: 'warning',
				}).then(async () => {
					vm.postLogout();

				});
			}

			if (dayMax == 0) {
				let days = (daysHolidays + extras) - daysUsedHolidays;
				let day_return = this.numWeekend(days);
				this.MaxHolidays = day_return;
			} else {
				let days = (daysHolidays + extras) - daysUsedHolidays;
				if (dayMax <= days) {
					// let _days = this.authUser.daysMax;
					let day_return = this.numWeekend(days);
					this.MaxHolidays = day_return;
				} else {
					let day_return = this.numWeekend(days);
					this.MaxHolidays = day_return;
				}
			}
		},
		numWeekend(days) {
			let daysLoop = 0;
			let initDate = moment(this.form.start_date);
			let endDate = moment(this.form.start_date).add(49, 'days');
			let activeDays = 0;
			let offDays = this.off_days;

			while (!initDate.isAfter(endDate)) {
				// Si no es sábado ni domingo
				let fortDate = initDate.format('YYYY-MM-DD');
				if (initDate.isoWeekday() !== 6 && initDate.isoWeekday() !== 7 && !offDays.includes(fortDate)) {
					daysLoop++;
					if (daysLoop == days) {
						break;
					}
				}
				activeDays++;
				initDate.add(1, 'days');
			}

			return activeDays;
		},
		async postLogout() {
			try {
				let logout = await this.authLogout();
				if (logout) {
					this.$nextTick(() => {
						location.reload()
					})
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		}),
		...mapActions({
			authLogout: 'auth/logout'
		})
	},
}
</script>

<style>
.pad {
	padding: 20px !important;
}

.mx-datepicker {
	width: 100%;
}
</style>
