import axios from "axios"
import config from '@/services/config';

const ENDPOINT = `${config.apiUrl}/api/auth`;

export const login = (payload) => {
    let endpoint = `${ENDPOINT}/login`
    return axios.post(
        endpoint,
        payload,
    )
}

export const register = (payload) => {
    let endpoint = `${ENDPOINT}/register`
    return axios.post(
        endpoint,
        payload,
    )
}

export const logout = () => {	
    let endpoint = `${ENDPOINT}/logout`
    return axios.post(endpoint)
}

export const getUser = () => {
    let endpoint = `${ENDPOINT}/me`
    return axios.get(endpoint);
}

export const updateUser = (payload) => {
    let endpoint = `${ENDPOINT}/user_login`
    return axios.get(endpoint, payload)
}


export default {
    login,
}
