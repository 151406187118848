<template>
	<div class="col-12">
		<div class="row">
			<div class="col-md-4">
				<div class="card card-outline card-info">
					<div class="card-header">
						<h3 class="card-title">Información del Cliente</h3>
					</div>
					<div class="card-body">
						<form @submit.prevent="triggerSave">
							<div class="row">
								<div class="col-12">
									<div class="row">
										<div class="col-md-12">
											<div class="col-12 text-center">
												<h3>Logo</h3>
												<img class="img-fluid rounded" style="width: 200px;" :src="logo" alt="">
												<br>
												<br>
												<input type="file" id="file" class="inputfile" @change="csv_file">
												<label for="file"><i class="fas fa-upload"></i> Subir logo </label>
											</div>
										</div>
										<div class="col-md-12">
											<div class="row">
												<div class="col-12">
													<div class="form-group">
														<label> Alias </label>
														<input type="text" v-model="form.alias" class="form-control">
														<div v-if="errors && errors.alias" class="text-danger">{{
															errors.alias[0]
														}}</div>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group">
														<label> Teléfono </label>
														<input type="text" v-model="form.phone" class="form-control">
														<div v-if="errors && errors.phone" class="text-danger">{{
															errors.phone[0]
														}}</div>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group">
														<label> Correo </label>
														<input type="text" v-model="form.email" class="form-control">
														<div v-if="errors && errors.email" class="text-danger">{{
															errors.email[0]
														}}</div>
													</div>
												</div>
												<div class="col-md-12">
													<div class="form-group">
														<label> Sitio Web</label>
														<input type="text" v-model="form.web" class="form-control" />
														<div v-if="errors && errors.web" class="text-danger">
															{{ errors.web[0] }}
														</div>
													</div>
												</div>
												<div class="col-md-12">
													<div class="form-group">
														<label>Cliente Principal </label>
														<select class="form-control" v-model="form.customer_id">
															<option value="">Sin cliente principal</option>
															<option v-for="(customer, index) in catalogue.main_clients"
																:key="index" :value="customer.id">{{
																	customer.alias
																}}</option>
														</select>
														<div v-if="(errors && errors.customer_id)" class="text-danger">
															{{ errors.customer_id[0] }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12">
									<hr>
								</div>
								<div class="col-md-12">
									<button type="submit" class="btn float-right btn-outline-success">
										<i class="fas fa-save"></i> Guardar
									</button>

								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-md-8">
				<div class="card card-info card-tabs">
					<div class="card-header p-0 pt-1">
						<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill"
									href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home"
									aria-selected="true">Datos de Facturación</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill"
									href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile"
									aria-selected="false">Contactos</a>
							</li>
						</ul>
					</div>
					<div class="card-body">
						<div class="tab-content" id="custom-tabs-one-tabContent">
							<div class="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel"
								aria-labelledby="custom-tabs-one-home-tab">
								<Billing />
							</div>
							<div class="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel"
								aria-labelledby="custom-tabs-one-profile-tab">
								<Contacts />
							</div>
						</div>
					</div>
				</div>
				<router-link to="/customers" class="btn float-right mt-5 btn-warning">
					<i class="fas fa-undo-alt"></i>
					Regresar
				</router-link>
			</div>
			<div class="col-12">

			</div>
		</div>
	</div>
</template>

<script>
import request from '../../services/request'
import { mapActions } from 'vuex';

import Catalogue from '../../services/catalogue';
import config from '@/services/config';
import Billing from './billing.vue';
import Contacts from '../contacts/index.vue';

export default {
	name: "users-table",
	components: {
		Billing,
		Contacts
	},
	data() {
		return {
			form: {
				id: '',
				alias: '',
				phone: '',
				email: '',
				web: '',
				customer_id: '',
				_method: 'POST',
			},
			isLoadingSelect: false,
			logo: require('@/assets/img/sin-logo.jpeg'),
			errors: {},
			action: '/customers',
			catalogue: new Catalogue(),
		}
	},
	mounted() {
		this.catalogue.get_countrys();
		let id = this.$route.params.id;
		this.catalogue.get_main_clients(id);
		if (id) {
			this.get_id(id);
		}
	},
	created() {
		Event.$on("event-load-country", () => {
			this.catalogue.get_countrys();
		});
	},
	methods: {
		add_country() {
			this.$modal.show('NewCountry');
		},
		async get_id(id) {
			let _endpoint = `/customers/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					let form = this.form;
					Object.keys(form).forEach(key => {
						this.form[key] = data[key];
					});
					this.get_zip();
					if (data.logo) {
						this.logo = config.apiUrl + data.logo;
					}
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		csv_file(event) {
			if (event.target.files[0].type == "image/png" ||
				event.target.files[0].type == "image/jpeg") {
				this.form.logo = event.target.files[0];
				this.logo = URL.createObjectURL(event.target.files[0]);
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PNG , JPGE',
				});
			}
		},
		async triggerSave() {
			const data_form = new FormData();
			let form = this.form;
			Object.keys(form).forEach(key => {
				data_form.append(key, form[key]);
			});

			data_form.append('logo', this.form.logo);
			data_form.append('_method', 'PUT');
			let _endpoint = `/customers/${this.form.id}`;

			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					this.successNotification(data.message);
					// this.$router.push('/customers');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					let info_error = await this.errorNotification(error);
					if (info_error == 'Logout') {
						this.$nextTick(() => {
							location.reload()
						})
					} else {
						this.errors = info_error;
					}
				}
			}
		},
		async get_zip() {
			let zip = this.form.zip;
			let country = this.form.country_id;
			if (Number.isInteger(zip)) {
				if (zip.length == 5 && country == 1) {
					this.catalogue.get_zip(zip);
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	}
}
</script>
<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}
</style>
