<template>
	<modal name="NewBudget" v-bind:click-to-close="false" :width="400" :adaptive="true">
		<div class="row pad" v-if="load == false">
			<div class="col-12">
				<h4>{{ title }}</h4>
				<hr>
				<div class="form-group">
					<label for="">Monto :</label>
					<money v-model="form.amount" class="form-control"></money> 
					<div v-if="(errors && errors.amount)" class="text-danger">{{ errors.amount[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Usuario en contacto :</label>
					<multiselect v-model='form.user_id' :options='catalogue.all_users'
						placeholder='Buscar opción' label='name' track-by='id' :multiple="false">
					</multiselect>
					<div v-if="(errors && errors.user_id)" class="text-danger">{{ errors.user_id[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Contacto empresa :</label>
					<multiselect v-model='form.contact_id' :options='catalogue.contacts_proyect'
						placeholder='Buscar opción' label='name' track-by='id' :multiple="false">
					</multiselect>
					<div v-if="(errors && errors.contact_id)" class="text-danger">{{ errors.contact_id[0] }}</div>
				</div>
				<div class="form-group">
					<label for="">Fecha de envio :</label>
					<date-picker v-model="form.send_date" valueType="YYYY-MM-DD" format="DD-MM-YYYY"
									:clearable="false"></date-picker>
					<div v-if="(errors && errors.send_date)" class="text-danger">{{ errors.send_date[0] }}</div>
				</div>
			</div>
			<div class="col-12 mt-3">
				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-warning" @click="reset">
							<i class="fas fa-undo-alt"></i>
							Cerrar
						</button>
					</div>
					<div class="col-6 text-right">
						<button class="btn btn-outline-success" @click="save">
							<i class="fas fa-save"></i>
							Guardar
						</button>

					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12">
				<div class="load">Loading...</div>
			</div>
		</div>
	</modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'
import Catalogue from '../../services/catalogue';
import Multiselect from 'vue-multiselect'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
	name: "new-budgets",
	components: {
		Multiselect,
		DatePicker
	},
	data() {
		return {
			title: 'Nuevo Presupuesto',
			form: {
				id: '',
				amount: '',
				user_id: '',
				contact_id: '',
				send_date: '',
				proyect_id: '',
			},
			errors: {},
			load: false,
			catalogue: new Catalogue(),
		}
	},
	created(){
		Event.$on("event-new-budgets", id => {
			this.title = 'Nuevo Presupuesto';
			if(id){
				this.title = 'Editar Presupuesto';
				this.get_id(id);	
			}
        });
	},
	mounted() {
		this.catalogue.get_all_users('');
		let id = this.$route.params.id;
		this.catalogue.get_contacts_proyect(id);
		if (id) {
			this.form.proyect_id = id;
		}
	},
	methods: {
		async save() {
			this.load = true;
			let _endpoint = '';
			if(this.form.id){
				_endpoint = `/budgets/${this.form.id}`;
				this.form._method = 'PUT';
			}else{
				_endpoint = `/budgets`;
			}
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
					Event.$emit('event-load-budgets', '');
				}
			} catch (error) {
				this.load = false;
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error);
				}
			}
		},
		async get_id(id) {
			this.form.id = id;
			let _endpoint = `/budgets/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					let form = this.form;
					Object.keys(form).forEach(key => {
						this.form[key] = data[key];
					});
					this.form.contact_id = data.contact
					this.form.user_id = data.user
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		reset() {
			this.load = false;
			this.errors = {};
			this.form.id = '';
			this.form.amount = '';
			this.form.user_id = '';
			this.form.contact_id = '';
			this.form.send_date = '';
			this.form._method = 'POST';
			this.$modal.hide('NewBudget');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style>
.pad {
	padding: 10px !important;
}
.mx-datepicker {
	width: 100%;
}
</style>
