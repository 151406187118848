import Vue from 'vue';

import moment from 'moment';
moment.locale('es');

Vue.filter('dateMinutHuman', function (value) {
	if (value) {
		return moment(value).format('DD/MMM/YYYY hh:mm A');
	} else {
		return 'Sin Fecha';
	}
});

Vue.filter('dateHuman', function (value) {
	if (value) {
		return moment(value).format('DD/MMM/YYYY');
	} else {
		return 'Sin Fecha';
	}
});


Vue.filter('Money', function (value) {
	if (value) {
		return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	} else {
		return '0';
	}
});


Vue.filter('formatSize', function (size) {
	if (size) {
		if (size > 1024 * 1024 * 1024 * 1024) {
			return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
		} else if (size > 1024 * 1024 * 1024) {
			return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
		} else if (size > 1024 * 1024) {
			return (size / 1024 / 1024).toFixed(2) + ' MB'
		} else if (size > 1024) {
			return (size / 1024).toFixed(2) + ' KB'
		}
		return size.toString() + ' B'
	} else {
		return '0';
	}
});

