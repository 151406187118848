const config = {
    apiUrl: window.location.host == 'localhost:8080' ? 'http://127.0.0.1:8000' : 'https://api.vacaciones.sorianoariza.com',
    BaseUrl: window.location.host == 'localhost:8080' ? 'http://localhost:8080' : 'https://talent.sorianoariza.com'
    // apiUrl: 'https://api.vacaciones.sorianoariza.com',
    // BaseUrl: 'https://vacaciones.sorianoariza.com'

}
export default config;


