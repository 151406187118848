<template>
    <div>
    
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            sessionTimeout: 3500, // Duración de la sesión en segundos (1 hora en este ejemplo)
            intervalId: null, // ID del intervalo de tiempo
            remaining: 0,
        };
    },
    mounted() {
        if (!localStorage.getItem('sessionStartTime')) {
            localStorage.setItem('sessionStartTime', Date.now());
        }
        this.startTimer();
    },
    methods: {
        startTimer() {
            this.intervalId = setInterval(() => {
                this.remainingTime();
            }, 1000);
        },
        remainingTime() {
            const startTime = parseInt(localStorage.getItem('sessionStartTime') || 0);
            const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
            this.remaining = Math.max(0, this.sessionTimeout - elapsedTime);
            if (this.remaining === 10) {
                this.closedAutomatic();
            }
            if (this.remaining === 0) {
                this.postLogout();
            }
        },
        closedAutomatic() {
            let vm = this;
            this.$swal({
                title: 'La sesión ha expirado',
                text: 'tu sesión ha expirado, para seguir trabajando inicia sesión nuevamente.',
                icon: 'warning',
            }).then(async () => {
                vm.postLogout();

            });
        },
        async postLogout() {
            try {
                let logout = await this.authLogout();
                if (logout) {
                    this.$nextTick(() => {
                        location.reload()
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },
        ...mapActions({
            authLogout: 'auth/logout'
        })
    },
    beforeDestroy() {
        this.postLogout();
    }
};
</script>