<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Lista de Usuarios</h3>
				<div class="card-tools">
					<ImportUserComponentVue />
					<a @click="showImport()"  v-can="'import_users'" class="btn btn-sm m-1 btn-outline-success">
						<i class="fa fa-file-excel"></i> Importar
					</a>
					<router-link :to="`/users/form`" v-can="'add_users'" class="btn btn-sm m-1 btn-outline-success">
						<i class="fa fa-plus"></i> Usuario
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Nombre</th>
								<th>Correo</th>
								<th>Perfil</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td colspan="5" class="text-center p-4">
									<i class="fas fa-spinner fa-pulse fa-5x"></i>
									<h6 class="mt-3">
										Cargando
									</h6>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="users">
							<tr v-for="(user, index) in users.data" :key="index">
								<td>{{ user.id }}</td>
								<td>{{ user.name }}</td>
								<td>{{ user.email }}</td>
								<td v-if="user.profile">
									<strong v-if="user.profile.area">{{ user.profile.area.name }}</strong>
									<p v-if="user.profile.profil">{{ user.profile.profil.name }}</p>
								</td>
								<td v-else>Sin perfil</td>
								<td>
									<router-link :to="`/users/form/${user.id}`" v-can="'edit_users'" class="btn btn-outline-primary btn-sm m-1">
										<i class="fas fa-edit "> </i>
									</router-link>
									<button type="button" @click="deleted(user.id)" v-can="'deleted_users'" class="btn-outline-danger btn btn-sm m-1 ">
										<i class="fa fa-trash"></i>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<pagination v-model="page" :records="users.total" :per-page="users.per_page" @paginate="list" />                       				
			</div>
		</div>
	</div>
</template>

<script>
import {  mapMutations, mapActions } from 'vuex';
import ImportUserComponentVue from '@/component/ImportUserComponent.vue'
import Pagination from 'vue-pagination-2';
import request from '@/services/request'
export default {
	name: "users-table",
	components: {
		Pagination,
		ImportUserComponentVue
	},
	data() {
		return {
            page:1,
			users: {},
			search: '',
            load: false,
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Usuarios');
	},
	created() {
			Event.$on("event-load-users", () => {
					this.list();
			});
	},
	methods: {
		deleted: function (id) {
			let tit = 'Eliminar usuario';
			let txt = 'Seguro que quiere eliminar al usuario';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/users/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport(){
			this.$modal.show('example');
		},
		async list(page = 1) {
            this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/users';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
                    this.load = false;
					this.users = data;
				}
			} catch (error) {
				this.load = false;
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
