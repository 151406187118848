import { render, staticRenderFns } from "./LayoutAdmin.vue?vue&type=template&id=50aaa66c&scoped=true"
import script from "./LayoutAdmin.vue?vue&type=script&lang=js"
export * from "./LayoutAdmin.vue?vue&type=script&lang=js"
import style0 from "./LayoutAdmin.vue?vue&type=style&index=0&id=50aaa66c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50aaa66c",
  null
  
)

export default component.exports